import { inject, injectable } from 'inversify';
import { mergeDeepRight } from 'ramda';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES } from '@/ioc/types';

import {
  IAccountEntity,
  IAccountRepository,
  IAccountSettingsEntity,
  IProvideGoogleContactsReqEntity,
} from '../domain';

import { IAccountDao } from './db/dao/AccountDao';
import { IAccountServicesApi } from './network/AccountApiServices';
import { COUNTRIES_DC, IAccountDC } from './dataContracts';
import {
  mapAccountDcToEntity,
  mapAccountEntityToDc,
  mapGoogleContactsReqEntityToDC,
  mapOnboardingEntityKey,
} from './mappers';

@injectable()
export default class AccountRepository implements IAccountRepository {
  @inject(ACCOUNT_TYPES.AccountDao)
  private accountDao: IAccountDao;

  @inject(ACCOUNT_TYPES.AccountApiService)
  private accountServiceApi: IAccountServicesApi;

  private emailForAccountCreationBehaviorSubject: BehaviorSubject<string> =
    new BehaviorSubject('');

  saveEmailForAccountCreation(email: string): void {
    this.emailForAccountCreationBehaviorSubject.next(email);
  }

  deleteEmailForAccountCreation(): void {
    this.emailForAccountCreationBehaviorSubject.next('');
  }

  getEmailForAccountCreation(): BehaviorSubject<string> {
    return this.emailForAccountCreationBehaviorSubject;
  }

  getAccount(): Observable<IAccountEntity | null> {
    return this.accountDao.getCurrent().pipe(
      map((acc: IAccountDC | null) => {
        return acc ? mapAccountDcToEntity(acc) : null;
      }),
    );
  }

  syncAccount(email?: string): Promise<void> {
    return firstValueFrom(this.accountServiceApi.syncAccount(email));
  }

  async setupAccount(account: {
    fullName: string;
    phone: string;
    country: COUNTRIES_DC;
  }): Promise<IAccountEntity> {
    const response = await firstValueFrom(
      this.accountServiceApi.updateAccount({
        full_name: account.fullName,
        settings: {
          country: account.country,
          phone: account.phone,
        },
      }),
    );
    return mapAccountDcToEntity(response);
  }

  async updateAccount(
    accountPatch: DeepPartial<IAccountEntity>,
  ): Promise<IAccountEntity> {
    const account = await firstValueFrom(this.getAccount());
    if (!account) {
      throw new Error('Account is not initialized');
    }
    const updatedAccount = mergeDeepRight(account, accountPatch);

    const result = await this.accountDao.upsert(mapAccountEntityToDc(updatedAccount));
    return mapAccountDcToEntity(result);
  }

  updateSettings(settings: Partial<IAccountSettingsEntity>): Promise<IAccountEntity> {
    return this.updateAccount({
      settings,
    });
  }

  async completeOnboardingStep(
    step: keyof IAccountEntity['settings']['onboarding'],
  ): Promise<void> {
    await firstValueFrom(
      this.accountServiceApi.updateSettings({
        onboarding: { [mapOnboardingEntityKey(step)]: true },
      }),
    );
  }

  async provideContacts(req: IProvideGoogleContactsReqEntity): Promise<void> {
    await firstValueFrom(
      this.accountServiceApi.provideContacts(mapGoogleContactsReqEntityToDC(req)),
    );
  }

  async deleteAccount(): Promise<void> {
    await firstValueFrom(this.accountServiceApi.deleteAccount());
  }
}
