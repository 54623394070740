import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Statistic } from '../Statistic';

import { usePlanOverviewViewModel } from './usePlanOverviewViewModel';

import styles from './styles.module.scss';

export const PlanOverview: FC = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoading, ...items } = usePlanOverviewViewModel();

  return (
    <Box className={styles.wrapper}>
      {Object.entries(items).map(([key, value]) => {
        if (isLoading) {
          return (
            <Box key={key} className={styles.item}>
              <Statistic.Preview />
            </Box>
          );
        }

        return (
          <Box key={key} className={styles.item}>
            <Statistic title={t(`${key}.title`)} value={value} />
          </Box>
        );
      })}
    </Box>
  );
};
