import { FC } from 'react';

import { useExtensionState } from '@/features/common/extension';
import { useOnboardingStepState } from '@/features/common/onboarding/ui';
import { Permission, usePermissions } from '@/features/common/permissions';
import { useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { CallToActionBaseProps } from './CallToActionBase';
import { GetCredits } from './GetCredits';
import { GetExtension } from './GetExtension';
import { InviteTeamMember } from './InviteTeamMember';
import { RevealContact } from './RevealContact';

const Actions = {
  getExtension: GetExtension,
  revealContact: RevealContact,
  getCredits: GetCredits,
  inviteTeamMember: InviteTeamMember,
};

type Action = keyof typeof Actions;

export type CallToActionToShow = 'auto' | Action | Action[];

type Props = Exclude<CallToActionBaseProps, 'text'> & {
  show?: CallToActionToShow;
  force?: boolean;
};

const useActionToShow = ({ show = 'auto', force }: Props): Action | null => {
  const { isCompleted: isRevealedContact } = useOnboardingStepState('revealContact');
  const { isInstalled: isExtensionInstalled } = useExtensionState();
  const subscriptionUseCase = useSubscriptionUseCase();
  const { hasPermissions } = usePermissions();

  const { data: isUnlimitedPlan } = useObservableResult(
    () => subscriptionUseCase.getIsUnlimitedPlan(),
    { defaultData: false },
  );

  const canShow: Record<Action, boolean> = {
    getExtension: !isExtensionInstalled,
    revealContact: !isRevealedContact,
    inviteTeamMember: isUnlimitedPlan && hasPermissions(Permission.CanManageMembers),
    getCredits: hasPermissions(Permission.CanAccessBilling) && !isUnlimitedPlan,
  };

  if (show === 'auto') {
    if (canShow['getExtension']) return 'getExtension';
    if (canShow['revealContact']) return 'revealContact';
    if (canShow['inviteTeamMember']) return 'inviteTeamMember';
    if (canShow['getCredits']) return 'getCredits';

    return null;
  }

  // passing array is same as passing 'auto' but with specific skipped actions
  if (Array.isArray(show)) {
    if (show.includes('getExtension') && canShow['getExtension']) return 'getExtension';
    if (show.includes('revealContact') && canShow['revealContact'])
      return 'revealContact';
    if (show.includes('inviteTeamMember') && canShow['inviteTeamMember'])
      return 'inviteTeamMember';
    if (show.includes('getCredits') && canShow['getCredits']) return 'getCredits';

    return null;
  }

  if (force) return show;

  return canShow[show] ? show : null;
};

export const CallToAction: FC<Props> = (props) => {
  const action = useActionToShow(props);

  if (!action) return null;

  const ActionComponent = Actions[action];

  return <ActionComponent {...props} />;
};
