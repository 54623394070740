import { inject, injectable } from 'inversify';
import { type MigrationStrategies } from 'rxdb';
import { firstValueFrom } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import type { IMigrationService } from '@/features/system/db';

import type { IWorkspaceApiService } from '../../abstractions/WorkspaceApiService';
import type { IWorkspaceDC } from '../../dataContracts';

@injectable()
export class WorkspaceMigrationService implements IMigrationService {
  @inject(WORKSPACE_TYPES.WorkspaceApiService)
  private workspaceApiService: IWorkspaceApiService;

  private async getWorkspace(uuid: IWorkspaceDC['uuid']): Promise<IWorkspaceDC> {
    const workspaces = await firstValueFrom(this.workspaceApiService.fetchWorkspace());

    const masterWorkspace = workspaces.find((w) => w.uuid === uuid);

    if (!masterWorkspace) {
      throw new Error('Can not find worksapce to migrate');
    }

    return masterWorkspace;
  }

  public getMigrationStrategies(): MigrationStrategies {
    return {
      1: async (oldDoc: IWorkspaceDC): Promise<IWorkspaceDC> => {
        const masterWorkspace = await this.getWorkspace(oldDoc.uuid);

        oldDoc.subscription.future_plan = masterWorkspace.subscription.future_plan;

        return oldDoc;
      },
      2: async (oldDoc: IWorkspaceDC): Promise<IWorkspaceDC> => {
        const masterWorkspace = await this.getWorkspace(oldDoc.uuid);

        oldDoc.billable_members_count = masterWorkspace.billable_members_count;

        return oldDoc;
      },
      3: (oldDoc: IWorkspaceDC): IWorkspaceDC => {
        return oldDoc;
      },
      4: async (oldDoc: IWorkspaceDC): Promise<IWorkspaceDC> => {
        const masterWorkspace = await this.getWorkspace(oldDoc.uuid);

        Object.assign(oldDoc.subscription, masterWorkspace.subscription);

        return oldDoc;
      },
      5: async (oldDoc: IWorkspaceDC): Promise<IWorkspaceDC> => {
        const masterWorkspace = await this.getWorkspace(oldDoc.uuid);

        Object.assign(oldDoc.subscription, masterWorkspace.subscription);

        return oldDoc;
      },
    };
  }
}
