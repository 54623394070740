/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FC, ReactElement } from 'react';
import { Box, BoxProps, Skeleton, Stack, styled } from '@mui/material';

import styles from './styles.module.scss';

type StatisticVariant = 'default' | 'danger' | 'warning' | 'success';

type StatisticProps = {
  title?: string;
  value: string | ReactElement;
  description?: string;
  variant?: StatisticVariant;
};

const StatisticValue = styled(Box)<BoxProps & { variant?: StatisticVariant }>(() => ({
  variants: [
    {
      props: { variant: 'default' },
      style: () => ({
        color: '#000',
      }),
    },
    {
      props: { variant: 'danger' },
      style: () => ({
        color: '#ff4757',
      }),
    },
  ],
}));

export const Statistic: FC<StatisticProps> & {
  Preview: FC;
} = ({ title, value, description, variant = 'default' }) => {
  return (
    <Stack direction="column" className={styles.wrapper}>
      {title && (
        <Box component="span" className={styles.title}>
          {title}
        </Box>
      )}
      <StatisticValue variant={variant} component="span" className={styles.value}>
        {value}
      </StatisticValue>
      {description && (
        <Box component="span" className={styles.description}>
          {description}
        </Box>
      )}
    </Stack>
  );
};

Statistic.Preview = ({ width = 100 }: { width?: number }) => {
  return (
    <Stack direction="column" className={styles.wrapper}>
      <Box className={styles.title}>
        <Skeleton animation="pulse" width={width} height={14} />
      </Box>
      <Box className={styles.value}>
        <Skeleton animation="pulse" width={width} height={22} />
      </Box>
    </Stack>
  );
};
