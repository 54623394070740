import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import { useInjection } from '@/ioc';
import { PLANS_TYPES } from '@/ioc/types';

import { BillingCycle, PlanType } from '@/features/common/billing';

import {
  AnalyticsIcon,
  HelpCenterIcon,
  HubIcon,
  KeyIcon,
  PageInfoIcon,
  PersonIcon,
  VerifiedUserIcon,
} from '@/components/Icons';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { IPlansUseCase } from '../../domain/abstractions/IPlansUseCase';
import type { IPlanGroupsEntity } from '../../domain/entities/PlanGroupsEntity';
import {
  BillingCycleDependent,
  LimitedByQuantity,
  PlanFeatureCategory,
  type PlanFeaturesMetadata,
  type PlanMetadata,
  Unlimited,
} from '../types';
import {
  billingCycleDependent,
  comingSoon,
  fullySupported,
  limittedByQuantity,
  unlimitted,
  unsupported,
} from '../utils';

type NewPlansMetadata = {
  plansMetadata: Record<PlanType, PlanMetadata>;
  plansFeatureMetadata: PlanFeaturesMetadata;
};

type UseNewPlansMetadata = (params: {
  currentPlanType?: Nullable<PlanType>;
}) => ObservableResult<NewPlansMetadata>;

function divideIfNotString(
  value: number | 'contact-sales',
  divider: number,
): number | 'contact-sales' {
  return typeof value === 'number' ? value / divider : value;
}

function getPlanPrices(
  planGroups: IPlanGroupsEntity,
  planType: PlanType,
): { [key in BillingCycle.Monthly | BillingCycle.Yearly]: number | 'contact-sales' } {
  return {
    [BillingCycle.Monthly]: planGroups[planType].monthly.price,
    [BillingCycle.Yearly]: divideIfNotString(planGroups[planType].annually.price, 12),
  };
}

function getFullCredits(
  planGroups: IPlanGroupsEntity,
  planType: PlanType,
): { [key in BillingCycle.Monthly | BillingCycle.Yearly]: number | 'unlimited' } {
  return {
    [BillingCycle.Monthly]: planGroups[planType].monthly.fullCredits,
    [BillingCycle.Yearly]: planGroups[planType].annually.fullCredits,
  };
}

function getCrossedOutPrice(
  planGroups: IPlanGroupsEntity,
  planType: PlanType,
): { [key in BillingCycle.Monthly | BillingCycle.Yearly]: number | 'contact-sales' } {
  return {
    [BillingCycle.Monthly]: planGroups[planType].monthly.price,
    [BillingCycle.Yearly]: planGroups[planType].monthly.price,
  };
}

function mapCredit(limit: number | 'unlimited'): LimitedByQuantity | Unlimited {
  return limit === 'unlimited' ? unlimitted() : limittedByQuantity({ limit });
}

function getBulkCredits(planGroups: IPlanGroupsEntity, planType: PlanType): number {
  return planGroups[planType].monthly.bulkCredits ?? 0;
}

function mapFullCredits(
  planGroups: IPlanGroupsEntity,
  planType: PlanType,
): BillingCycleDependent {
  const creditsByCycle = getFullCredits(planGroups, planType);

  return billingCycleDependent({
    [BillingCycle.Monthly]: mapCredit(creditsByCycle[BillingCycle.Monthly]),
    [BillingCycle.Yearly]: mapCredit(creditsByCycle[BillingCycle.Yearly]),
  });
}

export const useNewPlansMetadata: UseNewPlansMetadata = ({ currentPlanType }) => {
  const { t } = useTranslation('plans');
  const plansUseCase = useInjection<IPlansUseCase>(PLANS_TYPES.PlansUseCase);

  function getPlanName(planType: PlanType): string {
    return t(`plans.${planType}.name`);
  }

  function getPlanDescription(planType: PlanType): string {
    return t(`plans.${planType}.description`);
  }

  function getPlanFeatures(
    planGroups: IPlanGroupsEntity,
    planType: PlanType,
    maxItems: number,
  ): PlanMetadata['features'] {
    const bulkCredits = getBulkCredits(planGroups, planType); // hack. should be fixed in the future
    return {
      title: t(`plans.${planType}.features.title`),
      items: Array.from({ length: maxItems }, (_, index) => {
        return t(`plans.${planType}.features.items.${index + 1}`, { count: bulkCredits });
      }),
    };
  }

  function getFeatureCategoryTitle(category: PlanFeatureCategory): string {
    return t(`features.categories.${category}`);
  }

  function getFeatureTitle(feature: string): string {
    return t(`features.${feature}.title`);
  }

  function getFeatureHint(feature: string): string {
    return t(`features.${feature}.hint`);
  }

  function isCurrentPlan(planType?: Nullable<PlanType>): boolean {
    return currentPlanType === planType;
  }

  return useObservableResult(
    () =>
      plansUseCase.getPlanGroups().pipe(
        map((planGroups) => {
          return {
            plansMetadata: {
              [PlanType.Free]: {
                name: getPlanName(PlanType.Free),
                description: getPlanDescription(PlanType.Free),
                prices: getPlanPrices(planGroups, PlanType.Free),
                credits: getFullCredits(planGroups, PlanType.Free),
                crossedOutPrice: null,
                isCurrent: isCurrentPlan(PlanType.Free),
                isMostPopular: false,
                customBadgeText: null,
                features: getPlanFeatures(planGroups, PlanType.Free, 4),
                actions: [],
                creditCardRequired: false,
              },
              [PlanType.Pro]: {
                name: getPlanName(PlanType.Pro),
                description: getPlanDescription(PlanType.Pro),
                prices: getPlanPrices(planGroups, PlanType.Pro),
                crossedOutPrice: getCrossedOutPrice(planGroups, PlanType.Pro),
                credits: getFullCredits(planGroups, PlanType.Pro),
                isCurrent: isCurrentPlan(PlanType.Pro),
                isMostPopular: false,
                customBadgeText: null,
                features: getPlanFeatures(planGroups, PlanType.Pro, 4),
                actions: [],
                creditCardRequired: true,
              },
              [PlanType.Unlimited]: {
                name: getPlanName(PlanType.Unlimited),
                description: getPlanDescription(PlanType.Unlimited),
                prices: getPlanPrices(planGroups, PlanType.Unlimited),
                crossedOutPrice: getCrossedOutPrice(planGroups, PlanType.Unlimited),
                credits: getFullCredits(planGroups, PlanType.Unlimited),
                isCurrent: isCurrentPlan(PlanType.Unlimited),
                isMostPopular: true,
                customBadgeText: null,
                features: getPlanFeatures(planGroups, PlanType.Unlimited, 2),
                actions: [],
                creditCardRequired: true,
              },
              [PlanType.Expand]: {
                name: getPlanName(PlanType.Expand),
                description: getPlanDescription(PlanType.Expand),
                prices: getPlanPrices(planGroups, PlanType.Expand),
                crossedOutPrice: getCrossedOutPrice(planGroups, PlanType.Expand),
                credits: getFullCredits(planGroups, PlanType.Expand),
                isCurrent: isCurrentPlan(PlanType.Expand),
                isMostPopular: false,
                customBadgeText: null,
                features: getPlanFeatures(planGroups, PlanType.Expand, 6),
                actions: [],
                creditCardRequired: true,
              },
            },
            plansFeatureMetadata: {
              [PlanFeatureCategory.General]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.General),
                icon: <PageInfoIcon />,
                features: [
                  {
                    title: getFeatureTitle('amountOfCredits'),
                    hint: getFeatureHint('amountOfCredits'),
                    supportByPlan: {
                      [PlanType.Free]: mapFullCredits(planGroups, PlanType.Free),
                      [PlanType.Pro]: mapFullCredits(planGroups, PlanType.Pro),
                      [PlanType.Unlimited]: mapFullCredits(
                        planGroups,
                        PlanType.Unlimited,
                      ),
                      [PlanType.Expand]: mapFullCredits(planGroups, PlanType.Expand),
                    },
                  },
                  {
                    title: getFeatureTitle('exportCredits'),
                    hint: getFeatureHint('exportCredits'),
                    supportByPlan: {
                      [PlanType.Free]: unlimitted(),
                      [PlanType.Pro]: unlimitted(),
                      [PlanType.Unlimited]: unlimitted(),
                      [PlanType.Expand]: unlimitted(),
                    },
                  },
                  {
                    title: getFeatureTitle('amountOfUsers'),
                    hint: getFeatureHint('amountOfUsers'),
                    supportByPlan: {
                      [PlanType.Free]: limittedByQuantity({
                        limit: 1,
                      }),
                      [PlanType.Pro]: unlimitted(),
                      [PlanType.Unlimited]: unlimitted(),
                      [PlanType.Expand]: unlimitted(),
                    },
                  },
                  {
                    title: getFeatureTitle('linkedinExt'),
                    hint: getFeatureHint('linkedinExt'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('universalExt'),
                    hint: getFeatureHint('universalExt'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('dashboard'),
                    hint: getFeatureHint('dashboard'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('topUpCredits'),
                    hint: getFeatureHint('topUpCredits'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: unlimitted(),
                      [PlanType.Expand]: unlimitted(),
                    },
                  },
                  {
                    title: getFeatureTitle('bulkSave'),
                    hint: getFeatureHint('bulkSave'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('enrichCsv'),
                    hint: getFeatureHint('enrichCsv'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.ContactManagement]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.ContactManagement),
                icon: <PersonIcon />,
                features: [
                  {
                    title: getFeatureTitle('listManagement'),
                    hint: getFeatureHint('listManagement'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('tagManagement'),
                    hint: getFeatureHint('tagManagement'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('exportToCsv'),
                    hint: getFeatureHint('exportToCsv'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('saveToCrm'),
                    hint: getFeatureHint('saveToCrm'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('saveToSalesEngagementTool'),
                    hint: getFeatureHint('saveToSalesEngagementTool'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.CrmIntegrations]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.CrmIntegrations),
                icon: <HubIcon />,
                features: [
                  {
                    title: getFeatureTitle('salesforce'),
                    hint: getFeatureHint('salesforce'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('hubspot'),
                    hint: getFeatureHint('hubspot'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('zohoCrm'),
                    hint: getFeatureHint('zohoCrm'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('pipedrive'),
                    hint: getFeatureHint('pipedrive'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('zapier'),
                    hint: getFeatureHint('zapier'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('outreach'),
                    hint: getFeatureHint('outreach'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('salesloft'),
                    hint: getFeatureHint('salesloft'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: comingSoon(),
                      [PlanType.Expand]: comingSoon(),
                    },
                  },
                  {
                    title: getFeatureTitle('customIntegration'),
                    hint: getFeatureHint('customIntegration'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Analytics]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Analytics),
                icon: <AnalyticsIcon />,
                features: [
                  {
                    title: getFeatureTitle('usageAnalytics'),
                    hint: getFeatureHint('usageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('teamUsageAnalytics'),
                    hint: getFeatureHint('teamUsageAnalytics'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Compliance]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Compliance),
                icon: <VerifiedUserIcon />,
                features: [
                  {
                    title: getFeatureTitle('gdprCompliant'),
                    hint: getFeatureHint('gdprCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('ccpaCompliant'),
                    hint: getFeatureHint('ccpaCompliant'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.SSO]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.SSO),
                icon: <KeyIcon />,
                features: [
                  {
                    title: getFeatureTitle('google'),
                    hint: getFeatureHint('google'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('microsoft365'),
                    hint: getFeatureHint('microsoft365'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('okta'),
                    hint: getFeatureHint('okta'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('saml'),
                    hint: getFeatureHint('saml'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
              [PlanFeatureCategory.Support]: {
                title: getFeatureCategoryTitle(PlanFeatureCategory.Support),
                icon: <HelpCenterIcon />,
                features: [
                  {
                    title: getFeatureTitle('helpCenter'),
                    hint: getFeatureHint('helpCenter'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('emailSupport'),
                    hint: getFeatureHint('emailSupport'),
                    supportByPlan: {
                      [PlanType.Free]: fullySupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('liveChat'),
                    hint: getFeatureHint('liveChat'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: fullySupported(),
                      [PlanType.Unlimited]: fullySupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('onboarding'),
                    hint: getFeatureHint('onboarding'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                  {
                    title: getFeatureTitle('dedicatedSupport'),
                    hint: getFeatureHint('dedicatedSupport'),
                    supportByPlan: {
                      [PlanType.Free]: unsupported(),
                      [PlanType.Pro]: unsupported(),
                      [PlanType.Unlimited]: unsupported(),
                      [PlanType.Expand]: fullySupported(),
                    },
                  },
                ],
              },
            },
          } satisfies NewPlansMetadata;
        }),
      ),
    {
      deps: [currentPlanType],
    },
  );
};
