import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient } from '@/features/system/network';

const ApiUrl = {
  resetPassword: 'account/email',
  getInfoByIp: 'account/ip-info',
  exchangeAuthCode: 'account',
};

export interface IAuthApiService {
  sendResetPasswordLink(email: string): Observable<boolean>;
  sendEmailVerificationLink(email: string): Observable<boolean>;
  getUserInfoByIp(): Observable<{ country_code: string }>;
}

@injectable()
export class AuthApiService implements IAuthApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  public sendResetPasswordLink(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(ApiUrl.resetPassword, {
        email,
        email_type: 'reset_password',
      })
      .pipe(map((response) => response.data));
  }

  public getUserInfoByIp(): Observable<{ country_code: string }> {
    return this.httpClient.get<{ country_code: string }>(ApiUrl.getInfoByIp).pipe(
      map(({ data }) => {
        return data;
      }),
    );
  }

  public sendEmailVerificationLink(email: string): Observable<boolean> {
    return this.httpClient
      .post<boolean>(ApiUrl.resetPassword, {
        email,
        email_type: 'confirm_email',
      })
      .pipe(map((response) => response.data));
  }
}
