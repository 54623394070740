import { inject, injectable } from 'inversify';
import { combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import { IAccountEntity, IAccountRepository, UserRole } from '../../account';
import type { IWorkspaceEntity, IWorkspaceRepository } from '../../workspace';
import { Permission } from '../domain/types/Permission';

type CheckPermissionFn = (context: {
  account: IAccountEntity;
  workspace: IWorkspaceEntity;
}) => boolean;

const VirtualPermissionMap: Partial<Record<Permission, CheckPermissionFn>> = {
  [Permission.CanManageContactLists]: ({ account }): boolean => {
    return [UserRole.Owner, UserRole.Admin, UserRole.Manager].includes(account.role);
  },
  [Permission.CanManageContactOwner]: ({ account }): boolean => {
    return [UserRole.Owner, UserRole.Admin, UserRole.Manager].includes(account.role);
  },
  [Permission.CanViewProspectTaskProgress]: ({ account }): boolean => {
    return [UserRole.Owner, UserRole.Admin, UserRole.Manager].includes(account.role);
  },
  [Permission.CanManageMembers]: ({ account }): boolean => {
    return [UserRole.Owner, UserRole.Admin].includes(account.role);
  },
  [Permission.CanAccessRefferal]: ({ workspace }): boolean =>
    workspace.subscription.planIsFree,
};

export interface IPermissionsRepository {
  getAccessablePermissions(): Observable<Record<Permission, boolean>>;
  isSuperAdmin(): Observable<boolean>;
  hasPermissions(...permissions: Permission[]): Observable<boolean>;
}

@injectable()
export class PermissionsRepository implements IPermissionsRepository {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private readonly accountRepository: IAccountRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  public getAccessablePermissions(): Observable<Record<Permission, boolean>> {
    return combineLatest({
      account: this.accountRepository.getAccount(),
      workspace: this.workspaceRepository.getCurrentWorkspace(),
    }).pipe(
      filter(({ workspace, account }) => !!workspace && !!account),
      map(
        ({
          workspace,
          account,
        }: {
          workspace: IWorkspaceEntity;
          account: IAccountEntity;
        }) => {
          const result = Object.values(Permission).reduce(
            (acc, permission) => {
              acc[permission] =
                VirtualPermissionMap[permission]?.({
                  workspace,
                  account,
                }) || false;
              return acc;
            },
            {} as Record<Permission, boolean>,
          );
          workspace.permissions.forEach((permission) => {
            result[permission] = true;
          });

          workspace.subscription.planSelectedFeatures.forEach((permission) => {
            result[permission] = true;
          });

          return result;
        },
      ),
    );
  }

  public isSuperAdmin(): Observable<boolean> {
    return this.accountRepository.getAccount().pipe(
      filter((account) => !!account),
      switchMap((account) => of(Boolean(account?.adminEmail))),
    );
  }

  public hasPermissions(...permissions: Permission[]): Observable<boolean> {
    return this.getAccessablePermissions().pipe(
      map((accessablePermissions) => {
        return permissions.every((permission) => accessablePermissions[permission]);
      }),
    );
  }
}
