import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import {
  getBillingCycleFromSubscription,
  getPlanTypeFromSubscription,
  usePaymentCard,
} from '@/features/common/billing';

import { useFormWithSchema } from '@/utils/validation';

import {
  useBillingCycleFromUrl,
  usePlanFromUrl,
  useSeatsFromUrl,
  useSyncValue,
} from '../../hooks';
import { PaymentDetailsContext } from '../PaymentDetailsContext';

import { usePaymentFormSchema } from './usePaymentFormSchema';

export const PaymentFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const { account, billingDetails, paymentMethod, currentWorkspace } =
    useContext(PaymentDetailsContext);

  const currentBillingCycle = getBillingCycleFromSubscription(
    currentWorkspace.subscription,
  );
  const currentPlanType = getPlanTypeFromSubscription(currentWorkspace.subscription);

  const currentSeats = Math.max(
    currentWorkspace.billableMembersCount,
    currentWorkspace.subscription.paidMembersCount,
  );

  const shouldValidateCard = paymentMethod == null;

  const [billingCycle, setBillingCycle] = useBillingCycleFromUrl(currentBillingCycle);
  const [planType, setPlanType] = usePlanFromUrl(currentPlanType);
  const [seats, setSeats] = useSeatsFromUrl(currentSeats);

  const { error: cardError } = usePaymentCard({
    shouldValidate: shouldValidateCard,
  });

  const validationSchema = usePaymentFormSchema({
    cardError,
    minSeats: currentSeats,
  });

  // eslint-disable-next-line no-var
  var form = useFormWithSchema(validationSchema, {
    defaultValues: {
      billingCycle,
      planType,
      paymentMethod,
      billingDetails: {
        name: billingDetails?.name || '',
        email: billingDetails?.email || '',
        address: billingDetails?.address || '',
        city: billingDetails?.city || '',
        state: billingDetails?.state || '',
        postalCode: billingDetails?.postalCode || '',
        country: billingDetails?.country || account.settings.country || '',
        company: billingDetails?.company || '',
        vatId: billingDetails?.vatId || '',
      },
      promotionCode: undefined,
      seats,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  // sync card error with form
  useEffect(() => {
    const isSubmitted = form.formState.isSubmitted;
    if (isSubmitted) {
      form.trigger('paymentMethod.card');
    }
  }, [cardError, form]);

  // we need to mirrow those changes to url state
  useSyncValue(form.watch('planType'), setPlanType);
  useSyncValue(form.watch('billingCycle'), setBillingCycle);
  useSyncValue(form.watch('seats'), setSeats);

  return <FormProvider {...form}>{children}</FormProvider>;
};
