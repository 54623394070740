import { inject, injectable } from 'inversify';
import { distinctUntilKeyChanged, Observable, of, switchMap, throwError } from 'rxjs';

import {
  APP_LOGGER_TYPES,
  BILLING_TYPES,
  PAYMENT_DETAILS_TYPES,
  WORKSPACE_TYPES,
} from '@/ioc/types';

import type { IBillingUseCase, IProductEntity } from '@/features/common/billing';
import { ISubscriptionUseCase } from '@/features/common/workspace';
import { IAppLogger } from '@/features/system/logger';

import { IPaymentDetailsRepository } from '../data';

import type { IPaymentDetailsUseCase } from './abstractions/useCases/IPaymentDetailsUseCase';
import { FullCreditsQuotaNotFoundError } from './errors/FullCreditsQuotaNotFoundError';
import { ProductNotForBuyError } from './errors/ProductNotForBuyError';
import { ProductNotFoundError } from './errors/ProductNotFoundError';
import { WrongUpcomingReceiptTotalError } from './errors/WrongUpcomingReceiptTotalError';
import {
  IReceiptAdjustmentEntity,
  IReceiptEntity,
  IUpcomingReceiptEntity,
  PercentageReceiptAdjustment,
  ReceiptBuilder,
} from './entities';

@injectable()
export class PaymentDetailsUseCase implements IPaymentDetailsUseCase {
  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsRepository)
  private paymentDetailsRepository: IPaymentDetailsRepository;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  @inject(BILLING_TYPES.BillingUseCase)
  private billingUseCase: IBillingUseCase;

  @inject(APP_LOGGER_TYPES.AppLogger)
  private appLogger: IAppLogger;

  getReceipt(params: {
    plan: string;
    seats: number;
    promotionCode?: IReceiptAdjustmentEntity;
  }): Observable<IReceiptEntity> {
    return this.subscriptionUseCase.getSubscription().pipe(
      distinctUntilKeyChanged('stripeCustomerId'),
      switchMap((subscription) => {
        if (subscription.stripeCustomerId) {
          return this.paymentDetailsRepository
            .getUpcomingReceipt({
              plan: params.plan,
              seats: params.seats,
            })
            .pipe(
              switchMap((upcomingReceipt) =>
                this.getLocalReceipt({ ...params, upcomingReceipt }),
              ),
            );
        } else {
          return this.getLocalReceipt(params); // as we don't have a stripe customer id, we can't get the receipt from stripe
        }
      }),
    );
  }

  private mapAnnaulDiscount(params: {
    monthlyPrice: number;
    annualPrice: number;
  }): IReceiptAdjustmentEntity {
    const { monthlyPrice, annualPrice } = params;
    const fixedDiscount = monthlyPrice - annualPrice;
    const percentage = fixedDiscount / monthlyPrice;
    const displayPercent = Math.round(percentage * 100);

    return new PercentageReceiptAdjustment({
      title: `${displayPercent}% annaul discount`,
      percentage: percentage,
      type: 'discount',
    });
  }

  private calculateCredits(product: IProductEntity, seats: number): number | 'unlimited' {
    const fullCredits = product.quotas.find((q) => q.creditType === 'full');

    if (!fullCredits) throw new FullCreditsQuotaNotFoundError();

    if (fullCredits.isUnlimited) return 'unlimited';

    return fullCredits.creditsFixed + fullCredits.creditsPerSeat * seats;
  }

  private getLocalReceipt(params: {
    plan: string;
    seats: number;
    promotionCode?: IReceiptAdjustmentEntity;
    upcomingReceipt?: IUpcomingReceiptEntity;
  }): Observable<IReceiptEntity> {
    return this.billingUseCase.getProducts().pipe(
      switchMap((allProducts) => {
        const { plan, seats } = params;
        const targetProduct = allProducts.find((p) => p.id === plan);

        if (!targetProduct) {
          return throwError(() => new ProductNotFoundError(plan));
        }

        if (targetProduct.status === 'draft') {
          return throwError(() => new ProductNotForBuyError(plan));
        }

        const receiptBuilder = new ReceiptBuilder();

        receiptBuilder.addName(targetProduct.name);
        receiptBuilder.addSeats(seats);
        receiptBuilder.addCycle(targetProduct.cycle);
        receiptBuilder.addIsPriceFixed(targetProduct.isPriceFixed);
        receiptBuilder.addPricePerSeat(targetProduct.price);
        receiptBuilder.addCredits(this.calculateCredits(targetProduct, seats));

        // for annual plans we should show monthly price and discount
        if (targetProduct.cycle === 'annually') {
          const relevantMonthlyProduct = allProducts.find(
            (p) => p.cycle === 'monthly' && p.family === targetProduct.family,
          );

          if (relevantMonthlyProduct) {
            const monthlyPrice = relevantMonthlyProduct.price * 12;
            const annualPrice = targetProduct.price;
            receiptBuilder.addPricePerSeat(monthlyPrice);
            receiptBuilder.addAdjustment(
              this.mapAnnaulDiscount({ monthlyPrice, annualPrice }),
            );
          }
        }

        if (params.upcomingReceipt) {
          params.upcomingReceipt.adjustments.forEach((adjustment) =>
            receiptBuilder.addAdjustment(adjustment),
          );
        }

        if (params.promotionCode) {
          receiptBuilder.addPromotion(params.promotionCode);
        }

        const receipt = receiptBuilder.build();

        if (
          params.upcomingReceipt &&
          receipt.total.toFixed(2) !== params.upcomingReceipt.total.toFixed(2)
        ) {
          this.appLogger.error(
            new WrongUpcomingReceiptTotalError({
              upcomingReceipt: params.upcomingReceipt,
              predictedReceipt: receipt,
            }),
          );
        }

        return of(receipt);
      }),
    );
  }

  getPromocode(params: {
    code: string;
    plan: string;
  }): Observable<IReceiptAdjustmentEntity> {
    return this.paymentDetailsRepository.getPromocode(params);
  }
}
