import type { IWorkspaceSubscriptionEntity, SubscriptionPlan } from '../../domain';
import type { IWorkspaceSubscriptionDC } from '../dataContracts';

export const mapWorkspaceSubscriptionDcToEntity = (
  dc: IWorkspaceSubscriptionDC,
): IWorkspaceSubscriptionEntity => {
  return {
    plan: dc.plan,
    futurePlan: dc.future_plan as SubscriptionPlan,
    credits: dc.credits
      ? dc.credits.map((credit) => ({
          used: credit.used,
          limit: credit.limit,
          creditType: credit.credit_type,
          createdAt: credit.created_at,
          expiresAt: credit.expires_at,
        }))
      : [],
    purchaseType: dc.purchase_type,
    startDate: dc.start_date,
    expirationDate: dc.expiration_date,
    paid: dc.paid ?? false,
    isGracePeriod: dc.is_grace_period ?? false,
    isCanceled: dc.is_canceled ?? false,
    isGift: dc.purchase_type === 'gift',
    paidMembersCount: dc.paid_members_count ?? 1,
    billingDetailsFilled: dc.billing_details_filled ?? false,
    promoCodeId: dc.promo_code_id,
    isActive: dc.is_active ?? false,
    isIndividual: dc.is_individual ?? false,
    recurringPrice: dc.recurring_price,
    stripeCustomerId: dc.stripe_customer_id,
    monthlyFairLimit: dc.monthly_fair_limit ?? 0,
    dailyFairLimit: dc.daily_fair_limit ?? 0,
    billingCycle: ((): 'daily' | 'monthly' | 'annually' => {
      if (/monthly/.test(dc.plan)) return 'monthly';
      if (/annually/.test(dc.plan)) return 'annually';
      return 'daily';
    })(),
    planName: dc.plan_name ?? 'Free',
    planIsFree: /^free/.test(dc.plan),
    planIsCustom: dc.plan_is_custom ?? false,
    planIsUnlimited: dc.plan_is_unlimited ?? false,
    planIsCreditBased: !dc.plan_is_unlimited,
    planIsPaidMembersCountFixed: dc.plan_is_paid_members_count_fixed ?? false,
    planSelectedFeatures: dc.plan_selected_features ?? [],
    planPaidMembersLimit: dc.plan_paid_members_limit,
  };
};

export const mapWorkspaceSubscriptionEntityToDc = (
  entity: IWorkspaceSubscriptionEntity,
): IWorkspaceSubscriptionDC => {
  return {
    plan: entity.plan,
    future_plan: entity.futurePlan,
    credits: entity.credits.map((credit) => ({
      used: credit.used,
      limit: credit.limit,
      credit_type: credit.creditType,
      created_at: credit.createdAt,
      expires_at: credit.expiresAt,
    })),
    purchase_type: entity.purchaseType,
    start_date: entity.startDate,
    expiration_date: entity.expirationDate,
    paid: entity.paid,
    is_grace_period: entity.isGracePeriod,
    is_canceled: entity.isCanceled,
    paid_members_count: entity.paidMembersCount,
    billing_details_filled: entity.billingDetailsFilled,
    promo_code_id: entity.promoCodeId,
    is_active: entity.isActive,
    is_individual: entity.isIndividual,
    recurring_price: entity.recurringPrice,
    stripe_customer_id: entity.stripeCustomerId,
    monthly_fair_limit: entity.monthlyFairLimit,
    daily_fair_limit: entity.dailyFairLimit,
    plan_name: entity.planName,
    plan_is_custom: entity.planIsCustom,
    plan_is_unlimited: entity.planIsUnlimited,
    plan_is_paid_members_count_fixed: entity.planIsPaidMembersCountFixed,
    plan_selected_features: entity.planSelectedFeatures,
    plan_paid_members_limit: entity.planPaidMembersLimit,
  };
};
