import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import ModalIcon from './assets/ModalIcon.svg?react';

import styles from './styles.module.scss';

export const StartViewRateLimit: FC = () => {
  const { t } = useTranslation('enrichment');

  const handleButtonClick = (): void => {
    window.open(EXTERNAL_ROUTES.TALK_TO_SALES);
  };

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Icon}>
        <ModalIcon />
      </Box>
      <Typography variant="h3" className={styles.Title}>
        {t('uploadingRateLimitModal.title')}
      </Typography>
      <Typography variant="subtitle1" className={styles.Text}>
        {t('uploadingRateLimitModal.info')}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        className={styles.Button}
        onClick={handleButtonClick}
      >
        {t('uploadingRateLimitModal.button')}
      </Button>
    </Box>
  );
};
