import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc/ioc.react';
import { RESET_PASSWORD_TYPES } from '@/ioc/types';

import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import {
  getConfirmPasswordValidationSchema,
  PasswordValidationSchema,
  useFormWithSchema,
} from '@/utils/validation';

import { IResetPasswordUseCases } from '../domain/ResetPasswordUseCases';

const ResetPasswordFormSchema = yup.object({
  newPassword: PasswordValidationSchema,
  confirmPassword: getConfirmPasswordValidationSchema('newPassword'),
});

type ResetPasswordFormType = yup.InferType<typeof ResetPasswordFormSchema>;

const useResetPasswordViewModel = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'resetPassword' });
  const isMountedRef = useRef(false);

  const resetPasswordUseCases = useInjection<IResetPasswordUseCases>(
    RESET_PASSWORD_TYPES.ResetPasswordUseCases,
  );
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { trackEvent } = useAnalytics();
  const oobCode = queryParams.get('oobCode') ?? '';

  const form = useFormWithSchema(ResetPasswordFormSchema, {
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (isMountedRef.current) {
        return;
      }

      isMountedRef.current = true;

      trackEvent(ANALYTICS_EVENTS.VIEW_RESET_PASSWORD_PAGE);
      await resetPasswordUseCases.checkOobCode(oobCode);
      snackbar.enqueueSnackbar({
        variant: 'error',
        message: t('failed.title'),
        description: t('failed.description'),
      });

      navigate(ROUTES.DASHBOARD);
    })();
  }, []);

  const handleSubmit = (): void => {
    void form.handleSubmit(async ({ newPassword }: ResetPasswordFormType) => {
      try {
        await resetPasswordUseCases.resetPassword(oobCode, newPassword);
        trackEvent(ANALYTICS_EVENTS.RESET_PASSWORD_COMPLETE);
        snackbar.enqueueSnackbar({
          variant: 'success',
          message: t('success.title'),
          description: t('success.description'),
        });
        navigate(ROUTES.DASHBOARD);
      } catch (error) {
        form.setError('newPassword', error.data?.message || error?.mess);
      }
    })();
  };

  return {
    onSubmit: handleSubmit,
    form,
  };
};
export default useResetPasswordViewModel;
