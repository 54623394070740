import { FC } from 'react';
import { Box } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { mergeDeepRight } from 'ramda';

import { ArrowRightIcon } from '@/components/Icons';

import { PickerValue } from '../../types';

import { usePickerViewModel } from './usePickerViewModel';

import styles from './styles.module.scss';

type PickerProps = {
  onChange: (value: PickerValue) => void;
  defaultValue: string;
  minDateTimestamp?: number;
};

export const Picker: FC<PickerProps> = (props) => {
  const { open, minDate, value, handelClose, handleChange, handleInputClick } =
    usePickerViewModel(props);

  return (
    <Box className={styles.wrapper}>
      <DateRangePicker
        disableFuture
        minDate={minDate}
        open={open}
        calendars={1}
        value={value}
        slotProps={{
          fieldSeparator: {
            className: styles.separator,
            children: (
              <Box component="span" className={styles.icon}>
                <ArrowRightIcon />
              </Box>
            ),
          },
          textField: ({ position }) => {
            let props = {
              classes: {
                root: styles.field,
              },
              onClick: handleInputClick,
            };
            if (position === 'start') {
              props = mergeDeepRight(props, {
                InputProps: {
                  placeholder: 'Start date',
                },
              });
            } else {
              props = mergeDeepRight(props, {
                InputProps: {
                  placeholder: 'End date',
                },
              });
            }
            return props;
          },
        }}
        onChange={handleChange}
        onClose={handelClose}
      />
    </Box>
  );
};
