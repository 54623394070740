import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid';

import { useAccount } from '@/features/common/account';
import { IInvitationEntity } from '@/features/referral/domain/entities';

import { useObservableResult } from '@/utils/rx';

import { useReferralUseCase } from '../../hooks/useReferralUseCase';

import {
  TableCellActions,
  TableCellEmail,
  TableCellLastActivity,
  TableCellStatus,
} from './components';

type ViewModel = () => {
  columns: GridColDef[];
  rows: IInvitationEntity[];
  credits: number;
};

export const useInviteeTableSectionViewModel: ViewModel = () => {
  const { t } = useTranslation('referral', { keyPrefix: 'inviteesSection' });
  const referralUseCase = useReferralUseCase();
  const { account } = useAccount();

  const { data: invitations } = useObservableResult(
    () => referralUseCase.getInvitations(),
    { defaultData: [], deps: [] },
  );

  const columns: GridColDef[] = [
    {
      headerName: t('table.email'),
      minWidth: 300,
      field: 'inviteeEmail',
      disableColumnMenu: true,
      sortable: true,
      flex: 1,
      renderCell: ({ value }): ReactElement => <TableCellEmail value={value} />,
    },
    {
      headerName: t('table.lastActivity'),
      field: 'lastActivity',
      disableColumnMenu: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }): ReactElement => <TableCellLastActivity value={value} />,
    },
    {
      headerName: t('table.status'),
      field: 'status',
      disableColumnMenu: true,
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }): ReactElement => <TableCellStatus status={value} />,
    },
    {
      headerName: '',
      field: '',
      type: 'actions',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: ({ row: { uuid, inviteeEmail, status } }): ReactElement => (
        <TableCellActions uuid={uuid} email={inviteeEmail} status={status} />
      ),
    },
  ];

  return {
    columns,
    rows: invitations,
    credits: account?.referrerCreditsEarned ?? 0,
  };
};
