import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { Permission, usePermissions } from '@/features/common/permissions';

import { useUserMenuHandlers } from '@/components/header/hooks';

type ViewModel = {
  canManageCredits: boolean;
  onManageCredits: () => void;
  onClose: () => void;
};

export const useAccountCreditsViewModel = (): ViewModel => {
  const navigate = useNavigate();
  const { onClose } = useUserMenuHandlers();
  const { hasPermissions } = usePermissions();

  const canManageCredits = hasPermissions(Permission.CanAccessBilling);

  const onManageCredits = (): void => {
    onClose();
    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
  };

  return {
    canManageCredits,
    onManageCredits,
    onClose,
  };
};
