import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import { CallToAction } from '@/features/callToAction';
import { CreditsLeftCounter } from '@/features/creditsCounter';

import { useAccountCreditsViewModel } from './useAccountCreditsViewModel';

import styles from './styles.module.scss';

export const AccountCredits: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const { canManageCredits, onManageCredits, onClose } = useAccountCreditsViewModel();

  return (
    <Box
      className={clsx(styles.accountCredits, { [styles.countOnly]: !canManageCredits })}
    >
      <CreditsLeftCounter className={styles.creditsLeft} />
      <CallToAction
        show={['getCredits', 'inviteTeamMember']}
        size="small"
        variant="outlined"
        onClick={onClose}
        className={styles.cta}
      />
      {canManageCredits ? (
        <Button variant="text" onClick={onManageCredits} className={styles.manageCredits}>
          {t('manageCredits')}
        </Button>
      ) : null}
    </Box>
  );
};
