import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { map } from 'rxjs';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc';
import { WORKSPACE_TYPES } from '@/ioc/types';

import { type IPaymentMethodEntity, useBillingUseCase } from '@/features/common/billing';
import type { ISubscriptionUseCase } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { ModalControler, useModalController } from '@/hooks';

import { ObservableResult, useObservableResult } from '@/utils/rx';
import { startViewTransition } from '@/utils/startViewTransition';

import type { IPaymentMethodDetailsEntity } from '../../../domain/entities/PaymentMethodDetailsEntity';
import { useBillingSettingsUseCase } from '../../hooks/useBillingSettingsUseCase';

type UsePamynentDetailsViewModel = () => {
  isVisible: boolean;
  isCardChanging: boolean;
  paymentDetails: ObservableResult<
    {
      status: 'active' | 'canceled' | 'suspended' | 'grace';
      upcomingInvoice: {
        amount: string;
        expiresAt: string | undefined;
      } | null;
      method: Nullable<IPaymentMethodEntity>;
      canChangeCard: boolean;
      isGift: boolean;
    },
    unknown,
    null
  >;
  handleChangeCardClick: () => void;
  handleCancelSubscriptionClick: () => void;
  handleCancelSubscriotionWarningClose: () => void;
  handleCancelSubscriptionWarningConfirm: () => void;
  handleCancelSubscriptionDialogClose: () => void;
  handleResubsribeSubscriptionClick: () => Promise<void>;
  cancelSubscriptionDialog: ModalControler;
  cancelSubscriptionWarning: ModalControler;
};

const CARD_UPDATED_QUERY_KEY = 'cardUpdated';

export const usePaymentDetailsViewModel: UsePamynentDetailsViewModel = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const analytics = useAnalytics();
  const logger = useAppLogger();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation('billingSettings', { keyPrefix: 'paymentDetails' });

  const billingSettingsUseCase = useBillingSettingsUseCase();
  const billingUseCase = useBillingUseCase();
  const cancelSubscriptionDialog = useModalController();
  const cancelSubscriptionWarning = useModalController();

  const [isCardChanging, setIsCardChanging] = useState(false);

  const subscriptionUseCase = useInjection<ISubscriptionUseCase>(
    WORKSPACE_TYPES.SubscriptionUseCase,
  );

  const [formatter] = useState(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
  );

  useEffect(() => {
    const cardUpdated = searchParams.get(CARD_UPDATED_QUERY_KEY);

    if (cardUpdated) {
      snackbar.enqueueSnackbar(t('paymentMethodUpdated.title'), {
        variant: 'success',
        description: t('paymentMethodUpdated.description'),
        onClose: () => {
          searchParams.delete(CARD_UPDATED_QUERY_KEY);
          setSearchParams(searchParams);
        },
      });
    }
  }, []);

  const formatUpcomingInvoice = (
    upcomingInvoice: IPaymentMethodDetailsEntity['upcomingInvoice'],
  ): { amount: string; expiresAt: string | undefined } | null => {
    if (!upcomingInvoice) return null;

    return {
      amount: formatter.format(upcomingInvoice.ammount / 100),
      expiresAt: upcomingInvoice.expiresAt?.toLocaleString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
    };
  };

  const paymentDetails = useObservableResult(() =>
    billingSettingsUseCase.getPaymentMethodDetails().pipe(
      map(({ status, upcomingInvoice, method, isGift }) => ({
        isGift,
        method,
        canChangeCard: !!method,
        status,
        upcomingInvoice: formatUpcomingInvoice(upcomingInvoice),
      })),
    ),
  );

  const isFreePlan = useObservableResult(() => subscriptionUseCase.getIsFreePlan(), {
    defaultData: true,
  });

  const handleCancelSubscriptionClick = (): void => {
    startViewTransition(() => {
      cancelSubscriptionWarning.onOpen();
    });
  };

  const handleCancelSubscriotionWarningClose = (): void => {
    startViewTransition(() => {
      cancelSubscriptionWarning.onClose();
    });
  };

  const handleCancelSubscriptionWarningConfirm = (): void => {
    startViewTransition(() => {
      cancelSubscriptionDialog.onOpen();
    });
  };

  const handleCancelSubscriptionDialogClose = (): void => {
    cancelSubscriptionDialog.onClose();
    cancelSubscriptionWarning.onClose();
  };

  const handleResubsribeSubscriptionClick = async (): Promise<void> => {
    const status = paymentDetails.data?.status;

    if (status === 'suspended') {
      navigate(ROUTES.BILLING.PAYMENT_DETAILS);
      return;
    }

    if (status === 'canceled') {
      try {
        await subscriptionUseCase.renew();
        snackbar.enqueueSnackbar(t('subscriptionRenewed.title'), {
          variant: 'success',
          description: t('subscriptionRenewed.description'),
        });
      } catch (e) {
        logger.error(e);
        snackbar.enqueueSnackbar(t('subscriptionRenewFailed.title'), {
          variant: 'error',
          description: t('subscriptionRenewFailed.description'),
        });
      }
    }
  };

  const handleChangeCardClick = async (): Promise<void> => {
    analytics.trackEvent(ANALYTICS_EVENTS.CLICK_CHANGE_CARD);

    try {
      setIsCardChanging(true);
      const { url } = await billingUseCase.updatePaymentMethod({
        successUrl: `${window.location.href}?${CARD_UPDATED_QUERY_KEY}=true`,
        cancelUrl: window.location.href,
      });
      window.open(url, '_self');
    } catch (e) {
      logger.error(e);

      snackbar.enqueueSnackbar(t('paymentMethodUpdateFailed.title'), {
        variant: 'error',
        description: t('paymentMethodUpdateFailed.description'),
      });
    } finally {
      setIsCardChanging(false);
    }
  };

  return {
    paymentDetails,
    isVisible: !isFreePlan.data,
    isCardChanging,
    handleChangeCardClick,
    handleCancelSubscriptionClick,
    handleCancelSubscriotionWarningClose,
    handleCancelSubscriptionWarningConfirm,
    handleCancelSubscriptionDialogClose,
    handleResubsribeSubscriptionClick,
    cancelSubscriptionDialog,
    cancelSubscriptionWarning,
  };
};
