import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import type { IPaymentDetailsApiService } from '../abstractions';
import type { IStripePromotionCodeDC } from '../dataContracts';

@injectable()
export class PaymentDetailsApiService implements IPaymentDetailsApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  getPromotionCode(params: {
    code: string;
    plan: string;
  }): Observable<IStripePromotionCodeDC> {
    const searchParams = new URLSearchParams();
    searchParams.set('code', params.code);
    searchParams.set('label', params.plan);

    return this.httpClient
      .get<IStripePromotionCodeDC>(
        `/billing-service/stripe/promo-code-info?${searchParams.toString()}`,
      )
      .pipe(map(({ data }) => data));
  }
}
