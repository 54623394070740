import type { IUpcomingInvoiceEntity } from '@/features/common/billing';

import {
  FixedReceiptAdjustment,
  type IReceiptAdjustmentEntity,
  type IUpcomingReceiptEntity,
} from '../../domain';

const PREVIOUS_PLAN_ADJ_RETURN_DESCRIPTION = 'Unused time on'; // discount in case of upgrade
const PRORATED_ADJ_DESCRIPTION = 'Remaining time on';

export const mapUpcomingReceipt = (
  upcomingInvoice: IUpcomingInvoiceEntity,
): IUpcomingReceiptEntity => {
  const adjustments: IReceiptAdjustmentEntity[] = [];

  const prevPlanAdj = upcomingInvoice.lines.data.find((line) =>
    line.description.includes(PREVIOUS_PLAN_ADJ_RETURN_DESCRIPTION),
  );

  if (prevPlanAdj) {
    adjustments.push(
      new FixedReceiptAdjustment({
        title: 'Previous plan refund',
        amount: Math.abs(prevPlanAdj.amount / 100),
        type: 'discount',
      }),
    );
  }

  const proratedAdj = upcomingInvoice.lines.data.find((line) =>
    line.description.includes(PRORATED_ADJ_DESCRIPTION),
  );

  if (proratedAdj) {
    const newPlanPrice = proratedAdj.plan.amount * proratedAdj.quantity;
    const priceToPayFromNewPlan = proratedAdj.amount;

    const proratedDiscount = newPlanPrice - priceToPayFromNewPlan;

    const type = 'discount' as const;

    adjustments.push(
      new FixedReceiptAdjustment({
        title: `Prorated ${type}`,
        amount: Math.abs(proratedDiscount / 100),
        type,
      }),
    );
  }

  return {
    adjustments,
    total: upcomingInvoice.total / 100,
  };
};
