import { useEffect, useState } from 'react';

import { isDateSelectOptionValue } from '../enums';
import { Option } from '../types';

import { useDateSelectOptions } from './useDateSelectOptions';

export const useDateSelectViewModel = ({
  defaultValue,
  onChange,
}: {
  defaultValue: string | null;
  onChange: (value: string) => void;
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorElement);
  const { options, getCustomOption } = useDateSelectOptions();
  const [value, setValue] = useState(() => {
    if (isDateSelectOptionValue(defaultValue)) {
      return options.find((option) => option.value === defaultValue) as Option;
    }
    return getCustomOption(defaultValue ?? '');
  });

  const handleAnchorClick = (e: React.MouseEvent<HTMLElement>): void => {
    setAnchorElement((prev) => {
      if (prev) return null;
      return e.currentTarget;
    });
  };

  const close = (): void => {
    setAnchorElement(null);
  };

  const onSelect = (val: Option): void => {
    setValue(val);
  };

  useEffect(() => {
    if (!value.value) return;

    onChange(value.value);
    close();
  }, [value]);

  return {
    value,
    isOpen,
    anchorElement,
    close,
    handleAnchorClick,
    options,
    onSelect,
    cutomOption: getCustomOption(value.value),
  };
};
