import { ChangeEvent, CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BillingCycle, useAnnualDiscount } from '@/features/common/billing';

import styles from './styles.module.scss';

function mapBillingCycleToChecked(billingCycle: BillingCycle): boolean {
  return billingCycle === BillingCycle.Yearly;
}

function mapCheckedToBillingCycle(checked: boolean): BillingCycle {
  return checked ? BillingCycle.Yearly : BillingCycle.Monthly;
}

type MonthlyYearlySwitcherProps = {
  value: BillingCycle;
  onChange: (value: BillingCycle) => void;
};

export const MonthlyYearlySwitcher: FC<MonthlyYearlySwitcherProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation('plans', { keyPrefix: 'switcher' });
  const discount = useAnnualDiscount() * 100;
  const discountString = discount.toFixed(0);

  const [linkStyles, setLinkStyles] = useState<CSSProperties>({
    left: 0,
    width: 0,
  });

  const checked = mapBillingCycleToChecked(value);
  const unchckedRef = useRef<HTMLSpanElement>(null);
  const checkedRef = useRef<HTMLSpanElement>(null);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    onChange(mapCheckedToBillingCycle(event.target.checked));
  }

  useEffect(() => {
    const node = checked ? checkedRef.current : unchckedRef.current;
    const aLeftOffset = node?.offsetLeft ?? 0;
    const aWidth = node?.getClientRects()[0].width ?? 0;

    setLinkStyles({
      left: aLeftOffset,
      width: aWidth,
    });
  }, [checked]);

  return (
    <label role="switch" className={styles.switcher}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <a style={linkStyles} />
      <span ref={unchckedRef}>{t('monthly')}</span>
      <span ref={checkedRef} data-note={t('save', { discount: discountString })}>
        {t('annually')}
      </span>
    </label>
  );
};
