import { inject, injectable } from 'inversify';
import { catchError, combineLatest, iif, map, Observable, of, switchMap } from 'rxjs';

import { BILLING_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IBillingUseCase } from '@/features/common/billing/domain';
import type {
  IAggregatedCreditEntity,
  ICreditEntity,
  ICreditUseCase,
  ISubscriptionUseCase,
  IWorkspaceSubscriptionEntity,
} from '@/features/common/workspace';
import { isCreditIssuer } from '@/features/common/workspace/domain';

import { distinctUntilKeysChanged } from '@/utils/rx';

import type { IBillingSettingsUseCase } from './abstractions/IBillingSettingsUseCase';
import type { ICreditsDetailsEntity } from './entities/CreditsDetailsEntity';
import type { IPaymentMethodDetailsEntity } from './entities/PaymentMethodDetailsEntity';
import type { IPlanDetailsEntity } from './entities/PlanDetailsEntity';

@injectable()
export class BillingSettingsUseCase implements IBillingSettingsUseCase {
  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private readonly subscriptionUseCase: ISubscriptionUseCase;

  @inject(BILLING_TYPES.BillingUseCase)
  private readonly billingUseCase: IBillingUseCase;

  @inject(WORKSPACE_TYPES.CreditUseCase)
  private readonly creditUseCase: ICreditUseCase;

  private getCreditsLeft(credits?: IAggregatedCreditEntity): number {
    return credits ? Math.max(credits.limit - credits.used, 0) : 0;
  }

  public getCurrentPlanDetails(): Observable<IPlanDetailsEntity> {
    return this.subscriptionUseCase.getSubscription().pipe(
      distinctUntilKeysChanged(
        'plan',
        'planIsUnlimited',
        'paidMembersCount',
        'billingCycle',
        'expirationDate',
      ),
      switchMap((subscription) => {
        return combineLatest({
          subscription: of(subscription),
          fullCredits: this.creditUseCase.getFullCredits(),
        });
      }),
      map(({ subscription, fullCredits }) => {
        const isUnlimited = subscription.planIsUnlimited;
        const planCreditsInfo = fullCredits.find(
          (credit) => credit.issuer === 'plan' && credit.isActive,
        );

        return {
          currentPlan: subscription.planName,
          users: subscription.paidMembersCount || 1,
          planCredits: isUnlimited ? 'unlimited' : planCreditsInfo?.issuedCredits || 0,
          billingCycle: subscription.billingCycle,
          creditsRenewOn: subscription.expirationDate
            ? new Date(subscription.expirationDate * 1000)
            : undefined,
        } satisfies IPlanDetailsEntity;
      }),
    );
  }

  public getCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return this.subscriptionUseCase
      .getIsUnlimitedPlan()
      .pipe(
        switchMap((isUnlimited) =>
          iif(
            () => isUnlimited,
            this.unlimitedCreditsDetails(),
            this.creditBasedCreditsDetails(),
          ),
        ),
      );
  }

  private unlimitedCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return of({
      totalCreditsLeft: 'unlimited',
      bySources: {},
    });
  }

  private getPlanCreditsDetails(
    subscription: IWorkspaceSubscriptionEntity,
    creditDetails: Nullable<ICreditEntity>,
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (!creditDetails) return undefined;

    return {
      limit: creditDetails.issuedCredits,
      left: Math.max(creditDetails.issuedCredits - (creditDetails?.usedCredits ?? 0), 0),
      renewAt: subscription.expirationDate
        ? new Date(subscription.expirationDate * 1000)
        : undefined,
      plan: subscription.planName,
    };
  }

  private getRefferalCreditsDetails(
    credits: ICreditEntity[],
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (credits.length === 0) return undefined;

    return credits.reduce(
      (acc, credit) => {
        acc.limit += credit.issuedCredits;
        acc.left += credit.issuedCredits - (credit.usedCredits ?? 0);

        return acc;
      },
      {
        limit: 0,
        left: 0,
      },
    );
  }

  private getRolledUpCreditsDetails(
    credits: ICreditEntity[],
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (credits.length === 0) return undefined;

    return credits.reduce(
      (acc, credit) => {
        acc.limit += credit.issuedCredits;
        acc.left += credit.issuedCredits - (credit.usedCredits ?? 0);

        return acc;
      },
      {
        limit: 0,
        left: 0,
      },
    );
  }

  private getGiftCreditsDetails(
    credits: ICreditEntity[],
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (credits.length === 0) return undefined;

    return credits.reduce(
      (acc, credit) => {
        acc.limit += credit.issuedCredits;
        acc.left += credit.issuedCredits - (credit.usedCredits ?? 0);

        return acc;
      },
      {
        limit: 0,
        left: 0,
      },
    );
  }

  private getUnknownCreditsDetails(
    credits: ICreditEntity[],
  ): ValuesOf<ICreditsDetailsEntity['bySources']> {
    if (credits.length === 0) return undefined;

    return credits.reduce(
      (acc, credit) => {
        acc.limit += credit.issuedCredits;
        acc.left += credit.issuedCredits - (credit.usedCredits ?? 0);

        return acc;
      },
      {
        limit: 0,
        left: 0,
      },
    );
  }

  private creditBasedCreditsDetails(): Observable<ICreditsDetailsEntity> {
    return this.subscriptionUseCase.getSubscription().pipe(
      distinctUntilKeysChanged('credits', 'plan', 'planName', 'expirationDate'),
      switchMap((subscription) => {
        return combineLatest({
          fullCredits: this.creditUseCase.getFullCredits(),
          subscription: of(subscription),
        });
      }),
      map(({ fullCredits, subscription }) => {
        const credits = subscription.credits.find(
          (credit) => credit.creditType === 'full',
        );

        if (!credits) {
          return {
            totalCreditsLeft: 0,
            bySources: {},
          } satisfies ICreditsDetailsEntity;
        }

        const planCreditDetails: Nullable<ICreditEntity> = fullCredits.find(
          (credit) => credit.issuer === 'plan' && credit.isActive,
        );
        const referralCredits: ICreditEntity[] = fullCredits.filter(
          (credit) => credit.issuer === 'referral' && credit.isActive,
        );
        const rolledUpCredits: ICreditEntity[] = fullCredits.filter(
          (credit) => credit.issuer === 'rolled_up' && credit.isActive,
        );
        const giftCredits: ICreditEntity[] = fullCredits.filter(
          (credit) => credit.issuer === 'gift' && credit.isActive,
        );
        const unknownCredits: ICreditEntity[] = fullCredits.filter(
          (credit) => !isCreditIssuer(credit.issuer) && credit.isActive,
        );

        return {
          totalCreditsLeft: this.getCreditsLeft(credits),
          bySources: {
            plan: this.getPlanCreditsDetails(subscription, planCreditDetails),
            referral: this.getRefferalCreditsDetails(referralCredits),
            rolled_up: this.getRolledUpCreditsDetails(rolledUpCredits),
            gift: this.getGiftCreditsDetails(giftCredits),
            unknown: this.getUnknownCreditsDetails(unknownCredits),
          },
        };
      }),
    );
  }

  private resolveStatus(
    subscription: IWorkspaceSubscriptionEntity,
  ): 'active' | 'canceled' | 'suspended' | 'grace' {
    if (!subscription.isActive) return 'suspended';
    if (subscription.isCanceled) return 'canceled';
    if (subscription.isGracePeriod) return 'grace';

    return 'active';
  }

  public getPaymentMethodDetails(): Observable<IPaymentMethodDetailsEntity> {
    return this.subscriptionUseCase.getSubscription().pipe(
      distinctUntilKeysChanged('plan', 'paidMembersCount', 'expirationDate'),
      switchMap((subscription) => {
        return combineLatest({
          subscription: of(subscription),
          paymentMethod: this.billingUseCase
            .getPaymentMethod()
            .pipe(catchError(() => of(null))),
          upcomingInvoice: subscription.planIsFree
            ? of(null)
            : this.billingUseCase
                .getUpcomingInvoice({
                  plan: subscription.plan,
                })
                .pipe(catchError(() => of(null))),
        });
      }),
      map(({ subscription, paymentMethod, upcomingInvoice }) => {
        return {
          method: paymentMethod,
          upcomingInvoice: upcomingInvoice
            ? {
                ammount: upcomingInvoice.total,
                expiresAt: upcomingInvoice.next_payment_attempt
                  ? new Date(upcomingInvoice.next_payment_attempt * 1000)
                  : subscription.expirationDate
                    ? new Date(subscription.expirationDate * 1000)
                    : undefined,
              }
            : undefined,
          status: this.resolveStatus(subscription),
          isGift: subscription.isGift,
        } satisfies IPaymentMethodDetailsEntity;
      }),
    );
  }
}
