import { inject, injectable } from 'inversify';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';

import { BILLING_TYPES, PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import type { IBillingRepository } from '@/features/common/billing';

import type { IReceiptAdjustmentEntity, IUpcomingReceiptEntity } from '../domain';
import { PromotionExpiredError } from '../domain/errors/PromotionExpiredError';
import { PromotionNotFoundError } from '../domain/errors/PromotionNotFoundError';

import { IPaymentDetailsApiService, IPaymentDetailsRepository } from './abstractions';
import { mapPromotionCodeToAdjustment, mapUpcomingReceipt } from './mappers';

@injectable()
export class PaymentDetailsRepository implements IPaymentDetailsRepository {
  @inject(PAYMENT_DETAILS_TYPES.PaymentDetailsApiService)
  private api: IPaymentDetailsApiService;

  @inject(BILLING_TYPES.BillingRepository)
  private billingRepository: IBillingRepository;

  getPromocode(params: {
    code: string;
    plan: string;
  }): Observable<IReceiptAdjustmentEntity> {
    return this.api.getPromotionCode(params).pipe(
      catchError(() => throwError(() => new PromotionNotFoundError())),
      switchMap((promoCode) => {
        if (promoCode.expires_at) {
          const expiresAt = new Date(promoCode.expires_at);

          const now = new Date();
          if (expiresAt.getTime() < now.getTime()) {
            return throwError(() => new PromotionExpiredError());
          }
        }

        if (promoCode.active === false) {
          return throwError(() => new PromotionExpiredError());
        }

        return of(promoCode);
      }),
      map((dc) => mapPromotionCodeToAdjustment(dc)),
    );
  }

  getUpcomingReceipt(params: {
    plan: string;
    seats: number;
  }): Observable<IUpcomingReceiptEntity> {
    return this.billingRepository
      .getUpcomingInvoice({
        plan: params.plan,
        quantity: params.seats,
      })
      .pipe(map((dc) => mapUpcomingReceipt(dc)));
  }
}
