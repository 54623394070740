import { useTranslation } from 'react-i18next';

import { UserRole } from '@/features/common/account';

export const useTeamMemberRoleOptions = () => {
  const { t } = useTranslation('settings');
  return [
    {
      value: UserRole.Admin,
      label: t('teamManagement.inviteDialog.roleAdminTitle'),
      description: t('teamManagement.inviteDialog.roleAdminDescription'),
    },
    {
      value: UserRole.Member,
      label: t('teamManagement.inviteDialog.roleUserTitle'),
      description: t('teamManagement.inviteDialog.roleUserDescription'),
    },
    {
      value: UserRole.Manager,
      label: t('teamManagement.inviteDialog.roleManagerTitle'),
      description: t('teamManagement.inviteDialog.roleManagerDescription'),
    },
  ];
};
