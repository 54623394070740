import { v4 as uuidv4 } from 'uuid';

import type { IReceiptAdjustmentEntity } from './ReceiptAdjustmentEntity';

export class FixedReceiptAdjustment implements IReceiptAdjustmentEntity {
  public id: string;
  public amount: number;
  public title: string;
  public description?: string | undefined;
  public type: 'discount' | 'surcharge';

  private applyiedPercentage?: number = undefined;

  get percentage(): number {
    if (this.applyiedPercentage === undefined) {
      throw new Error('Percentage is not calculated yet');
    }

    return this.applyiedPercentage;
  }

  constructor(params: {
    amount: number;
    title: string;
    type: 'discount' | 'surcharge';
    description?: string;
  }) {
    this.id = uuidv4();
    this.amount = params.amount;
    this.title = params.title;
    this.description = params.description;
    this.type = params.type;
  }

  apply(sum: number): number {
    this.applyiedPercentage = this.amount / sum;

    if (this.type === 'discount') {
      return sum - this.amount;
    }

    return sum + this.amount;
  }
}
