import { useInjection } from '@/ioc';
import { WORKSPACE_TYPES } from '@/ioc/types';

import type { ISubscriptionUseCase } from '@/features/common/workspace';

import { ObservableResult, useObservableResult } from '@/utils/rx';

type UsePlanCardViewModelReturn = Pick<ObservableResult<boolean>, 'isLoading' | 'data'>;

export const usePlanCardViewModel = (): UsePlanCardViewModelReturn => {
  const subscriptionUseCase = useInjection<ISubscriptionUseCase>(
    WORKSPACE_TYPES.SubscriptionUseCase,
  );

  return useObservableResult(() => subscriptionUseCase.getIsFreePlan());
};
