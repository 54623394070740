import { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from 'react';
import { map } from 'rxjs';

import { ErrorPage500 } from '@/router/error';

import { useInjection } from '@/ioc';
import { BILLING_TYPES } from '@/ioc/types';

import { useAccount } from '@/features/common/account';
import {
  IBillingUseCase,
  useGetBillingDetails,
  useGetPaymentMethod,
} from '@/features/common/billing';
import { useCurrentWorkspace } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { useBillingCycleFromUrl, usePlanFromUrl } from '../../hooks';

import { PaymentDetailsContext, type PaymentDetailsContextType } from './Context';

type NullableFields<T extends object> = {
  [K in keyof T]: Nullable<T[K]>;
};

function assertAllDataLoaded(
  context: NullableFields<PaymentDetailsContextType>,
): asserts context is PaymentDetailsContextType {
  if (!context.account) {
    throw new Error('Account data not loaded');
  }
  if (!context.currentSubscription) {
    throw new Error('Subscription data not loaded');
  }

  if (!context.currentWorkspace) {
    throw new Error('Workspace data not loaded');
  }
}

/*
Purpose of this provider is to provide all necessary data
for payment details before rendering feature
*/
export const PaymentDetailsProvider: FC<
  PropsWithChildren<{
    fallback?: ReactNode;
  }>
> = ({ children, fallback = null }) => {
  const billingUseCase = useInjection<IBillingUseCase>(BILLING_TYPES.BillingUseCase);
  const [planType] = usePlanFromUrl();
  const [billingCycle] = useBillingCycleFromUrl();

  const {
    data: targetProduct,
    isLoading: isTargetProductLoading,
    hasError: isTargetProductHasError,
  } = useObservableResult(
    () =>
      billingUseCase
        .getProducts()
        .pipe(
          map((products) =>
            products.find(
              (product) => product.family === planType && product.cycle === billingCycle,
            ),
          ),
        ),
    {
      deps: [planType, billingCycle],
    },
  );

  const { account, isLoading: isAccountLoading } = useAccount();

  const { data: currentWorkspace, isLoading: isWorkspaceLoading } = useCurrentWorkspace();

  const { data: paymentMethod, isLoading: isPaymentMethodLoading } =
    useGetPaymentMethod();

  const { data: billingDetails, isLoading: isBillingDetailsLoading } =
    useGetBillingDetails();

  const isLoading =
    isAccountLoading ||
    isWorkspaceLoading ||
    isPaymentMethodLoading ||
    isBillingDetailsLoading ||
    isTargetProductLoading;

  const contextValue = useMemo<NullableFields<PaymentDetailsContextType>>(
    () => ({
      account,
      currentSubscription: currentWorkspace?.subscription,
      currentWorkspace,
      paymentMethod,
      billingDetails,
      targetProduct,
    }),
    [account, currentWorkspace, paymentMethod, billingDetails, targetProduct],
  );

  if (isLoading) {
    return <Fragment>{fallback}</Fragment>;
  }

  if (isTargetProductHasError) {
    return <ErrorPage500 />;
  }

  assertAllDataLoaded(contextValue);

  return (
    <PaymentDetailsContext.Provider value={contextValue}>
      {children}
    </PaymentDetailsContext.Provider>
  );
};
