import { createContext, FC, PropsWithChildren, useState } from 'react';

import {
  EnrichmentUploadFileFormatError,
  EnrichmentUploadFileRecordsError,
  EnrichmentUploadFileSizeError,
  EnrichmentUploadRateLimitError,
} from '@/features/enrichment/domain/errors';
import { EnrichmentFileUploadResponse } from '@/features/enrichment/domain/types';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { EnrichmentUploadError } from '@/features/system/analytics/domain/types/eventProperties';
import { ConnectionError } from '@/features/system/network';

import { useModalController } from '@/hooks';

import { useEnrichmentFileUploadUseCase } from './hooks';

export type ModalViewType =
  | 'StartViewDefault'
  | 'StartViewConnectionError'
  | 'StartViewSizeError'
  | 'StartViewContentError'
  | 'StartViewFormatError'
  | 'StartViewDefaultError'
  | 'StartViewRateLimit'
  | 'ConfigFieldsMapperView'
  | 'ConfigSettingsView'
  | 'ConfigResultView';

export type FileMetadata = {
  name: string;
  rowsCount: number;
  progress: number;
};

interface IEnrichmentUploadingContext {
  currentView: ModalViewType;
  fileMetadata: Nullable<FileMetadata>;
  isUploadingModalOpen: boolean;
  fileUploadResponse: Nullable<EnrichmentFileUploadResponse>;
  onUploadingModalClose(): void;
  onUploadingModalOpen(): void;
  toView(viewType: ModalViewType): void;
  upload(file: File): Promise<void>;
}

export const EnrichmentUploadingContext = createContext<IEnrichmentUploadingContext>({
  currentView: 'StartViewDefault',
  isUploadingModalOpen: false,
  fileUploadResponse: null,
  fileMetadata: null,
  onUploadingModalClose() {
    return;
  },
  onUploadingModalOpen() {
    return;
  },
  toView(_viewType: ModalViewType) {
    return;
  },
  upload(_file: File) {
    return Promise.resolve();
  },
});

export const EnrichmentUploadingContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const enrichmentFileUploadUseCase = useEnrichmentFileUploadUseCase();
  const [fileMetadata, setFileMetadata] = useState<Nullable<FileMetadata>>(null);
  const { isUploadingModalOpen, onUploadingModalClose, onUploadingModalOpen } =
    useModalController('UploadingModal');
  const [currentView, setCurrentView] = useState<ModalViewType>('StartViewDefault');
  const [abortController, setAbortController] = useState(new AbortController());
  const [fileUploadResponse, setFileUploadResponse] =
    useState<Nullable<EnrichmentFileUploadResponse>>(null);
  const { trackEvent } = useAnalytics();

  const trackErrorUpload = (type: EnrichmentUploadError): void => {
    trackEvent(ANALYTICS_EVENTS.ENRICHMENT_UPLOAD_ERROR, { type });
  };

  const upload = async (file: File): Promise<void> => {
    try {
      setFileMetadata({ name: file.name, rowsCount: 0, progress: 0 });
      const response = await enrichmentFileUploadUseCase.uploadFile(
        { file },
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / Number(progressEvent?.total || 1)) * 100,
            );
            setFileMetadata((prev) => {
              if (prev) return { ...prev, progress };
              return prev;
            });
          },
          signal: abortController.signal,
        },
      );

      trackEvent(ANALYTICS_EVENTS.ENRICHMENT_UPLOAD_COMPLETED);

      setFileMetadata({ rowsCount: response.total, name: file.name, progress: 0 });
      setCurrentView('ConfigFieldsMapperView');
      setFileUploadResponse(response);
    } catch (error) {
      switch (true) {
        case error instanceof ConnectionError:
          trackErrorUpload(EnrichmentUploadError.InternetConnection);
          setCurrentView('StartViewConnectionError');
          break;
        case error instanceof EnrichmentUploadFileSizeError:
          trackErrorUpload(EnrichmentUploadError.FileSizeOverLimit);
          setCurrentView('StartViewSizeError');
          break;
        case error instanceof EnrichmentUploadFileRecordsError:
          trackErrorUpload(EnrichmentUploadError.RecordsOverLimit);
          setCurrentView('StartViewContentError');
          break;
        case error instanceof EnrichmentUploadFileFormatError:
          trackErrorUpload(EnrichmentUploadError.FileFormatInvalid);
          setCurrentView('StartViewFormatError');
          break;
        case error instanceof EnrichmentUploadRateLimitError:
          trackErrorUpload(EnrichmentUploadError.NotEnoughCredits);
          setCurrentView('StartViewRateLimit');
          break;
        default:
          setCurrentView('StartViewDefaultError');
          break;
      }
    } finally {
      setAbortController(new AbortController());
    }
  };

  return (
    <EnrichmentUploadingContext.Provider
      value={{
        currentView,
        fileMetadata,
        fileUploadResponse,
        isUploadingModalOpen,
        onUploadingModalClose: (): void => {
          if (currentView === 'StartViewDefault') {
            abortController.abort();
          }
          onUploadingModalClose();
        },
        onUploadingModalOpen,
        toView: setCurrentView,
        upload,
      }}
    >
      {children}
    </EnrichmentUploadingContext.Provider>
  );
};

export function withEnrichmentUploadingContextProvider<Props extends object>(
  Component: FC<Props>,
): FC<Props> {
  return (props: Props) => {
    return (
      <EnrichmentUploadingContextProvider>
        <Component {...props} />
      </EnrichmentUploadingContextProvider>
    );
  };
}
