import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';

import styles from './styles.module.scss';

export const InviteLimitReachedError: FC = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'teamManagement.inviteDialog',
  });

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.icon}>
        <InfoOutlinedIcon />
      </Box>
      <Typography className={styles.text}>
        <Trans
          t={t}
          i18nKey={'errorTip.text'}
          components={{
            b: <b />,
          }}
        />
      </Typography>
    </Box>
  );
};
