import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Skeleton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { CancelSubscriptionCard } from './components/CancelSubscriptionCard';
import { CancelSubscriptionDialog } from './components/CancelSubscriptionDialog';
import { ChangeCardAction } from './components/ChangeCardAction';
import { CreditCardDetails } from './components/CreditCardDetails';
import { NoPaymentMethod } from './components/NoPaymentMethod';
import { PaymentActions } from './components/PaymentAction';
import { PaymentStatus } from './components/PaymentStatus';
import { usePaymentDetailsViewModel } from './usePaymentDetailsViewModel';

import styles from './styles.module.scss';

export const PaymentDetails: FC = () => {
  const {
    cancelSubscriptionDialog,
    cancelSubscriptionWarning,
    handleCancelSubscriptionClick,
    handleCancelSubscriptionDialogClose,
    handleCancelSubscriptionWarningConfirm,
    handleCancelSubscriotionWarningClose,
    handleChangeCardClick,
    handleResubsribeSubscriptionClick,
    isCardChanging,
    isVisible,
    paymentDetails,
  } = usePaymentDetailsViewModel();

  const { t } = useTranslation('billingSettings', { keyPrefix: 'paymentDetails' });

  if (!isVisible) return null;

  if (paymentDetails.isLoading) {
    return (
      <Card className={styles.card}>
        <CardContent className={styles.content}>
          <Stack direction="column" gap="10px">
            <Skeleton animation="pulse" height={15} />
            <Skeleton animation="pulse" height={15} />
            <Skeleton animation="pulse" height={15} />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (!paymentDetails.data) return null;

  const { method, status, upcomingInvoice, isGift } = paymentDetails.data;

  return (
    <Card className={styles.card}>
      <CardContent className={styles.content}>
        <CancelSubscriptionDialog
          isOpened={cancelSubscriptionDialog.isOpen}
          onClose={handleCancelSubscriptionDialogClose}
        />
        {cancelSubscriptionWarning.isOpen ? (
          <CancelSubscriptionCard
            onSubmit={handleCancelSubscriptionWarningConfirm}
            onCancel={handleCancelSubscriotionWarningClose}
          />
        ) : (
          <Stack direction="row" className={styles.row}>
            <Stack direction="column" className={styles.cardDetails}>
              <Typography component="span" className={clsx(styles.text, styles.grey)}>
                {t('paymentMethod')}
              </Typography>
              {method ? (
                <CreditCardDetails
                  expDate={`${method.card.expMonth}/${method.card.expYear}`}
                  last4={method.card.last4}
                  brand={method.card.brand}
                />
              ) : (
                <NoPaymentMethod />
              )}
              <PaymentStatus
                status={status}
                nextPayment={upcomingInvoice}
                isGift={isGift}
              />
            </Stack>
            <Stack direction="column" className={styles.actions}>
              <ChangeCardAction
                isCardChanging={isCardChanging}
                onClick={handleChangeCardClick}
                disabled={!paymentDetails.data.canChangeCard}
              />
              <PaymentActions
                status={status}
                onCancel={handleCancelSubscriptionClick}
                onResubsribe={handleResubsribeSubscriptionClick}
              />
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
