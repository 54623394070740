import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { useInjection } from '@/ioc/ioc.react';
import { TAG_TYPES } from '@/ioc/types';

import { ITagEntity, ITagUseCase } from '@/features/common/tag';
import { TagNameAlreadyExistError } from '@/features/common/tag/domain/errors';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';

import { useFormWithSchema } from '@/utils/validation';

import { DEFAULT_TAG_COLOR } from './constants';

type ViewModel = ({
  onClose,
}: {
  onClose(): void;
  tag?: Pick<ITagEntity, 'name' | 'color' | 'uuid'>;
}) => {
  color: string;
  isEditMessageModalVisible: boolean;
  form: UseFormReturn<TagSettingsFormType>;
  onColorChange: (color: string) => void;
  updateTag(): Promise<void>;
  onSave(): void;
  closeEditMessageModal(): void;
  onClose: () => void;
};

const TagSettingsFormSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(30, 'createTag.errors.maxNameLength')
    .required('createTag.errors.nameRequired'),
  color: yup.string().required('colorRequired'),
});

type TagSettingsFormType = yup.InferType<typeof TagSettingsFormSchema>;

export const useTagSettingsDialogViewModel: ViewModel = ({ onClose, tag }) => {
  const { t } = useTranslation('tag');
  const [isEditMessageModalVisible, setIsEditMessageModalVisible] = useState(false);
  const tagUseCase = useInjection<ITagUseCase>(TAG_TYPES.TagUseCase);

  const form = useFormWithSchema(TagSettingsFormSchema, {
    mode: 'onChange',
    defaultValues: {
      name: tag?.name ?? '',
      color: tag?.color ?? DEFAULT_TAG_COLOR,
    },
  });

  const name = form.watch('name');
  const color = form.watch('color');
  const isUpdate = !!tag;
  const { enqueueSnackbar } = useSnackbar();
  const analytics = useAnalytics();

  useEffect(() => {
    form.reset({
      name: tag?.name ?? '',
      color: tag?.color ?? DEFAULT_TAG_COLOR,
    });
  }, [tag?.name, tag?.color, tag?.uuid]);

  const onColorChange = (color: string): void => {
    form.setValue('color', color, { shouldDirty: true });
  };

  const handleClose = (): void => {
    form.resetField('name');
    onClose();
  };

  const createTag = async (): Promise<void> => {
    try {
      await tagUseCase.addTag({
        color: color,
        name: name,
      });
      analytics.trackEvent(ANALYTICS_EVENTS.TAG_ADD_TAG, {
        platform: Platform.Web,
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('createTag.toastSuccessTitle'),
        description: t('createTag.toastSuccessSubtitle'),
      });
      handleClose();
    } catch (error) {
      if (error instanceof TagNameAlreadyExistError) {
        enqueueSnackbar({
          variant: 'error',
          message: t('createTag.toastNameExistsTitle'),
          description: t('createTag.toastNameExistsSubtitle'),
        });
        form.setError('name', {
          message: error.message,
        });
      }
    }
  };

  const updateTag = async (): Promise<void> => {
    if (!tag) return;

    try {
      await tagUseCase.updateTag({
        uuid: tag.uuid,
        name: name,
        color: color,
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('editTag.toastSuccessTitle'),
        description: t('editTag.toastSuccessSubtitle'),
      });
      setIsEditMessageModalVisible(false);
      onClose();
    } catch (error) {
      if (error instanceof TagNameAlreadyExistError) {
        enqueueSnackbar({
          variant: 'error',
          message: t('editTag.toastErrorExistsTitle'),
          description: t('editTag.toastErrorExistsSubtitle'),
        });
        setIsEditMessageModalVisible(false);
        form.setError('name', {
          message: error.message,
        });
      }
    }
  };

  const onSave = (): void => {
    void form.handleSubmit(() => {
      if (isUpdate) {
        setIsEditMessageModalVisible(true);
      } else {
        createTag();
      }
    })();
  };

  return {
    color: form.watch('color'),
    onColorChange,
    isEditMessageModalVisible,
    closeEditMessageModal: (): void => {
      setIsEditMessageModalVisible(false);
    },
    updateTag,
    form,
    onSave,
    onClose: handleClose,
  };
};
