import { FC } from 'react';
import { Box, OutlinedInput, Typography } from '@mui/material';

import { CardField, PaymentFormField } from '@/features/common/billing';

import { CountrySelect } from '@/components';
import { LockIcon } from '@/components/Icons';

import { PaymentFormFieldset, SeatsCreditsSelector } from './components';
import { usePaymentFormViewModel } from './usePaymentFormViewModel';

import styles from './styles.module.scss';

type PaymentFormProps = {
  isProcessing: boolean;
  onSubmit: () => Promise<void>;
};

export const PaymentForm: FC<PaymentFormProps> = ({ isProcessing, onSubmit }) => {
  const { t, bindPaymentControl, bindPaymentFormField } = usePaymentFormViewModel({
    isProcessing,
  });

  return (
    <form className={styles.form} id="payment-details" noValidate onSubmit={onSubmit}>
      <PaymentFormFieldset title={t('creditsUsersSelectorTitle')}>
        <PaymentFormField
          {...bindPaymentFormField('seats', true)}
          errorPosition="bottom"
          labelText={undefined}
        >
          <SeatsCreditsSelector {...bindPaymentControl('seats')} />
        </PaymentFormField>
      </PaymentFormFieldset>
      <PaymentFormFieldset title={t('cardDetailsTitle')}>
        <Box className={styles.cardDetails}>
          <PaymentFormField {...bindPaymentFormField('billingDetails.name', true)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.name')} />
          </PaymentFormField>
          <Box className={styles.cardRow}>
            <PaymentFormField
              {...bindPaymentFormField('paymentMethod.card', true)}
              errorPosition="bottom"
            >
              <CardField {...bindPaymentControl('paymentMethod.card')} />
            </PaymentFormField>
            <Box className={styles.secure}>
              <LockIcon />
              <Typography variant="inherit" className={styles.secureText}>
                {t('secure')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </PaymentFormFieldset>
      <PaymentFormFieldset
        title={t('billingDetailsTitle')}
        subtitle={t('billingDetailsSubtitle')}
      >
        <Box className={styles.billingDetails}>
          <PaymentFormField {...bindPaymentFormField('billingDetails.address', true)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.address')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.country', true)}>
            <CountrySelect {...bindPaymentControl('billingDetails.country')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.city', true)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.city')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.state', false)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.state')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.postalCode', true)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.postalCode')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.company', false)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.company')} />
          </PaymentFormField>
          <PaymentFormField {...bindPaymentFormField('billingDetails.vatId', false)}>
            <OutlinedInput {...bindPaymentControl('billingDetails.vatId')} />
          </PaymentFormField>
        </Box>
      </PaymentFormFieldset>
    </form>
  );
};
