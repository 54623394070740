import { PickerValue } from '../../types';

const FORMAT = 'MM/DD/YYYY';

export const usePopoverBodyViewModel = () => {
  const formatToValue = ([startDate, endDate]: PickerValue): string => {
    if (!startDate || !endDate) return '';
    return `${startDate.format(FORMAT)}-${endDate.format(FORMAT)}`;
  };

  return {
    formatToValue,
  };
};
