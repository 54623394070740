export enum Permission {
  CanAccessAllContact = 'can_access_all_contacts', // workspace.permissions
  CanAddTags = 'can_add_tags', // workspace.permissions
  CanEditOtherTags = 'can_edit_others_tags', // workspace.permissions
  CanEditOwnTags = 'can_edit_own_tags', // workspace.permissions
  CanManageMembers = 'can_manage_members', // workspace.permissions
  CanAccessBilling = 'can_access_billing', // workspace.permissions
  CanUseExtension = 'can_use_extension', // workspace.permissions
  CanEditIntegrations = 'can_edit_integrations', // workspace.permissions
  CanRunBulkEnrichment = 'can_run_bulk_enrichment', // workspace.permissions
  CanManageContactLists = 'can_manage_contact_lists', // virtual
  CanManageContactOwner = 'can_manage_contact_owner', // virtual
  CanViewProspectTaskProgress = 'can_view_prospect_task_progress', // virtual
  CanAccessRefferal = 'can_access_refferal', // virtual
  CanAccessCsvEnrichemnt = 'csv_enrichment', // workspace.subscription.planSelectedFeatures
  CanAccessDataExport = 'data_export', // workspace.subscription.planSelectedFeatures
  CanAccessApi = 'api_access', // workspace.subscription.planSelectedFeatures
  CanAccessCrmIntegration = 'crm_integration', // workspace.subscription.planSelectedFeatures
}
