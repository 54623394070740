import { type FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Billing } from '@/features/billing';
import { Logout, useAuthState } from '@/features/common/auth';
import { HasPermission, Permission } from '@/features/common/permissions';
import { Contacts } from '@/features/contacts';
import Dashboard from '@/features/dashboard/Dashboard';
import { Enrichment, EnrichmentReport } from '@/features/enrichment';
import { Integration } from '@/features/integration';
import { Integrations } from '@/features/integrations';
import { ContactLists } from '@/features/lists';
import { PaymentDetails } from '@/features/paymentDetails';
import { Plans } from '@/features/plans';
import { Referral, ReferralLanding } from '@/features/referral';
import { ResetPassword } from '@/features/resetPassword';
import { LeavingSoon } from '@/features/retention';
import { Settings, TeamManagement } from '@/features/settings';
import { AccountSettings } from '@/features/settings/features/account';
import TagManagement from '@/features/settings/features/tagManagement';
import {
  ForgotPasswordPage,
  LoginPage,
  LoginWithCustomTokenPage,
} from '@/features/signin';
import {
  CreateAccountPage,
  EmailConfirmPage,
  InstallExtensionPage,
  SignUpPage,
  SuccessSignUp,
} from '@/features/signup';
import { FeatureEnabled } from '@/features/system/featureFlag';

import { ErrorPage403, ErrorPage404, ErrorPage500 } from './error';
import {
  AuthenticatedGuardRoute,
  AuthenticatedRoutesWrapper,
  EmailLinksLanderRoute,
  MobileAccessGuardRoute,
  SetupAccountGuardRoute,
  UnauthenticatedGuardRoute,
  UnauthenticatedRoutesWrapper,
  VerifyEmailGuardRoute,
} from './guards';
import { withLocationRestore } from './hoc';
import { ROUTES } from './routes';

export const AppRouter: FC = withLocationRestore(() => {
  const authState = useAuthState();

  return (
    <Routes>
      <Route element={<AuthenticatedGuardRoute authState={authState} />}>
        <Route element={<MobileAccessGuardRoute />}>
          <Route
            path={ROUTES.SIGN_UP_INSTALL_EXTENSION}
            element={<InstallExtensionPage />}
          />
          <Route element={<AuthenticatedRoutesWrapper />}>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.CONTACTS_ENTRY} element={<ContactLists />} />
            <Route path={ROUTES.CONTACTS_INNER} element={<Contacts />} />
            <Route path={ROUTES.SETTINGS.INDEX} element={<Settings />}>
              <Route path={ROUTES.SETTINGS.ACCOUNT} element={<AccountSettings />} />
              <Route
                path={ROUTES.SETTINGS.TEAM_MANAGEMENT}
                element={<TeamManagement />}
              />
              <Route path={ROUTES.SETTINGS.TAG_MANAGEMENT} element={<TagManagement />} />
              <Route
                path={ROUTES.SETTINGS.SUBSCRIPTION}
                element={
                  <HasPermission
                    permission={Permission.CanAccessBilling}
                    fallback={<ErrorPage403 />}
                  >
                    <Billing />
                  </HasPermission>
                }
              />
              <Route
                path={ROUTES.SETTINGS.REFERRAL}
                element={
                  <HasPermission
                    permission={Permission.CanAccessRefferal}
                    fallback={<ErrorPage403 />}
                  >
                    <Referral />
                  </HasPermission>
                }
              />
            </Route>
            <Route
              path={ROUTES.BILLING.PLANS}
              element={
                <HasPermission
                  permission={Permission.CanAccessBilling}
                  fallback={<ErrorPage403 />}
                >
                  <Plans />
                </HasPermission>
              }
            />
            <Route
              path={ROUTES.BILLING.PAYMENT_DETAILS}
              element={
                <HasPermission
                  permission={Permission.CanAccessBilling}
                  fallback={<ErrorPage403 />}
                >
                  <PaymentDetails />
                </HasPermission>
              }
            />

            <Route
              path={ROUTES.ENRICHMENT_ENTRY}
              element={
                <FeatureEnabled
                  featureName="FEATURE_ENRICHMENT"
                  fallback={<ErrorPage404 />}
                >
                  <Enrichment />
                </FeatureEnabled>
              }
            />
            <Route
              path={ROUTES.ENRICHMENT_REPORT}
              element={
                <FeatureEnabled
                  featureName="FEATURE_ENRICHMENT"
                  fallback={<ErrorPage404 />}
                >
                  <EnrichmentReport />
                </FeatureEnabled>
              }
            />

            <Route path={ROUTES.WILDCARD} element={<ErrorPage404 />} />
            <Route path={ROUTES.ERROR_500} element={<ErrorPage500 />} />
            <Route path={ROUTES.INTEGRATIONS} element={<Integrations />} />
            <Route
              path={ROUTES.INTEGRATION}
              element={
                <HasPermission
                  permission={Permission.CanEditIntegrations}
                  fallback={<ErrorPage403 />}
                >
                  <Integration />
                </HasPermission>
              }
            />
          </Route>
        </Route>
      </Route>
      <Route element={<UnauthenticatedGuardRoute authState={authState} />}>
        <Route element={<UnauthenticatedRoutesWrapper />}>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.LOGIN_WITH_TOKEN} element={<LoginWithCustomTokenPage />} />
          <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        </Route>
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      </Route>
      <Route element={<SetupAccountGuardRoute authState={authState} />}>
        <Route path={ROUTES.SIGN_UP_NEW} element={<CreateAccountPage />} />
      </Route>
      <Route element={<VerifyEmailGuardRoute authState={authState} />}>
        <Route path={ROUTES.SIGN_UP_VERIFICATION} element={<EmailConfirmPage />} />
      </Route>
      <Route
        path={ROUTES.SIGN_UP_REDIRECT_SUCCESS_VERIFICATION}
        element={<SuccessSignUp />}
      />
      <Route path={ROUTES.EMAIL_LINKS_LANDER} element={<EmailLinksLanderRoute />} />
      <Route path={ROUTES.LEAVING_SOON} element={<LeavingSoon />} />
      <Route
        path={ROUTES.REFERRALS}
        element={<ReferralLanding authState={authState} />}
      />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
    </Routes>
  );
});
