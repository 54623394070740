import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import type { IHttpClient } from '@/features/system/network';

import type { IWorkspaceApiService } from '../abstractions/WorkspaceApiService';
import type { ICreditDC, IStripeSubscriptionDC, IWorkspaceDC } from '../dataContracts';

const workspaceEndpoints = {
  workspaces: 'workspaces',
  switch: 'workspaces/switch',
  updateSusbcription: (id: string): string => `workspaces/${id}/subscription`,
  createSubscription: 'billing-service/stripe/init-subscription',
  getCredits: 'billing-service/api/v2/wallets/my/credits',
};

@injectable()
export default class WorkspaceApiService implements IWorkspaceApiService {
  @inject(NETWORK_TYPES.HttpClient) private httpClient: IHttpClient;

  fetchWorkspace(): Observable<IWorkspaceDC[]> {
    return this.httpClient
      .get<IWorkspaceDC[]>(workspaceEndpoints.workspaces)
      .pipe(map(({ data }) => data));
  }

  updateWorkspace(account: Partial<IWorkspaceDC>): Observable<IWorkspaceDC> {
    return this.httpClient
      .patch<IWorkspaceDC>(workspaceEndpoints.workspaces, account)
      .pipe(map(({ data }) => data));
  }

  createSubscription(params: {
    label: string;
    promo_code?: string;
    is_canceled?: boolean;
    quantity?: number;
  }): Promise<IStripeSubscriptionDC> {
    return firstValueFrom(
      this.httpClient
        .post<IStripeSubscriptionDC>(workspaceEndpoints.createSubscription, params)
        .pipe(map(({ data }) => data)),
    );
  }

  async updateSubscription(params: {
    workspaceId: string;
    quantity?: number;
    plan?: string;
    is_canceled?: boolean;
    billing_details_filled?: boolean;
  }): Promise<void> {
    const { workspaceId: id, ...payload } = params;
    await firstValueFrom(
      this.httpClient.patch(workspaceEndpoints.updateSusbcription(id), payload),
    );
  }

  switchWorkspace(req: {
    uuid: string;
    action: 'accept' | 'reject';
  }): Observable<boolean> {
    return this.httpClient
      .post<boolean>(workspaceEndpoints.switch, req)
      .pipe(map(({ data }) => data));
  }

  getAllCredits(): Observable<{ items: ICreditDC[]; total: number }> {
    return this.httpClient
      .get<{
        items: ICreditDC[];
        total: number;
      }>(workspaceEndpoints.getCredits, {
        cachePolicy: 'cache-first',
        revalidateAfter: 10_000,
      })
      .pipe(map(({ data }) => data));
  }
}
