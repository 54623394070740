import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction } from '@/features/callToAction';
import { useAccount, UserRole } from '@/features/common/account';
import { usePlanName } from '@/features/common/workspace';

import { PlanCardContentManager } from './PlanCardContentManager';
import { PlanCardLayout } from './PlanCardLayout';

export const PlanCardProfessionalPlan: FC = () => {
  const { t } = useTranslation('common');

  const { account } = useAccount();

  const { data: planName } = usePlanName();

  const isManagerRole = account?.role === UserRole.Manager;

  return (
    <PlanCardLayout
      title={t('planNameLong', { planName })}
      hideCreditsData={isManagerRole}
    >
      {isManagerRole ? (
        <PlanCardContentManager />
      ) : (
        <CallToAction
          variant="outlined"
          show={['getCredits', 'getExtension', 'revealContact']}
          size="small"
        />
      )}
    </PlanCardLayout>
  );
};
