import { inject, injectable } from 'inversify';
import { firstValueFrom, map, Observable } from 'rxjs';

import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { IContactListRepository } from '../domain/abstractions/repositories/IContactListRepository';
import { IContactListEntity } from '../domain/entities/ContactListEntity';
import { ContactNameAlreadyExistError } from '../domain/errors';
import { ContactListSortSchema } from '../domain/types';

import { IContactListDC } from './dataContracts/ContactListDC';
import { IContactListDao } from './db/dao/ContactListDao';
import { mapContactListDcToEntity } from './mappers';

@injectable()
export default class ContactListRepository implements IContactListRepository {
  @inject(CONTACT_LIST_TYPES.ContactListDao)
  private contactListDao: IContactListDao;

  getDefaultContactList(): Observable<IContactListEntity> {
    return this.contactListDao
      .getDefaultContactList()
      .pipe(map(mapContactListDcToEntity));
  }

  getContactListByQuery(payload: {
    nameReg?: string;
    created_by?: string;
    sortSchema?: ContactListSortSchema;
  }): Observable<IContactListEntity[]> {
    return this.contactListDao
      .getContactListByQuery(payload)
      .pipe(map((list) => list.map(mapContactListDcToEntity)));
  }

  async createContactList(payload: {
    name: string;
    created_by: string;
  }): Promise<IContactListEntity> {
    await this.checkContactListExistence({ name: payload.name });
    const result = await this.contactListDao.upsert({
      name: payload.name,
      created_by: payload.created_by,
      contacts_amount: 0,
    });
    return mapContactListDcToEntity(result);
  }

  async updateContactList(payload: {
    uuid: string;
    name: string;
  }): Promise<IContactListEntity> {
    await this.checkContactListExistence(payload);
    const result = await this.contactListDao.updateOne(payload.uuid, {
      name: payload.name,
    });
    return mapContactListDcToEntity(result);
  }

  async deleteContactList(uuid: string): Promise<boolean> {
    const result = await this.contactListDao.removeOne(uuid);
    return !!result;
  }

  getContactListById(id: string): Observable<IContactListEntity | null> {
    return this.contactListDao
      .findById(id)
      .pipe(map((response) => response && mapContactListDcToEntity(response)));
  }

  private async checkContactListExistence(payload: {
    name: string;
    uuid?: string;
  }): Promise<IContactListDC | null> {
    const contactList = await firstValueFrom(
      this.contactListDao.findOne({ selector: { name: payload.name } }),
    );
    const isDuplicate =
      contactList && (payload?.uuid ? payload.uuid !== contactList.uuid : true);

    if (isDuplicate) throw new ContactNameAlreadyExistError();
    return contactList;
  }
}
