import { ChangeEvent, useContext } from 'react';

import { EnrichmentUploadingContext } from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';
import { useUploadEnrichmentFile } from '@/features/enrichment/ui/Enrichment/hooks';

type UseConfigViewResultViewModelResult = {
  inputRef: React.RefObject<HTMLInputElement>;
  handleDoneClick(): void;
  handleUploadClick(): void;
  handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
};

export const useConfigViewResultViewModel = (): UseConfigViewResultViewModelResult => {
  const { onUploadingModalClose } = useContext(EnrichmentUploadingContext);
  const { onChange, onClick, inputRef } = useUploadEnrichmentFile();

  const handleDoneClick = (): void => {
    onUploadingModalClose();
  };

  const handleUploadClick = (): void => {
    onClick();
  };

  return {
    handleDoneClick,
    handleUploadClick,
    inputRef,
    handleInputChange: onChange,
  };
};
