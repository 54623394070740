import { FC, PropsWithChildren, useContext } from 'react';

import {
  ANALYTICS_EVENTS,
  DataFiltersPageProperty,
  useAnalytics,
} from '@/features/system/analytics';

import { DateSelect } from '@/components';

import { ContactsContext } from '../../context';

export const FilterByDateAction: FC<PropsWithChildren> = () => {
  const { filterByDate, setFilterByDate } = useContext(ContactsContext);
  const analytics = useAnalytics();

  const handleDateChange = (value: string): void => {
    setFilterByDate(value);
    analytics.trackEvent(ANALYTICS_EVENTS.FILTER_BY_TIME, {
      page: DataFiltersPageProperty.Contacts,
      time: value,
    });
  };

  return <DateSelect defaultValue={filterByDate} onChange={handleDateChange} />;
};
