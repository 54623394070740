import { inject, injectable } from 'inversify';
import { firstValueFrom } from 'rxjs';

import {
  ACCOUNT_TYPES,
  COOKIES_TYPES,
  HUBSPOT_TYPES,
  LEADS_TRACKING_TYPES,
} from '@/ioc/types';

import { IAccountRepository } from '@/features/common/account';

import { isDesktop } from '@/utils/isDesktop';
import { isMobile } from '@/utils/isMobile';

import { ICookiesManager } from '../../cookies';
import { ILeadsTrackingRepository } from '../../leadsTracking';

import { IHubspotApiService } from './network';

export interface IHubspotRepository {
  setupHubspotContact(): Promise<void>;
}

@injectable()
export class HubspotRepository implements IHubspotRepository {
  @inject(HUBSPOT_TYPES.HubspotApiService)
  private hubspotApiService: IHubspotApiService;

  @inject(COOKIES_TYPES.CookiesManager)
  private cookiesManager: ICookiesManager;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
  private leadsTrackingRepository: ILeadsTrackingRepository;

  async setupHubspotContact(): Promise<void> {
    const account = await firstValueFrom(this.accountRepository.getAccount());

    if (account) {
      const utmData = this.leadsTrackingRepository.get();
      const [firstName, lastName] = account.fullName?.split(' ') ?? [];

      let deviceType;

      if (isMobile()) {
        deviceType = 'mobile';
      } else if (isDesktop()) {
        deviceType = 'desktop';
      } else {
        deviceType = 'other';
      }

      const response = await this.hubspotApiService.sendClientSetupForm({
        submittedAt: Date.now().toString(),
        context: {
          pageUri: window.location.href,
          hutk: this.cookiesManager.get('hubspotutk'),
          pageName: window.document.title,
        },
        fields: [
          {
            objectTypeId: 'contact',
            name: 'email',
            value: account.email,
          },
          {
            objectTypeId: 'contact',
            name: 'powerlead_user_id',
            value: account.uuid,
          },
          {
            objectTypeId: 'contact',
            name: 'firstname',
            value: firstName ?? account.fullName ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'lastname',
            value: lastName ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'phone',
            value: account.settings.phone ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'country',
            value: account.settings.country ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'utm_content',
            value: utmData?.content ?? account.utm?.content ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'utm_medium',
            value: utmData?.medium ?? account.utm?.medium ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'utm_campaign',
            value: utmData?.campaign ?? account.utm?.campaign ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'utm_source',
            value: utmData?.source ?? account.utm?.source ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'utm_term',
            value: utmData?.term ?? account.utm?.term ?? '',
          },
          {
            objectTypeId: 'contact',
            name: 'device_sign_up',
            value: deviceType,
          },
        ],
      });

      if (response.ok) {
        await this.accountRepository.updateSettings({
          isImportedToHubspot: true,
        });
      }
    }
  }
}
