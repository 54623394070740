import { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { PlanType } from '../types';

function _getPlanTypeFromSubscription(plan: string): PlanType {
  switch (true) {
    case /^free/.test(plan):
      return PlanType.Free;
    case /^pro/.test(plan):
      return PlanType.Pro;
    case /^unlimited/.test(plan):
      return PlanType.Unlimited;
    case /^custom/.test(plan):
      return PlanType.Expand;
    default:
      return PlanType.Free;
  }
}

export function getPlanTypeFromSubscription({
  plan,
}: Pick<IWorkspaceSubscriptionEntity, 'plan'>): PlanType {
  if (!plan) {
    return PlanType.Free;
  }

  return _getPlanTypeFromSubscription(plan);
}
