import { inject, injectable } from 'inversify';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  race,
  switchMap,
  throwError,
  timer,
} from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { getPlanTypeFromSubscription, PlanType } from '../../billing';
import type { IWorkspaceRepository } from '../data/abstractions/WorksapceRepository';

import type { SubscriptionPlan } from './types/SubscriptionPlan';
import type { ISubscriptionUseCase } from './abstractions';
import type { IWorkspaceSubscriptionEntity } from './entities';

@injectable()
export class SubscriptionUseCase implements ISubscriptionUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workpsaceRepository: IWorkspaceRepository;

  getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workpsaceRepository.getCurrentWorkspaceSubscription();
  }

  create(params: {
    plan: SubscriptionPlan;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<{ secret?: string }> {
    return this.workpsaceRepository.createSubscription(params);
  }

  async update(subscription: {
    plan?: SubscriptionPlan;
    isCanceled?: boolean;
    billingDetailsFilled?: boolean;
    quantity?: number;
    promoCode?: string;
  }): Promise<void> {
    await this.workpsaceRepository.updateSubscription(subscription);
  }

  private async waitWithTimeout<T>(
    observable: Observable<T>,
    timeout: number,
  ): Promise<T> {
    return firstValueFrom(
      race([
        observable,
        timer(timeout).pipe(switchMap(() => throwError(() => new Error('Timeout')))),
      ]),
    );
  }

  async cancel(): Promise<void> {
    await this.update({ isCanceled: true });
    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => subsription.isCanceled)),
      10_000,
    );
  }

  async updatePlan(plan: SubscriptionPlan): Promise<void> {
    await this.update({ plan, billingDetailsFilled: true });

    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => subsription.plan === plan)),
      10_000,
    );
  }

  async renew(): Promise<void> {
    await this.update({ isCanceled: false });

    await this.waitWithTimeout(
      this.getSubscription().pipe(filter((subsription) => !subsription.isCanceled)),
      10_000,
    );
  }

  getSubscriptionPlan(): Observable<SubscriptionPlan> {
    return this.getSubscription().pipe(map((subscription) => subscription.plan));
  }

  getSubscriptionPlanType(): Observable<PlanType> {
    return this.getSubscription().pipe(
      map((subscription) => getPlanTypeFromSubscription(subscription)),
      distinctUntilChanged(),
    );
  }

  getIsFreePlan(): Observable<boolean> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.planIsFree),
      distinctUntilChanged(),
    );
  }

  getIsUnlimitedPlan(): Observable<boolean> {
    return this.getSubscription().pipe(
      map((subscription) => subscription.planIsUnlimited),
      distinctUntilChanged(),
    );
  }

  getPlanName(params: { variant?: 'short' | 'long' }): Observable<string> {
    return this.getSubscription().pipe(
      map((subscription) => {
        const planName = subscription.planName ?? 'Free';

        if (params?.variant === 'long') {
          const billingCycle = `${subscription.billingCycle.charAt(0)}${subscription.billingCycle.slice(1)}`;
          return `${planName} ${billingCycle}`;
        }

        return planName;
      }),
      distinctUntilChanged(),
    );
  }
}
