import { inject, injectable } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import type { IAccountRepository } from '@/features/common/account';
import type {
  IWorkspaceRepository,
  IWorkspaceUseCase,
} from '@/features/common/workspace';
import {
  InvitationStatus,
  type ITeamMemberEntity,
} from '@/features/settings/features/teamManagement';

import { WorkspaceSeatsLimitError } from './errors/WorkspaceSeatsLimitError';
import type { InvitationEntity, IWorkspaceEntity } from './entities';

@injectable()
export default class WorkspaceUseCase implements IWorkspaceUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(ACCOUNT_TYPES.AccountUseCase)
  private accountRepository: IAccountRepository;

  async acceptSwitchWorkspace(req: { uuid: string }): Promise<boolean> {
    try {
      const response = await this.workspaceRepository.switchWorkspace({
        uuid: req.uuid,
        action: 'accept',
      });

      return response;
    } catch (error) {
      if (
        error.response?.status >= 400 &&
        error.response?.data?.error_code === 'subscription_error'
      ) {
        throw new WorkspaceSeatsLimitError();
      }
      throw error;
    }
  }

  rejectSwitchWorkspace(req: { uuid: string }): Promise<boolean> {
    return this.workspaceRepository.switchWorkspace({
      uuid: req.uuid,
      action: 'reject',
    });
  }

  getInvitationToAnotherWorkspace(): Observable<Nullable<InvitationEntity>> {
    const account$ = this.accountRepository.getAccount();
    const workspaces$ = this.workspaceRepository.getWorkspaces();

    return combineLatest([account$, workspaces$]).pipe(
      map(([account, workspaces]) => {
        if (workspaces.length <= 1 || !account) {
          return null;
        }

        const invitations: InvitationEntity[] = [];

        for (const workspace of workspaces) {
          for (const member of workspace.members) {
            if (
              member.invitationStatus === InvitationStatus.Pending &&
              member.uuid === account.uuid
            ) {
              const invitedBy = workspace.members.find(
                (inviter) => inviter.uuid === member.invitedBy,
              );

              invitations.push({
                invitedTo: workspace.uuid,
                invitedAt: member.invitedAt || workspace.createdAt,
                invitedBy: {
                  email: invitedBy?.email || workspace.ownerEmail,
                  name: invitedBy?.fullName || workspace.ownerFullName,
                },
              });
            }
          }
        }

        if (invitations.length === 0) {
          return null;
        }

        return invitations.sort((a, b) => {
          return b.invitedAt - a.invitedAt;
        })[0];
      }),
    );
  }

  getCurrentWorkspace(): Observable<IWorkspaceEntity | null> {
    return this.workspaceRepository.getCurrentWorkspace();
  }

  getCurrentWorkspaceOwner(): Observable<ITeamMemberEntity | null> {
    return this.workspaceRepository.getCurrentWorkspaceOwner();
  }
}
