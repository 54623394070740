import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowUpIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 10 6">
    <path
      d="m17 22.6-5-5.088.9-.912 4.1 4.2 2.8-2.852 1.3-1.325.9.912z"
      transform="rotate(180 11 11.3)"
      fill="currentColor"
    />
  </SvgIcon>
);
