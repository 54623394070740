import { useEffect, useState } from 'react';

import { useIsOnline } from '../../hooks';

type UseOnlineTrackerViewModel = () => {
  isOpen: boolean;
  handleClose: () => void;
};

export const useOnlineTrackerViewModel: UseOnlineTrackerViewModel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isOnline = useIsOnline();

  useEffect(() => {
    setIsOpen(!isOnline);
  }, [isOnline]);

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleClose,
  };
};
