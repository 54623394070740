import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Skeleton, Typography } from '@mui/material';
import clsx from 'clsx';

import { CallToAction } from '@/features/callToAction';

import { Statistic } from '../Statistic';

import { useCreditsOverviewVideModel } from './useCreditsOverviewViewModel';

import styles from './styles.module.scss';

type CreditsOverviewProps = {
  isCollapsed: boolean;
};

export const CreditsOverview: FC<CreditsOverviewProps> = ({ isCollapsed }) => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoading, data, error } = useCreditsOverviewVideModel();

  return (
    <>
      <Box className={styles.table}>
        <Box className={styles.tableRow}>
          <Box className={styles.tableCol}>
            {isLoading || error ? (
              <Statistic.Preview />
            ) : (
              <Statistic
                title={t('totalCreditsLeft.title')}
                value={
                  <Typography fontWeight={400} component="span" fontSize={22}>
                    {data?.totalCreditsLeft}
                  </Typography>
                }
              />
            )}
          </Box>
          <Box className={styles.tableCol}></Box>
          <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}>
            {isLoading || error ? (
              <Skeleton width={160} height={40} />
            ) : (
              <CallToAction
                variant="outlined"
                show={['getCredits', 'inviteTeamMember']}
                size="small"
              />
            )}
          </Box>
        </Box>
      </Box>
      <Collapse in={!isLoading && !isCollapsed && !error} timeout="auto" unmountOnExit>
        <Box className={styles.table} sx={{ marginTop: '20px' }}>
          <Box className={styles.tableHead}>
            <Box className={clsx(styles.tableCol, styles.tableColHead)}>
              <Box component="span" className={styles.tableColHeadText}>
                {t('bySources.columns.creditType')}
              </Box>
            </Box>
            <Box className={styles.tableCol}>
              <Box component="span" className={styles.tableColHeadText}>
                {t('bySources.columns.creditsLeft')}
              </Box>
            </Box>
            <Box className={styles.tableCol} />
          </Box>
          {data?.bySources.plan && (
            <Box className={styles.tableRow}>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.plan.title}
                  description={data.bySources.plan.renewAt ?? ''}
                />
              </Box>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.plan.creditsLeft}
                  variant={data.bySources.plan.isOutOfCredits ? 'danger' : 'default'}
                />
              </Box>
              <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
            </Box>
          )}
          {data?.bySources.referral && (
            <Box className={styles.tableRow}>
              <Box className={styles.tableCol}>
                <Statistic value={data.bySources.referral.title} />
              </Box>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.referral.creditsLeft}
                  variant={data.bySources.referral.isOutOfCredits ? 'danger' : 'default'}
                />
              </Box>
              <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
            </Box>
          )}
          {data?.bySources.gift && (
            <Box className={styles.tableRow}>
              <Box className={styles.tableCol}>
                <Statistic value={data.bySources.gift.title} />
              </Box>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.gift.creditsLeft}
                  variant={data.bySources.gift.isOutOfCredits ? 'danger' : 'default'}
                />
              </Box>
              <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
            </Box>
          )}
          {data?.bySources.unknown && (
            <Box className={styles.tableRow}>
              <Box className={styles.tableCol}>
                <Statistic value={data.bySources.unknown.title} />
              </Box>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.unknown.creditsLeft}
                  variant={data.bySources.unknown.isOutOfCredits ? 'danger' : 'default'}
                />
              </Box>
              <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
            </Box>
          )}
          {data?.bySources.rolledUp && (
            <Box className={styles.tableRow}>
              <Box className={styles.tableCol}>
                <Statistic value={data.bySources.rolledUp.title} />
              </Box>
              <Box className={styles.tableCol}>
                <Statistic
                  value={data.bySources.rolledUp.creditsLeft}
                  variant={data.bySources.rolledUp.isOutOfCredits ? 'danger' : 'default'}
                />
              </Box>
              <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
            </Box>
          )}
        </Box>
      </Collapse>
    </>
  );
};
