import { useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { IPlanDetailsEntity } from '@/features/billing/domain/entities/PlanDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { useObservableResult } from '@/utils/rx';

type UsePlanOverviewViewModel = () => {
  isLoading: boolean;
  currentPlan: string;
  planCredits: string;
  users: string;
  creditsRenewOn: string;
};

export const usePlanOverviewViewModel: UsePlanOverviewViewModel = () => {
  const billingSettingsUseCase = useBillingSettingsUseCase();
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const formatPlanCredits = (data: IPlanDetailsEntity): string => {
    if (data.planCredits === 'unlimited') {
      return t('planCredits.unlimited');
    }

    return t(`planCredits.${data.billingCycle}`, {
      count: data.planCredits,
    });
  };

  const formatUsers = (count: IPlanDetailsEntity['users']): string => {
    return t('users.count', { count });
  };

  const formatRenewAt = (renewAt: IPlanDetailsEntity['creditsRenewOn']): string => {
    if (!renewAt) return t('creditsRenewOn.noRenewal');

    return renewAt.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const planDetails = useObservableResult(
    () =>
      billingSettingsUseCase.getCurrentPlanDetails().pipe(
        map((planDetails) => ({
          currentPlan: planDetails.currentPlan,
          planCredits: formatPlanCredits(planDetails),
          users: formatUsers(planDetails.users),
          creditsRenewOn: formatRenewAt(planDetails.creditsRenewOn),
        })),
      ),
    {
      deps: [],
    },
  );

  return {
    isLoading: planDetails.isLoading,
    currentPlan: planDetails.data?.currentPlan ?? '',
    planCredits: planDetails.data?.planCredits ?? '',
    users: planDetails.data?.users ?? '',
    creditsRenewOn: planDetails.data?.creditsRenewOn ?? '',
  };
};
