import { ReactNode, useContext, useMemo } from 'react';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { PlanActionResolverContext } from '@/features/plans/ui/contexts/PlanActionResolverContext';
import { PlanAction } from '@/features/plans/ui/types';

import { PlanFeaturesMetadataContext } from '../../contexts';
import { BillingCycleContext } from '../../contexts/BillingCycleContext';
import { PlansMetadataContext } from '../../contexts/PlanMetadataContext';

import { usePlanPrice } from './hooks';

type PlanCardViewModel = {
  billingCycle: BillingCycle;
  name: string;
  description: string;
  price: ReturnType<typeof usePlanPrice>;
  crossedOutPrice: ReturnType<typeof usePlanPrice> | null;
  isCurrent: boolean;
  isPopular: boolean;
  customBadge: string;
  features: {
    title: string;
    items: Array<string | ReactNode>;
  };
  credits: Record<BillingCycle.Yearly | BillingCycle.Monthly, number | string>;
  featuresDetailed: {
    category: string;
    items: { text: string; hint: string }[];
  }[];
  action: PlanAction;
  onAction: () => void;
  creditCardRequired: boolean;
};

export function usePlanCardViewModel(params: { planType: PlanType }): PlanCardViewModel {
  const planActionResolver = useContext(PlanActionResolverContext);
  const plansMetadata = useContext(PlansMetadataContext);
  const billingCycle = useContext(BillingCycleContext);
  const planFeaturesMetadata = useContext(PlanFeaturesMetadataContext);

  const planMetadata = plansMetadata[params.planType];

  const price = usePlanPrice({
    priceMetadata: planMetadata.prices,
    billingCycle,
  });

  const crossedOutPrice = usePlanPrice({
    priceMetadata: planMetadata.crossedOutPrice ?? planMetadata.prices,
    billingCycle,
  });

  const action = planActionResolver.resolve({
    planType: params.planType,
    billingCycle,
  });

  const featureDetailed = useMemo(() => {
    return Object.values(planFeaturesMetadata)
      .map((featureByCategory) => {
        return {
          category: featureByCategory.title,
          items: Object.values(featureByCategory.features)
            .filter(
              (feature) =>
                feature.supportByPlan[params.planType].brandTag === 'supported',
            )
            .map((feature) => ({
              text: feature.title,
              hint: feature.hint,
            })),
        };
      })
      .filter((feature) => feature.items.length > 0);
  }, [planFeaturesMetadata, params.planType]);

  return {
    billingCycle,
    name: planMetadata.name,
    description: planMetadata.description,
    isCurrent: planMetadata.isCurrent,
    isPopular: planMetadata.isMostPopular,
    customBadge: planMetadata.customBadgeText?.[billingCycle],
    price,
    credits: planMetadata.credits,
    features: planMetadata.features,
    featuresDetailed: featureDetailed,
    crossedOutPrice,
    action,
    onAction: (): void => {
      planActionResolver.onAction({
        action,
        planType: params.planType,
        billingCycle: billingCycle,
      });
    },
    creditCardRequired: planMetadata.creditCardRequired,
  };
}
