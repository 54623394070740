import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserRole } from '@/features/common/account';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useObservableResult } from '@/utils/rx';

import { TeamMemberInviteGiftSubscriptionError } from '../../../../domain/errors/TeamMemberInviteGiftSubscriptionError';
import { useTeamMembersUseCase } from '../../../hooks/useTeamMembersUseCase';

import { useInviteModalFormResolver } from './useInviteModalFormResolver';

export const useInviteModalViewModel = () => {
  const analytics = useAnalytics();
  const teamMemberUseCase = useTeamMembersUseCase();
  const { t } = useTranslation('settings');
  const { enqueueSnackbar } = useSnackbar();
  const resolver = useInviteModalFormResolver();
  const form = useForm({
    resolver,
    defaultValues: {
      emails: [],
      role: UserRole.Member,
    },
    reValidateMode: 'onBlur',
  });
  const { emails = [], role } = form.watch();
  const [isProcessing, setIsProcessing] = useState(false);
  const logger = useAppLogger();
  const onboardingUseCase = useOnboardingUseCase();
  const invitesAccessability = useObservableResult(
    () =>
      teamMemberUseCase.validateInvitesAccessability(
        emails.map((email) => ({ email, role })),
      ),
    { deps: [emails, role] },
  );

  function handleSubmitForm(onSuccess?: () => void): void {
    form.handleSubmit(async () => {
      try {
        await teamMemberUseCase.inviteTeamMembers(
          emails.map((email) => ({
            email,
            role,
          })),
        );
        emails.forEach(() => {
          analytics.trackEvent(ANALYTICS_EVENTS.ADD_TEAM_MEMBER, {
            role,
          });
        });
        await onboardingUseCase.completeInviteTeamMemberStep();
        enqueueSnackbar(t('teamManagement.inviteDialog.successToast.title'), {
          variant: 'success',
          preventDuplicate: true,
          description: t('teamManagement.inviteDialog.successToast.subtitle'),
        });

        onSuccess && onSuccess();
      } catch (error) {
        if (error instanceof WorkspaceSeatsLimitError) {
          enqueueSnackbar(t('teamManagement.inviteDialog.errorLimitToast.title'), {
            variant: 'success',
            preventDuplicate: true,
            description: t('teamManagement.inviteDialog.errorLimitToast.subtitle'),
          });
        }
        if (error instanceof TeamMemberInviteGiftSubscriptionError) {
          enqueueSnackbar(
            t('teamManagement.inviteDialog.errorGiftSubscriptionToast.title'),
            {
              variant: 'success',
              preventDuplicate: true,
              description: t(
                'teamManagement.inviteDialog.errorGiftSubscriptionToast.subtitle',
              ),
            },
          );
        }

        logger.error(error);
      } finally {
        setIsProcessing(false);
      }
    })();
  }

  const inviteCharcheableWarning = {
    visible: invitesAccessability.data?.warning === 'nextInvitesChargeable',
    props: {
      limit: invitesAccessability.data?.limit || 1,
    },
  };
  const inviteLimitReachError = {
    visible:
      invitesAccessability.error instanceof WorkspaceSeatsLimitError && !isProcessing,
  };

  const sendActionDisabled =
    !form.formState.isValid ||
    !emails.length ||
    isProcessing ||
    invitesAccessability.error instanceof WorkspaceSeatsLimitError;

  return {
    form,
    inviteCharcheableWarning,
    inviteLimitReachError,
    sendActionDisabled,
    handleSubmitForm,
  };
};
