import { useObservableResult } from '@/utils/rx';

import { useWorkspaceUseCase } from '../../../hooks';

export const useWorkspaceInvitationViewModel = () => {
  const workspaceUseCase = useWorkspaceUseCase();
  const invitation = useObservableResult(
    () => workspaceUseCase.getInvitationToAnotherWorkspace(),
    {
      deps: [],
      defaultData: null,
    },
  );

  return {
    isOpen: !!invitation.data,
    invitation,
  };
};
