import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { BILLING_TYPES } from '@/ioc/types';

import {
  type IBillingUseCase,
  IProductEntity,
  PlanType,
} from '@/features/common/billing';

import type { IPlansUseCase } from './abstractions/IPlansUseCase';
import type {
  IPlanGroupsEntity,
  IPlanGroupsItemEntity,
} from './entities/PlanGroupsEntity';

@injectable()
export class PlansUseCase implements IPlansUseCase {
  @inject(BILLING_TYPES.BillingUseCase)
  private billingUseCase: IBillingUseCase;

  private createPlanGroupItem(product?: IProductEntity): IPlanGroupsItemEntity {
    const fullCredits = product?.quotas.find((quota) => quota.creditType === 'full');
    const bulkCredits = product?.quotas.find((quota) => quota.creditType === 'bulk');

    const fullCreditsCount =
      (fullCredits?.creditsFixed ?? 0) + (fullCredits?.creditsPerSeat ?? 0);
    const csvCreditsCount =
      (bulkCredits?.creditsFixed ?? 0) + (bulkCredits?.creditsPerSeat ?? 0);

    return {
      id: product?.id ?? '',
      name: product?.name ?? '',
      price: product?.price ?? 0,
      fullCredits: fullCredits?.isUnlimited ? 'unlimited' : fullCreditsCount,
      bulkCredits: csvCreditsCount,
      features: product?.features ?? [],
    };
  }

  private createPlanGroup(): IPlanGroupsEntity {
    return {
      [PlanType.Free]: {
        monthly: this.createPlanGroupItem(),
        annually: this.createPlanGroupItem(),
      },
      [PlanType.Pro]: {
        monthly: this.createPlanGroupItem(),
        annually: this.createPlanGroupItem(),
      },
      [PlanType.Unlimited]: {
        monthly: this.createPlanGroupItem(),
        annually: this.createPlanGroupItem(),
      },
      // hardcoded Expand plan
      [PlanType.Expand]: {
        monthly: {
          id: '',
          name: 'Expand',
          price: 'contact-sales',
          fullCredits: 'unlimited',
          bulkCredits: 50000,
          features: [],
        },
        annually: {
          id: '',
          name: 'Expand',
          price: 'contact-sales',
          fullCredits: 'unlimited',
          bulkCredits: 50000,
          features: [],
        },
      },
    };
  }

  private productRelatesToPlanGroup(product: IProductEntity): boolean {
    return (
      (product.family === PlanType.Free ||
        product.family === PlanType.Pro ||
        product.family === PlanType.Unlimited) &&
      (product.cycle === 'monthly' || product.cycle === 'annually')
    );
  }

  getPlanGroups(): Observable<IPlanGroupsEntity> {
    return this.billingUseCase.getProducts().pipe(
      map((products) => {
        return products.reduce((acc, product) => {
          if (!this.productRelatesToPlanGroup(product)) {
            return acc;
          }

          if (product.family === PlanType.Free) {
            const freePlanGroupItem = this.createPlanGroupItem(product);
            acc[PlanType.Free]['monthly'] = freePlanGroupItem;
            acc[PlanType.Free]['annually'] = freePlanGroupItem;
          } else {
            acc[product.family][product.cycle] = this.createPlanGroupItem(product);
          }

          return acc;
        }, this.createPlanGroup());
      }),
    );
  }
}
