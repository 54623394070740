import {
  IWorkspaceSubscriptionEntity,
  SubscriptionPlan,
} from '@/features/common/workspace';

import { BillingCycle } from '../types';

function getBillingCycleFromPlan(plan: SubscriptionPlan): BillingCycle {
  switch (true) {
    case /daily/.test(plan):
      return BillingCycle.Daily;
    case /monthly/.test(plan):
      return BillingCycle.Monthly;
    case /annually/.test(plan):
      return BillingCycle.Yearly;
    default:
      return BillingCycle.Monthly;
  }
}

export function getBillingCycleFromSubscription({
  plan,
}: Pick<IWorkspaceSubscriptionEntity, 'plan'>): BillingCycle {
  return getBillingCycleFromPlan(plan);
}

export function getFutureBillingCycleFromSubscription({
  futurePlan,
}: Pick<IWorkspaceSubscriptionEntity, 'futurePlan'>): BillingCycle | null {
  if (!futurePlan) {
    return null;
  }

  return getBillingCycleFromPlan(futurePlan);
}
