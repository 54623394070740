import type { IProductEntity, IProductQuotaEntity } from '../../domain';
import type { IProductDC, IProductQuotaDC } from '../dataContracts';

export function mapProductDcToEntity(productDC: IProductDC): IProductEntity {
  return {
    id: productDC.id,
    price: productDC.price ? Number(productDC.price) : 0,
    status: productDC.status,
    planType: productDC.plan_type ?? 'credit_based',
    seats: productDC.seats,
    features: productDC.features ?? [],
    isDeleted: productDC.is_deleted,
    isPriceFixed: productDC.is_price_fixed,
    isActivatable: productDC.is_activatable ?? false,
    isRecurring: productDC.is_recurring,
    canIncreaseQuantity: productDC.can_increase_quantity,
    canBuyCreditsOnDemand: productDC.can_buy_credits_on_demand,
    cycle: productDC.cycle ?? 'monthly',
    family: productDC.family,
    regionId: productDC.region_id,
    workspaceEmail: productDC.workspace_email ?? null,
    name: productDC.name ?? '',
    monthlyFairLimit: productDC.monthly_fair_limit,
    dailyFairLimit: productDC.daily_fair_limit,
    createdAt: productDC.created_at,
    updatedAt: productDC.updated_at,
    quotas: productDC.quotas.map(mapProductQuotaDcToEntity),
  };
}

export function mapProductQuotaDcToEntity(
  productQuotaDC: IProductQuotaDC,
): IProductQuotaEntity {
  return {
    creditType: productQuotaDC.credit_type,
    creditsFixed: productQuotaDC.credits_fixed ?? 0,
    creditsPerSeat: productQuotaDC.credits_per_seat ?? 0,
    isUnlimited: productQuotaDC.is_unlimited ?? false,
  };
}
