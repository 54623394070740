import {
  FixedReceiptAdjustment,
  type IReceiptAdjustmentEntity,
  PercentageReceiptAdjustment,
} from '../../domain';
import type { IStripePromotionCodeDC } from '../dataContracts';

export const mapPromotionCodeToAdjustment = (
  promotion: IStripePromotionCodeDC,
): IReceiptAdjustmentEntity => {
  const name = promotion.code;

  if (promotion.coupon.percent_off) {
    return new PercentageReceiptAdjustment({
      title: name,
      percentage: promotion.coupon.percent_off / 100,
      type: 'discount',
    });
  }

  if (promotion.coupon.amount_off) {
    return new FixedReceiptAdjustment({
      title: name,
      amount: promotion.coupon.amount_off / 100,
      type: 'discount',
    });
  }

  throw new Error('Unknow promocode.');
};
