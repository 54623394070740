import { useContext, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { firstValueFrom } from 'rxjs';

import { useInjection } from '@/ioc';
import { PAYMENT_DETAILS_TYPES } from '@/ioc/types';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { DiscountAnnual } from '@/features/common/billing/domain';
import { IWorkspaceSubscriptionEntity } from '@/features/common/workspace';

import { IPaymentDetailsUseCase } from '../..';
import { IReceiptAdjustmentEntity } from '../../domain';
import { PaymentDetailsContext, PaymentFormValues } from '../contexts';

import { useReceipt } from '.';

type UsePaymentSummary = (params: {
  currentSubscription: IWorkspaceSubscriptionEntity;
}) => {
  showBillingCycleSwitcher: boolean;
  annualDiscountPercent: string;
  billingCycle: BillingCycle;
  planType: PlanType;
  receipt: ReturnType<typeof useReceipt>;
  onPromotionCodeApply: (code: string) => Promise<void>;
  onPromotionCodeRemove: () => void;
  promotionCode?: IReceiptAdjustmentEntity;
  promotionsAvailable: boolean;
};

export const usePaymentSummary: UsePaymentSummary = ({ currentSubscription }) => {
  const paymentDetailsUseCode = useInjection<IPaymentDetailsUseCase>(
    PAYMENT_DETAILS_TYPES.PaymentDetailsUseCase,
  );

  const [promotionCode, setPromotionCode] = useState<
    IReceiptAdjustmentEntity | undefined
  >();

  const { targetProduct } = useContext(PaymentDetailsContext);

  const { control } = useFormContext<PaymentFormValues>();

  const [planType, billingCycle, seats] = useWatch({
    control,
    name: ['planType', 'billingCycle', 'seats'],
  });

  const promotionsAvailable = currentSubscription.planIsFree;

  const reciept = useReceipt({
    promotionCode,
    seats,
  });

  function onPromotionCodeRemove(): void {
    setPromotionCode(undefined);
  }

  async function onPromotionCodeApply(code: string): Promise<void> {
    const promotionCode = await firstValueFrom(
      paymentDetailsUseCode.getPromocode({
        code: code,
        plan: targetProduct.id,
      }),
    );
    setPromotionCode(promotionCode);
  }

  return {
    annualDiscountPercent: (DiscountAnnual.rate * 100).toFixed(0),
    showBillingCycleSwitcher: false,
    billingCycle,
    planType,
    receipt: reciept,
    onPromotionCodeRemove,
    onPromotionCodeApply,
    promotionCode,
    promotionsAvailable,
  };
};
