import { useEffect, useRef } from 'react';

export function useSyncValue<T>(value: T, syncCallback: (value: T) => void): void {
  const syncRefCallback = useRef(syncCallback);
  const firstRender = useRef(true);

  useEffect(() => {
    syncRefCallback.current = syncCallback;
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // @ts-ignore
    syncRefCallback.current(value);
  }, [value]);
}
