import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ErrorPage500 } from '@/router/error';
import { EXTERNAL_ROUTES } from '@/router/routes';

import { SplashScreen } from '@/components';

import { PlanDowngradeDialog } from './components/PlanDowngradeDialog';
import { PlanActionResolverContext } from './contexts/PlanActionResolverContext';
import {
  BillingCycleDialog,
  ComparePlansHeader,
  ComparePlansTable,
  CustomerReviews,
  MonthlyYearlySwitcher,
  PlanCard,
  PlansFAQ,
  PlansList,
  PlansPageLayout,
} from './components';
import {
  BillingCycleContext,
  PlanFeaturesMetadataContext,
  PlansMetadataContext,
} from './contexts';
import { usePlansViewModel } from './PlansViewModel';

import styles from './Plans.module.scss';

export const Plans: FC = () => {
  const {
    planTypesToShow,
    planFeatureCategoriesToShow,
    selectedBillingCycle,
    billingCycleDialog,
    planDowngradeDialog,
    planActionResolver,
    selectBillingCycle,
    newPlansMetadata,
  } = usePlansViewModel();
  const { t } = useTranslation('plans');

  if (newPlansMetadata.isLoading) {
    return <SplashScreen />;
  }

  if (newPlansMetadata.hasError) {
    return <ErrorPage500 />;
  }

  const { plansMetadata, plansFeatureMetadata } = newPlansMetadata.data;

  return (
    <PlanActionResolverContext.Provider value={planActionResolver}>
      <BillingCycleContext.Provider value={selectedBillingCycle}>
        <PlansMetadataContext.Provider value={plansMetadata}>
          <PlanFeaturesMetadataContext.Provider value={plansFeatureMetadata}>
            <PlansPageLayout>
              <section className={styles.plansAndPricing}>
                <Typography variant="h1" className={styles.plansAndPricingTitle}>
                  {t('title')}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={styles.plansAndPricingSubtitle}
                >
                  {t('subtitle')}
                </Typography>
                <MonthlyYearlySwitcher
                  value={selectedBillingCycle}
                  onChange={selectBillingCycle}
                />
                <PlansList>
                  {planTypesToShow.map((planType) => (
                    <PlanCard key={planType} variant={planType} />
                  ))}
                </PlansList>
              </section>
              <section className={styles.comparePlans}>
                <Typography variant="h2" className={styles.title}>
                  {t('comparePlans.title')}
                </Typography>
                <Typography variant="subtitle1" className={styles.subtitle}>
                  {t('comparePlans.subtitle')}
                </Typography>
                <ComparePlansHeader />
                {planFeatureCategoriesToShow.map((planFeatureCategory, index) => (
                  <ComparePlansTable
                    key={planFeatureCategory}
                    planTypes={planTypesToShow}
                    planFeatureCategory={planFeatureCategory}
                    showPlanNames={index === 0}
                  />
                ))}
              </section>
              <section className={styles.plansFAQ}>
                <Typography variant="h2" className={styles.title}>
                  {t('faq.title')}
                </Typography>

                <Typography variant="subtitle1" className={styles.plansFAQSubtitle}>
                  <Trans
                    i18nKey="faq.subtitle"
                    t={t}
                    components={{
                      a: <a href={EXTERNAL_ROUTES.HELP_CENTER} target="_blank" />,
                    }}
                  />
                </Typography>

                <PlansFAQ />
              </section>
              <section className={styles.customerReviewsWrapper}>
                <div className={styles.customerReviews}>
                  <Typography variant="h2" className={styles.customerReviewsTitle}>
                    {t('customerReviews.title')}
                  </Typography>
                  <Typography variant="h2" className={styles.customerReviewsTitlePhones}>
                    {t('customerReviews.titlePhones')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={styles.customerReviewsSubtitle}
                  >
                    {t('customerReviews.subtitle')}
                  </Typography>
                  <CustomerReviews />
                </div>
              </section>
              <BillingCycleDialog {...billingCycleDialog} />
              <PlanDowngradeDialog {...planDowngradeDialog} />
            </PlansPageLayout>
          </PlanFeaturesMetadataContext.Provider>
        </PlansMetadataContext.Provider>
      </BillingCycleContext.Provider>
    </PlanActionResolverContext.Provider>
  );
};
