import { FC, useContext } from 'react';

import {
  EnrichmentUploadingContext,
  ModalViewType,
} from '../../EnrichmentUploadingContext';

import { ConfigView, StartView } from './components';

const ModalViewByType: Record<ModalViewType, FC> = {
  StartViewDefault: StartView,
  StartViewConnectionError: StartView,
  StartViewContentError: StartView,
  StartViewSizeError: StartView,
  StartViewFormatError: StartView,
  StartViewDefaultError: StartView,
  StartViewRateLimit: StartView,
  ConfigFieldsMapperView: ConfigView,
  ConfigSettingsView: ConfigView,
  ConfigResultView: ConfigView,
};

type UseEnrichmentUploadingModalViewModelResult = {
  isUploadingModalOpen: boolean;
  CurrentViewComponent: FC;
  onUploadingModalClose(): void;
};

export const useEnrichmentUploadingModalViewModel =
  (): UseEnrichmentUploadingModalViewModelResult => {
    const { currentView, isUploadingModalOpen, onUploadingModalClose } = useContext(
      EnrichmentUploadingContext,
    );
    const CurrentViewComponent = ModalViewByType[currentView];

    return {
      isUploadingModalOpen,
      onUploadingModalClose,
      CurrentViewComponent,
    };
  };
