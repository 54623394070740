import { inject, injectable } from 'inversify';
import { firstValueFrom, Observable } from 'rxjs';

import {
  ACCOUNT_TYPES,
  AUTH_TYPES,
  ONBOARDING_TYPES,
  STORAGE_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { COUNTRIES_DC, IAccountRepository } from '@/features/common/account';
import {
  AuthStatus,
  IAuthRepository,
  InvalidWorkEmailError,
  UserAlreadyExistsError,
} from '@/features/common/auth';
import { IOnboardingUseCase } from '@/features/common/onboarding';
import { IStorageRepository } from '@/features/system/storage/index';
import { IBaseSyncRepository } from '@/features/system/sync';

import { WorkEmailValidationSchema } from '@/utils/validation';

type FullFillUserDataParams = {
  displayName: string;
  phoneNumber: string;
  unconfirmedEmail?: string;
  country: COUNTRIES_DC;
};
type SetupAccountParams = {
  info: FullFillUserDataParams;
  credentials?: {
    email: string;
    password: string;
  };
};
export interface ISignUpUseCases {
  signUpGoogle(): Promise<void>;
  signUpMicrosoft(): Promise<void>;
  signUpWithEmail(email: string): Promise<void>;
  sendEmailVerification(email: string): Promise<void>;
  verificateEmail(params: { code: string; email: string }): Promise<void>;
  setupAccount(params: SetupAccountParams): Promise<void>;
  checkUserExists(email: string): Promise<boolean>;
  getUserCountry(): Observable<string>;
}

@injectable()
export class SignUpUseCases implements ISignUpUseCases {
  handleSuccessRedirects(): Observable<void> {
    throw new Error('Method not implemented.');
  }

  @inject(AUTH_TYPES.AuthRepository)
  private authRepository: IAuthRepository;

  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  @inject(STORAGE_TYPES.StorageRepository)
  private storage: IStorageRepository;

  @inject(SYNC_TYPES.BaseSyncRepository)
  private baseSyncRepository: IBaseSyncRepository;

  @inject(ONBOARDING_TYPES.OnboardingUseCase)
  private onboardingUseCase: IOnboardingUseCase;

  private async completeOnboardingSteps(): Promise<void> {
    try {
      await this.onboardingUseCase.completeSignupStep();
      await this.onboardingUseCase.completeMobileSignupOpenChromeStoreStep();
    } catch {}
  }

  getUserCountry(): Observable<string> {
    return this.authRepository.getUserCountry();
  }

  async setupAccount({ credentials, info }: SetupAccountParams): Promise<void> {
    try {
      this.baseSyncRepository.pause();
      if (credentials) {
        const isExist = await this.authRepository.checkUserExists(credentials.email);
        if (isExist) {
          throw new UserAlreadyExistsError();
        }
        await this.authRepository.signUpWithEmailAndPassword(
          credentials.email,
          credentials.password,
        );
        await this.accountRepository.setupAccount({
          fullName: info.displayName,
          phone: info.phoneNumber,
          country: info.country,
        });
        this.accountRepository.deleteEmailForAccountCreation();
        this.storage.save({ hasSignedUp: 'true' });
      } else {
        await this.accountRepository.setupAccount({
          fullName: info.displayName,
          phone: info.phoneNumber,
          country: info.country,
        });
        this.completeOnboardingSteps();
      }
    } catch (error) {
      throw error;
    } finally {
      this.baseSyncRepository.unpause();
    }
  }

  async signUpGoogle(): Promise<void> {
    await this.authRepository.signUpWithGoogle();
    this.storage.save({ hasSignedUp: 'true' });
  }

  async signUpWithEmail(email: string): Promise<void> {
    const isExist = await this.authRepository.checkUserExists(email);
    if (isExist) {
      throw new UserAlreadyExistsError();
    }
    await WorkEmailValidationSchema.validate(email).catch(() => {
      throw new InvalidWorkEmailError();
    });
    this.accountRepository.saveEmailForAccountCreation(email);
  }

  async verificateEmail(params: { code: string; email: string }): Promise<void> {
    await this.authRepository.applyActionCode(params.code);
    await this.accountRepository.syncAccount(params.email);

    const authStatus = await firstValueFrom(this.authRepository.getAuthStatus());

    if (authStatus === AuthStatus.Authorized) {
      await this.onboardingUseCase.completeSignupStep();
    }
  }

  async signUpMicrosoft(): Promise<void> {
    await this.authRepository.signUpWithMicrosoft();
    this.storage.save({ hasSignedUp: 'true' });
  }

  checkUserExists(email: string): Promise<boolean> {
    return this.authRepository.checkUserExists(email);
  }

  async sendEmailVerification(email: string): Promise<void> {
    await this.authRepository.sendVerificationEmail(email);
  }
}
