import { FC, ReactElement, useState } from 'react';
import { type FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Box,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

type EmailsAutocompleteProps = {
  values: string[];
  error?: FieldError & {
    value: string;
  };
  disabled?: boolean;
  onChange(values: string[]): void;
};

const getAutocompleteChipTypeByError = (error: string): 'warning' | 'error' => {
  if (error === 'emailIsExist') return 'warning';
  return 'error';
};

export const EmailsAutocomplete: FC<EmailsAutocompleteProps> = ({
  values,
  error,
  disabled,
  onChange,
}) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation('settings');
  const placeholder = values.length
    ? t('teamManagement.inviteDialog.emailsInputPlaceholderWithValue')
    : t('teamManagement.inviteDialog.emailsInputPlaceholder');

  const handleAutocompleteInputChange = (event, newInputValue: string): void => {
    const options: string[] = newInputValue.split(/[ ,]+/);
    const fieldValue = values
      .concat(options)
      .map((x) => x.trim())
      .filter((x) => x);

    if (options.length > 1) {
      onChange(fieldValue);
      setValue('');
    } else {
      setValue(newInputValue);
    }
  };

  const handleAutocompleteInputBlur = (): void => {
    if (value.length > 0) {
      onChange(values.concat(value));
      setValue('');
    }
  };

  const handleAutocompleteKeyDown = (event): void => {
    switch (event.key) {
      case 'Tab': {
        if (value.length > 0) {
          onChange(values.concat(value));
          setValue('');
          event.preventDefault();
        }
        break;
      }
    }
  };

  return (
    <Box>
      <Typography>{t('teamManagement.inviteDialog.emailsInputTitle')}</Typography>
      <Autocomplete
        multiple
        value={values}
        disabled={disabled}
        onChange={(e, newValues): void => {
          onChange(newValues);
        }}
        isOptionEqualToValue={(option, value): boolean => {
          return option === value;
        }}
        renderInput={(params): ReactElement => (
          <TextField
            {...params}
            className={styles.Input}
            error={!!error}
            placeholder={placeholder}
          />
        )}
        onBlur={handleAutocompleteInputBlur}
        inputValue={value}
        onKeyDown={handleAutocompleteKeyDown}
        onInputChange={handleAutocompleteInputChange}
        options={[]}
        autoFocus
        renderTags={(
          values: string[],
          getTagProps: AutocompleteRenderGetTagProps,
        ): ReactElement[] => {
          return values.map((option, index) => {
            const { onDelete } = getTagProps({ index });
            let chipVariant: 'default' | 'error' | 'warning' = 'default';

            if (error && error.value === option) {
              chipVariant = getAutocompleteChipTypeByError(error.message || '');
            }

            return (
              <Chip
                key={option}
                label={option}
                variant={chipVariant}
                onDelete={onDelete}
                component="div"
              />
            );
          });
        }}
      />
      {!!error && (
        <Box
          className={clsx(styles.EmailInputError, {
            [styles.Warning]: error.message === 'emailIsExist',
          })}
        >
          {t(`teamManagement.inviteDialog.${error.message}`)}
        </Box>
      )}
    </Box>
  );
};
