import { SubscriptionPlan } from '@/features/common/workspace';

import { BillingCycle, PlanType } from '../types';

function match(billingCycle: BillingCycle): (map: {
  [key in
    | BillingCycle.Monthly
    | BillingCycle.Yearly
    | BillingCycle.Daily]: SubscriptionPlan;
}) => SubscriptionPlan {
  return (map) => map[billingCycle];
}

export function getSubscriptionPlanFrom(params: {
  planType: PlanType;
  billingCycle: BillingCycle;
}): SubscriptionPlan {
  const { planType, billingCycle } = params;

  switch (planType) {
    case PlanType.Free:
      return SubscriptionPlan.FreeMonthly;

    case PlanType.Pro:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ProV2Daily,
        [BillingCycle.Monthly]: SubscriptionPlan.ProV2Monthly,
        [BillingCycle.Yearly]: SubscriptionPlan.ProV2Annually,
      });
    case PlanType.Unlimited:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.UnlimitedDaily,
        [BillingCycle.Monthly]: SubscriptionPlan.UnlimitedMonthly,
        [BillingCycle.Yearly]: SubscriptionPlan.UnlimitedAnnually,
      });
    case PlanType.Expand:
      return match(billingCycle)({
        [BillingCycle.Daily]: SubscriptionPlan.ExpandMock,
        [BillingCycle.Monthly]: SubscriptionPlan.ExpandMock,
        [BillingCycle.Yearly]: SubscriptionPlan.ExpandMock,
      });
  }
}
