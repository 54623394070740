import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

import { CallToActionBase, CallToActionBaseProps } from './CallToActionBase';

type Props = CallToActionBaseProps;

export const GetCredits: FC<Props> = ({ onClick, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (): void => {
    onClick?.();
    navigate(`${ROUTES.BILLING.PLANS}?source=get-credits`);
  };

  return (
    <CallToActionBase
      onClick={handleClick}
      {...props}
      text={t('callToActions.getCredits')}
    />
  );
};
