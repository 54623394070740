import dayjs from 'dayjs';
import { combineLatest, map } from 'rxjs';

import { useCreditUseCase, useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { useAccountUseCase } from './useAccountUseCase';

export const useAccountMonthlyCredits = (): {
  used: number;
  limit: number;
  expirationDate: number;
} => {
  const accountUseCase = useAccountUseCase();
  const subscriptionUseCase = useSubscriptionUseCase();
  const creditUseCase = useCreditUseCase();

  return useObservableResult(
    () => {
      return combineLatest({
        used: accountUseCase
          .getAccount()
          .pipe(map((account) => account?.currentCreditsUsed ?? 0)),
        limit: subscriptionUseCase
          .getSubscription()
          .pipe(map((subscription) => subscription?.monthlyFairLimit ?? 0)),
        expirationDate: creditUseCase.getFullAggregatedCreditsInfo().pipe(
          map((creditsInfo) => {
            return creditsInfo.expiresAt ?? dayjs().add(1, 'month').unix();
          }),
        ),
      });
    },
    {
      defaultData: {
        used: 0,
        limit: 0,
        expirationDate: 0,
      },
    },
  ).data;
};
