import { PlanType } from '../types';

export function isPlanType(plan: unknown): plan is PlanType {
  if (typeof plan !== 'string') {
    return false;
  }

  switch (plan) {
    case PlanType.Free:
    case PlanType.Pro:
    case PlanType.Unlimited:
      return true;
    default:
      return false;
  }
}
