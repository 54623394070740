import { FC } from 'react';

import { Modal } from '@/components';

import { InvitationEntity } from '../../../domain';

import { SwitchModal } from './components/SwitchModal';
import { useWorkspaceInvitationViewModel } from './hooks/useWorkspaceInvitationViewModel';

import styles from './styles.module.scss';

export const WorkspaceInvitation: FC = () => {
  const { isOpen, invitation } = useWorkspaceInvitationViewModel();

  return (
    <Modal
      containerClass={styles.paper}
      open={isOpen}
      onClose={(): void => {
        return;
      }}
      keepMounted={false}
      disableEscapeKeyDown
    >
      <SwitchModal invitation={invitation.data as InvitationEntity} />
    </Modal>
  );
};
