import { useCreditUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

export const useAvailableCredits = (): number => {
  const creditUseCase = useCreditUseCase();

  return useObservableResult(() => creditUseCase.getFullAggregatedCreditsCount(), {
    defaultData: 0,
  }).data;
};
