import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CallToAction } from '@/features/callToAction';
import { ActionDisableGuard } from '@/features/common/permissions';

import { PlanCardLayout } from './PlanCardLayout';

type PlanCardFreePlanProps = {
  title?: string;
};

export const PlanCardFreePlan: FC<PlanCardFreePlanProps> = ({ title }) => {
  const { t } = useTranslation('common');

  return (
    <PlanCardLayout title={title || t('planNameLong', { planName: 'Free' })}>
      <ActionDisableGuard>
        <CallToAction
          show={['getCredits', 'getExtension', 'revealContact']}
          variant="outlined"
          size="small"
        />
      </ActionDisableGuard>
    </PlanCardLayout>
  );
};
