import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import {
  BillingCycle,
  PlanType,
  useCurrentBillingCycle,
  useCurrentPlanType,
} from '@/features/common/billing';
import { PlanActionResolverContextType } from '@/features/plans/ui/contexts';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { CustomerMessagingEventKeys, useCMPage } from '@/features/system/CustomerIO';

import { useDocumentMeta } from '@/hooks';

import { useBillingCycleDialogViewModel } from './components/ChangeBillingCycleDialog/BillingCycleDialogViewModel';
import { usePlanDowngradeDialogViewModel } from './components/PlanDowngradeDialog/PlanDowngradeDialogViewModel';
import { useAvailablePlans, useNewPlansMetadata } from './hooks';
import { PlanAction, PlanFeatureCategory } from './types';
import { isPlanUpgrade } from './utils';

export type PlansViewModel = {
  planTypesToShow: PlanType[];
  planFeatureCategoriesToShow: PlanFeatureCategory[];
  selectedBillingCycle: BillingCycle;
  billingCycleDialog: ReturnType<typeof useBillingCycleDialogViewModel>;
  planDowngradeDialog: ReturnType<typeof usePlanDowngradeDialogViewModel>;
  selectBillingCycle: (billingCycle: BillingCycle) => void;
  planActionResolver: PlanActionResolverContextType;
  newPlansMetadata: ReturnType<typeof useNewPlansMetadata>;
};

const PLAN_FEATURE_CATEGORIES_TO_SHOW: PlanFeatureCategory[] = [
  PlanFeatureCategory.General,
  PlanFeatureCategory.ContactManagement,
  PlanFeatureCategory.CrmIntegrations,
  PlanFeatureCategory.Analytics,
  PlanFeatureCategory.Compliance,
  PlanFeatureCategory.SSO,
  PlanFeatureCategory.Support,
];

export function usePlansViewModel(): PlansViewModel {
  const navigate = useNavigate();

  const { trackEvent } = useAnalytics();

  const { data: currentPlanType } = useCurrentPlanType();
  const { data: currentBillingCycle } = useCurrentBillingCycle();
  const billingCycleDialog = useBillingCycleDialogViewModel();
  const planDowngradeDialog = usePlanDowngradeDialogViewModel();
  const planTypesToShow = useAvailablePlans();

  const [selectedBillingCycle, setSelectedBillingCycle] = useState<BillingCycle>(
    BillingCycle.Yearly,
  );

  const newPlansMetadata = useNewPlansMetadata({
    currentPlanType,
  });

  useEffect(() => {
    currentPlanType &&
      trackEvent(ANALYTICS_EVENTS.VIEW_PLANS_PAGE, {
        current_plan: currentPlanType,
      });
  }, [currentPlanType]);

  useCMPage(CustomerMessagingEventKeys.VIEW_PLANS_PAGE);

  useDocumentMeta({
    title: 'plans.title',
    description: 'plans.description',
  });

  const actionResolver = useMemo<PlanActionResolverContextType>(() => {
    return {
      resolve: ({ planType, billingCycle }): PlanAction => {
        if (
          planType === currentPlanType &&
          (planType === PlanType.Free || billingCycle === currentBillingCycle)
        ) {
          return PlanAction.CurrentPlan;
        }

        if (planType === PlanType.Free) {
          return PlanAction.None;
        }

        if (planType === PlanType.Pro) {
          if (currentPlanType !== PlanType.Pro) {
            return PlanAction.ChoosePlan;
          }

          if (currentBillingCycle !== billingCycle) {
            return PlanAction.ChangeBillingCycle;
          }

          return PlanAction.None;
        }

        if (planType === PlanType.Unlimited) {
          if (currentPlanType !== PlanType.Unlimited) {
            return PlanAction.ChoosePlan;
          }

          if (currentBillingCycle !== billingCycle) {
            return PlanAction.ChangeBillingCycle;
          }

          return PlanAction.None;
        }

        if (planType === PlanType.Expand) {
          return PlanAction.TalkToSales;
        }

        return PlanAction.None;
      },
      onAction: ({ action, planType, billingCycle }): void => {
        if (action === PlanAction.ChoosePlan) {
          if (
            !isPlanUpgrade(planType, currentPlanType as PlanType) &&
            currentPlanType !== PlanType.Free
          ) {
            planDowngradeDialog.open(planType, billingCycle);
          } else {
            const searchParams = new URLSearchParams();

            trackEvent(ANALYTICS_EVENTS.CLICKS_SELECT_PLAN, {
              type: `${planType} ${billingCycle}`,
            });

            searchParams.set('new_plan', planType);
            searchParams.set('new_cycle', billingCycle);

            navigate(`${ROUTES.BILLING.PAYMENT_DETAILS}?${searchParams.toString()}`);

            return;
          }
        }

        if (action === PlanAction.TalkToSales) {
          trackEvent(ANALYTICS_EVENTS.CLICK_TALK_TO_SALES);
          window.open(EXTERNAL_ROUTES.TALK_TO_SALES);
          return;
        }

        if (action === PlanAction.ChangeBillingCycle) {
          billingCycleDialog.onOpen({ planType, billingCycle });
        }
      },
    };
  }, [currentPlanType, currentBillingCycle]);

  return {
    planTypesToShow,
    planFeatureCategoriesToShow: PLAN_FEATURE_CATEGORIES_TO_SHOW,
    selectedBillingCycle,
    selectBillingCycle: setSelectedBillingCycle,
    billingCycleDialog,
    planDowngradeDialog,
    planActionResolver: actionResolver,
    newPlansMetadata,
  };
}
