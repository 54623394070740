import { PlanType } from '@/features/common/billing';

const PLAN_TYPE_ORDER_MAP = {
  [PlanType.Free]: 0,
  [PlanType.Pro]: 1,
  [PlanType.Unlimited]: 2,
};

export const isPlanUpgrade = (next: PlanType, current: PlanType): boolean => {
  return PLAN_TYPE_ORDER_MAP[next] > PLAN_TYPE_ORDER_MAP[current];
};
