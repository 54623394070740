import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import Axios, { type AxiosObservable } from 'axios-observable';
import { inject, injectable } from 'inversify';
import { finalize, first, Observable, shareReplay, switchMap } from 'rxjs';

import {
  AUTH_TYPES,
  GLOBAL_ERROR_TYPES,
  LEADS_TRACKING_TYPES,
  ONLINE_TRACKER_TYPES,
} from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';
import { ReferrerTokenKey } from '@/features/referral';
import { IGlobalErrorRepository } from '@/features/system/globalError';
import type { ILeadsTrackingRepository } from '@/features/system/leadsTracking';

import { getApiUrl } from '@/utils/getApiUrl';
import { getAppVersion } from '@/utils/getAppVersion';

import { IOnlineTrackerRepository } from '../OnlineTracker';

import {
  ConnectionError,
  InternalServerError,
  NetworkError,
  UnauthorizedError,
} from './errors';
import { HttpClientCache } from './HttpClientCache';
import type { CacheOptions } from './types';

type RequestOptions<D = any> = AxiosRequestConfig<D> & CacheOptions;

export interface IHttpClient {
  get: <T>(url: string, options?: RequestOptions<T>) => AxiosObservable<T>;
  post: <T>(url: string, data) => AxiosObservable<T>;
  put: <T>(url: string, data) => AxiosObservable<T>;
  patch: <T>(url: string, data) => AxiosObservable<T>;
  delete: <T>(url: string, data) => AxiosObservable<T>;
  request: <T>(config: AxiosRequestConfig<T>) => AxiosObservable<T>;
}

const baseURL = `${getApiUrl()}/`;

@injectable()
export default class HttpClient implements IHttpClient {
  @inject(GLOBAL_ERROR_TYPES.GlobalErrorRepository)
  private globalErrorRepository: IGlobalErrorRepository;

  private cache = new HttpClientCache(10);
  private pendingRequests = new Map<string, AxiosObservable<any>>();

  private client: Axios;

  constructor(
    @inject(AUTH_TYPES.AuthRepository)
    private readonly authRepository: IAuthRepository,
    @inject(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
    private readonly leadsTrackingRepository: ILeadsTrackingRepository,
    @inject(ONLINE_TRACKER_TYPES.OnlineTrackerRepository)
    private readonly onlineTrackerRepository: IOnlineTrackerRepository,
  ) {
    this.client = Axios.create({
      baseURL,
    });
    this.setInterceptors();
    this.handleAuth();
    this.setAdditionalHeaders();
    this.handleLeadsTracking();
  }

  private setAdditionalHeaders(): void {
    this.client.defaults.headers.common['App-Type'] = 'dashboard';
    this.client.defaults.headers.common['App-Version'] = getAppVersion();
  }

  private handleAuth(): void {
    this.authRepository.getAccessToken().subscribe((token) => {
      if (token) {
        this.client.defaults.headers.common['Authorization'] = token;
      } else {
        this.authCleanup();
      }
    });
  }

  private handleLeadsTracking(): void {
    this.leadsTrackingRepository.get$().subscribe((leadsTracking) => {
      if (leadsTracking) {
        this.client.defaults.headers.common['x-utm'] = JSON.stringify(leadsTracking);
      } else {
        delete this.client.defaults.headers.common['x-utm'];
      }
    });
  }

  private waitOnline<T>(observableFactory: () => Observable<T>): Observable<T> {
    if (this.onlineTrackerRepository.isOnline) {
      return observableFactory();
    }

    return this.onlineTrackerRepository
      .getIsOnline()
      .pipe(first(), switchMap(observableFactory));
  }

  private authCleanup(): void {
    delete this.client.defaults.headers.common['Authorization'];
    this.cache.cleanup();
    this.pendingRequests.clear();
  }
  private applyErrorHandlingInterseptor(): void {
    this.client.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        let networkError = new NetworkError(
          error.message,
          error.response?.status,
          error?.code,
          error?.response,
        );

        if (error.code === 'ERR_NETWORK') {
          networkError = new ConnectionError(error.message);
        }

        if (error.response?.status === 500) {
          networkError = new InternalServerError(error.message);
        }

        if (error.response?.status === 401) {
          networkError = new UnauthorizedError(error.message);
          this.globalErrorRepository.setExecutionError(networkError);
        }

        return Promise.reject(networkError);
      },
    );
  }

  private getRequestKey(method: string, url: string, params?: any): string {
    if (!params) return `${method.toUpperCase()}-${url}`;
    return `${method.toUpperCase()}-${url}-${JSON.stringify(params)}`;
  }

  private applyDeduplicationMechanism<T>(
    requestKey: string,
    req: () => AxiosObservable<T>,
  ): AxiosObservable<T> {
    const pendingRequest$ = this.pendingRequests.get(requestKey);

    if (pendingRequest$) {
      return pendingRequest$;
    }

    const req$ = req().pipe(
      shareReplay(1),
      finalize(() => {
        this.pendingRequests.delete(requestKey);
      }),
    );

    this.pendingRequests.set(requestKey, req$);

    return req$;
  }

  public get<Result>(url: string, options: RequestOptions = {}): AxiosObservable<Result> {
    return this.waitOnline(() => {
      const {
        cacheKey = url,
        cachePolicy = 'no-cache',
        revalidateAfter = 10000,
        ...restOptions
      } = options;
      const requestKey = this.getRequestKey('get', url, options.params);
      const req = (): AxiosObservable<Result> =>
        this.client.get<Result>(url, restOptions);

      return this.applyDeduplicationMechanism(requestKey, () =>
        cachePolicy === 'no-cache'
          ? req()
          : this.cache.apply(req, { cacheKey, cachePolicy, revalidateAfter }),
      );
    });
  }

  public delete<Result>(url: string, data): AxiosObservable<Result> {
    return this.waitOnline(() => this.client.delete<Result>(url, { data }));
  }

  public patch<Result>(url: string, data): AxiosObservable<Result> {
    return this.waitOnline(() => this.client.patch<Result>(url, data));
  }

  public post<Result>(url: string, data): AxiosObservable<Result> {
    return this.waitOnline(() => this.client.post<Result>(url, data));
  }

  public put<Result>(url: string, data): AxiosObservable<Result> {
    return this.waitOnline(() => this.client.put<Result>(url, data));
  }

  public request<Result>(options: RequestOptions): AxiosObservable<Result> {
    return this.waitOnline(() => {
      if (options.method?.toLowerCase() === 'get') {
        const {
          cacheKey = options.url as string,
          cachePolicy = 'no-cache',
          revalidateAfter = 10000,
          ...restOptions
        } = options;
        const requestKey = this.getRequestKey(
          'get',
          restOptions.url as string,
          options.params,
        );
        const req = (): AxiosObservable<Result> =>
          this.client.request<Result>(restOptions);

        return this.applyDeduplicationMechanism(requestKey, () =>
          cachePolicy === 'no-cache'
            ? req()
            : this.cache.apply(req, { cacheKey, cachePolicy, revalidateAfter }),
        );
      }

      return this.client.request<Result>(options);
    });
  }

  private setInterceptors(): void {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = localStorage.getItem(ReferrerTokenKey);

      if (token) {
        this.client.defaults.headers.common['x-referrer-token'] = token;
      }

      return config;
    });

    this.applyErrorHandlingInterseptor();
  }
}
