import { useState } from 'react';
import { map } from 'rxjs';

import { useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

type UseSubscriptionOverviewViewModel = () => {
  isCollapsed: boolean;
  showCardActions: boolean;
  toggleCollapsed: () => void;
};

export const useSubscriptionOverviewViewModel: UseSubscriptionOverviewViewModel = () => {
  const subscriptionUseCase = useSubscriptionUseCase();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapsed = (): void => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  const showCardActions = useObservableResult(
    () =>
      subscriptionUseCase
        .getIsUnlimitedPlan()
        .pipe(map((isUnlimitedPlan) => !isUnlimitedPlan)),
    {
      defaultData: false,
    },
  );

  return {
    isCollapsed,
    toggleCollapsed,
    showCardActions: showCardActions.data,
  };
};
