import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { Modal } from '@/components';

import { PlanDowngradeDialogViewModel } from './PlanDowngradeDialogViewModel';

import styles from './styles.module.scss';

export const PlanDowngradeDialog: React.FC<PlanDowngradeDialogViewModel> = (props) => {
  const { t } = useTranslation('plans', { keyPrefix: 'planDowngradeDialogSupport' });

  return (
    <Modal open={props.isOpen} containerClass={styles.modal} onClose={props.close}>
      <div className={styles.container}>
        <Typography variant="h1">{t('title')}</Typography>
        <Typography variant="subtitle1">
          {t('subtitle', { date: props.changeDate })}
        </Typography>
        <div className={styles.body}>
          <Typography variant="h2">{props.text}</Typography>
        </div>
        <div className={styles.footer}>
          <Button variant="text" color="info" size="small" onClick={props.close}>
            {t('cancel')}
          </Button>
          <Button variant="contained" size="small" onClick={props.onConfirm}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
