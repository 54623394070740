import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import { clsx } from 'clsx';

import { useCreditsLeftViewModel } from './useCreditsLeftViewModel';

import styles from './styles.module.scss';

const CreditsLeftCounterContent: FC<{ size: 'small' | 'large' }> = ({ size }) => {
  const { t } = useTranslation('common', { keyPrefix: 'creditsLeftCounter' });
  const viewModel = useCreditsLeftViewModel();

  if (viewModel.view === 'loading') {
    return <Skeleton width={150} height={30} />;
  }

  if (viewModel.view === 'manager') {
    return (
      <Typography
        className={clsx({
          [styles.creditsLeftCounterContent]: true,
          [styles.zero]: true,
        })}
      >
        {t('creditsLeft', { count: 0 })}
      </Typography>
    );
  }

  if (viewModel.view === 'unlimited') {
    return (
      <Typography
        className={clsx(styles.creditsLeftCounterContent, {
          [styles.unlimited]: true,
          [styles.large]: size === 'large',
        })}
      >
        {t('unlimited')}
      </Typography>
    );
  }

  return (
    <Typography
      className={clsx(styles.creditsLeftCounterContent, {
        [styles.zero]: viewModel.availableCredits === 0,
      })}
    >
      {t('creditsLeft', { count: viewModel.availableCredits })}
    </Typography>
  );
};

export const CreditsLeftCounter: FC<{ className?: string; size?: 'small' | 'large' }> = ({
  className,
  size = 'small',
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'creditsLeftCounter' });

  return (
    <Box className={clsx(styles.creditsLeftCounter, className)}>
      <Typography className={styles.creditsLeftCounterTitle}>
        {t('accountCredits')}
      </Typography>
      <CreditsLeftCounterContent size={size} />
    </Box>
  );
};
