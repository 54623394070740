import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { map } from 'rxjs';

import type { ICreditsDetailsEntity } from '@/features/billing/domain/entities/CreditsDetailsEntity';
import { useBillingSettingsUseCase } from '@/features/billing/ui/hooks/useBillingSettingsUseCase';

import { ObservableResult, useObservableResult } from '@/utils/rx';

type SourceKey = 'plan' | 'referral' | 'rolledUp' | 'gift' | 'unknown';

type UseCreditsOverviewVideModel = {
  totalCreditsLeft: ReactNode;
  bySources: {
    [key in SourceKey]?: Nullable<FormatResult>;
  };
};

type FormatResult = {
  title: string;
  creditsLeft: string;
  isOutOfCredits: boolean;
  renewAt?: string | null;
};

export const useCreditsOverviewVideModel =
  (): ObservableResult<UseCreditsOverviewVideModel> => {
    const { t } = useTranslation('billingSettings', {
      keyPrefix: 'subscriptionOverview.statistics',
    });

    const billingSettingsUseCase = useBillingSettingsUseCase();

    const formatTotalCreditsLeft = (
      data: ICreditsDetailsEntity['totalCreditsLeft'],
    ): ReactNode => {
      if (data === 'unlimited') return t('totalCreditsLeft.unlimited');

      return (
        <Trans
          t={t}
          key={'totalCredits.creditsLeft'}
          defaults="<b>{{count}}</b> Credits Left"
          values={{ count: data }}
          components={{ b: <b /> }}
        />
      );
    };

    const formatPlanCredits = (
      data: ICreditsDetailsEntity['bySources']['plan'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.plan.title', { plan: data.plan ?? 'Unknown' }),
        creditsLeft: t('bySources.plan.credits', {
          left: data.left,
          limit: data.limit,
        }),
        isOutOfCredits: data.left <= 0,
        renewAt: t('bySources.plan.renew', {
          date:
            data.renewAt?.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }) || null,
        }),
      };
    };

    const formatReferralCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.referral.title'),
        isOutOfCredits: data.left <= 0,
        creditsLeft: t('bySources.referral.credits', {
          left: data.left,
          limit: data.limit,
        }),
      };
    };

    const formatRolledUpCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.rolled_up.title'),
        isOutOfCredits: data.left <= 0,
        creditsLeft: t('bySources.rolled_up.credits', {
          left: data.left,
          limit: data.limit,
        }),
      };
    };

    const formatGiftCredits = (
      data: ICreditsDetailsEntity['bySources']['referral'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.gift.title'),
        isOutOfCredits: data.left <= 0,
        creditsLeft: t('bySources.gift.credits', {
          left: data.left,
          limit: data.limit,
        }),
      };
    };

    const formatUnknownCredits = (
      data: ICreditsDetailsEntity['bySources']['unknown'],
    ): Nullable<FormatResult> => {
      if (!data) return null;

      return {
        title: t('bySources.gift.title'),
        isOutOfCredits: data.left <= 0,
        creditsLeft: t('bySources.gift.credits', {
          left: data.left,
          limit: data.limit,
        }),
      };
    };

    return useObservableResult<UseCreditsOverviewVideModel>(
      () =>
        billingSettingsUseCase.getCreditsDetails().pipe(
          map(({ totalCreditsLeft, bySources }) => ({
            totalCreditsLeft: formatTotalCreditsLeft(totalCreditsLeft),
            bySources: {
              plan: formatPlanCredits(bySources.plan),
              referral: formatReferralCredits(bySources.referral),
              rolledUp: formatRolledUpCredits(bySources.rolled_up),
              gift: formatGiftCredits(bySources.gift),
              unknown: formatUnknownCredits(bySources.unknown),
            },
          })),
        ),
      { deps: [] },
    );
  };
