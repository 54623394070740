export enum PlanType {
  Free = 'free',
  Pro = 'pro',
  Unlimited = 'unlimited',
  Expand = 'expand',
}

export type NotLegacyPlanType =
  | PlanType.Free
  | PlanType.Pro
  | PlanType.Unlimited
  | PlanType.Expand;
