import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';
import { InvitationEntity } from '@/features/common/workspace/domain';

import Icon from './assets/Icon.svg?react';
import { useSwitchModalViewModel } from './hooks/useSwitchModalViewModel';

import styles from './styles.module.scss';

export const SwitchModal: FC<{
  invitation: InvitationEntity;
}> = ({ invitation }) => {
  const { t } = useTranslation('common');
  const { handleAccept, handleReject, actionsDisabled } =
    useSwitchModalViewModel(invitation);

  return (
    <Box className={styles.container}>
      <Box className={styles.titleContainer}>
        <Typography variant="h1">{t('workspaceInvitation.title')}</Typography>
      </Box>
      <Box className={styles.imgContainer}>
        <Icon />
      </Box>
      <Box className={styles.descriptionContainer}>
        <Typography variant="h3">
          <Trans t={t} i18nKey="workspaceInvitation.description">
            {{ name }} (
            <a href={`mailto:${invitation.invitedBy.email}`} className={styles.emailLink}>
              <>{{ email: invitation.invitedBy.email }}</>
            </a>
            ) has invited you to join their team on Powerlead.
          </Trans>
        </Typography>
      </Box>
      <Box className={styles.alertContainer}>
        <Typography variant="subtitle1">
          <Trans t={t} i18nKey="workspaceInvitation.warning">
            Accepting the invitation from
            <a href={`mailto:${invitation.invitedBy.email}`}>
              <>{{ email: invitation.invitedBy.email }}</>
            </a>
            will mean losing access to the prospect information you've collected, but
            we'll email you a link to download that data.
          </Trans>
        </Typography>
      </Box>
      <Box className={styles.buttonsContainer}>
        <ActionDisableGuard>
          <Button variant="contained" disabled={actionsDisabled} onClick={handleAccept}>
            {t('workspaceInvitation.switchAccounts')}
          </Button>
        </ActionDisableGuard>
        <Button
          variant="outlined"
          color="info"
          disabled={actionsDisabled}
          onClick={handleReject}
        >
          {t('workspaceInvitation.decline')}
        </Button>
      </Box>
    </Box>
  );
};
