import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentDetailsContext } from '@/features/paymentDetails/ui/contexts';

type Option = {
  label: string;
  value: number;
  disabled: boolean;
};

type UseSeatsCreditsViewModel = (params: { value: number }) => {
  options: Option[];
};

const NUMBER_OF_OPTIONS = 10;

export const useSeatsCreditsViewModel: UseSeatsCreditsViewModel = ({ value }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'form' });
  const { currentWorkspace: workspace, targetProduct } =
    useContext(PaymentDetailsContext);

  const minUsers = Math.max(workspace.billableMembersCount, 1);

  const options = useMemo(() => {
    const withCredits = targetProduct.planType === 'credit_based';
    const fullCredits = targetProduct.quotas.find((q) => q.creditType === 'full');

    const baseCredits = fullCredits?.creditsFixed ?? 0;
    const creditsPerSeat = fullCredits?.creditsPerSeat ?? 0;

    const createOption = (usersCount: number): Option => {
      const disabled = usersCount < minUsers;

      return {
        label: t(`creditsUsersOption`, {
          credits: withCredits ? baseCredits + creditsPerSeat * usersCount : 'unlimited',
          count: usersCount,
        }),
        value: usersCount,
        disabled,
      };
    };

    const result = Array.from({ length: NUMBER_OF_OPTIONS }, (_, index) => {
      return createOption(index + 1);
    });

    // Add minUsers and value to the list of options if they are not there
    if (!result.find((option) => option.value === minUsers)) {
      result.push(createOption(minUsers));
    }

    // Add value to the list of options if it is not there
    if (!result.find((option) => option.value === value)) {
      result.push(createOption(value));
    }

    result.sort((a, b) => a.value - b.value);

    return result;
  }, [minUsers, targetProduct]);

  return {
    options,
  };
};
