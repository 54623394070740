import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { BillingCycle, PlanType } from '@/features/common/billing';
import { useWorkspaceSubscription } from '@/features/common/workspace';

import { openInNewTab } from '@/utils/openInNewTab';

export type PlanDowngradeDialogViewModel = {
  isOpen: boolean;
  text: string;
  changeDate: string;
  open(planType: PlanType, billingCycle: BillingCycle): void;
  close(): void;
  onConfirm(): void;
};

export const usePlanDowngradeDialogViewModel = (): PlanDowngradeDialogViewModel => {
  const {
    t,
    i18n: { language },
  } = useTranslation('plans');

  const [isOpen, setIsOpen] = useState(false);
  const [planType, setPlanType] = useState<PlanType | null>(null);
  const [billingCycle, setBillingCycle] = useState<BillingCycle | null>(null);

  const { data: subscription } = useWorkspaceSubscription();

  const planNameText = isOpen ? t(`plans.${planType}.longName`) : '';
  const billingCycleText = isOpen ? t(`billingCycleDialog.${billingCycle}`) : '';
  const text = `${planNameText} (${billingCycleText})`;

  // @ts-ignore
  const changeDate = dayjs(dayjs.unix(subscription?.expirationDate), {
    locale: language,
  }).format('MMMM D, YYYY');

  const open = (planType: PlanType, billingCycle: BillingCycle): void => {
    setIsOpen(true);
    setPlanType(planType);
    setBillingCycle(billingCycle);
  };
  const close = (): void => {
    setIsOpen(false);
    setPlanType(null);
    setBillingCycle(null);
  };

  const onConfirm = async (): Promise<void> => {
    if (planType && billingCycle) {
      openInNewTab(EXTERNAL_ROUTES.TALK_TO_SALES);
    }
  };

  return {
    isOpen,
    text,
    changeDate,
    open,
    close,
    onConfirm,
  };
};
