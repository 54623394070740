import { useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { useBillingUseCase } from '@/features/common/billing';

import { formatCurrency } from '@/utils/formatCurrency';
import { useObservableResult } from '@/utils/rx';

import { BillingDataItem } from '../type';

dayjs.extend(localizedFormat);

type BillingHistory = {
  data: BillingDataItem[];
  isLoading: boolean;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
    changePage: (page: number) => void;
  };
};

const previewRows = Array.from({ length: 5 }).map((_, index) => ({
  transactionId: `${index}`,
})) as unknown as BillingDataItem[];

export function useBillingHistoryViewModel(): BillingHistory {
  const billingUseCase = useBillingUseCase();

  const { data: invoices, isLoading } = useObservableResult(
    () => billingUseCase.getInvoices(),
    {
      deps: [],
      cacheKey: 'invoices',
      defaultData: [],
    },
  );

  const pageSize = 10;
  const [page, setPage] = useState(0);
  const data = isLoading
    ? previewRows
    : invoices.slice(page * pageSize, (page + 1) * pageSize).map((invoice) => ({
        transactionId: invoice.transactionId,
        url: invoice.url,
        date: dayjs(invoice.date).format('L'),
        type: invoice.description ?? '',
        amount: formatCurrency({ amount: invoice.amount, currency: invoice.currency }),
      }));

  return {
    data,
    isLoading,
    pagination: {
      page,
      pageSize,
      total: invoices.length,
      changePage: (page: number) => setPage(page),
    },
  };
}
