import { useAccountUseCase, UserRole } from '@/features/common/account';
import { useAvailableCredits, useSubscriptionUseCase } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

type UseCreditsLeftViewModel = () =>
  | { view: 'loading' }
  | {
      view: 'manager';
    }
  | {
      view: 'credits-based';
      availableCredits: number;
    }
  | {
      view: 'unlimited';
    };

export const useCreditsLeftViewModel: UseCreditsLeftViewModel = () => {
  const subscriptionUseCase = useSubscriptionUseCase();
  const accountUseCase = useAccountUseCase();
  const availableCredits = useAvailableCredits();

  const { data: isUnlimitedPlan } = useObservableResult(
    () => subscriptionUseCase.getIsUnlimitedPlan(),
    {
      defaultData: false,
    },
  );

  const { data: role } = useObservableResult(() => accountUseCase.getRole());

  if (role === UserRole.Manager) {
    return {
      view: 'manager',
    };
  }

  if (isUnlimitedPlan) {
    return {
      view: 'unlimited',
    };
  }

  return {
    view: 'credits-based',
    availableCredits,
  };
};
