import { useSearchParams } from 'react-router-dom';

import { BillingCycle, isBillingCycle } from '@/features/common/billing';

export const useBillingCycleFromUrl = (
  defaulValue: BillingCycle = BillingCycle.Yearly,
): [BillingCycle, (cycle: BillingCycle) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const billingCycleAsString = searchParams.get('new_cycle');

  const billingCycle = isBillingCycle(billingCycleAsString)
    ? billingCycleAsString
    : defaulValue;

  const handleBillingCycleChange = (billingCycle: BillingCycle): void => {
    searchParams.set('new_cycle', billingCycle);
    setSearchParams(searchParams, { replace: true });
  };

  return [billingCycle, handleBillingCycleChange];
};
