import { FC, ReactNode, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { BillingCycle } from '@/features/common/billing';
import { BillingCycleContext } from '@/features/plans/ui/contexts';

import { FlashIcon, HelpIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type PlanLimitsProps = {
  children: ReactNode;
};

export const PlanLimits: FC<PlanLimitsProps> = ({ children }) => {
  return (
    <div className={styles.planLimitsWrapper}>
      <ul role="list" className={styles.planLimits}>
        {children}
      </ul>
    </div>
  );
};

type PlanLimitProps = {
  startAddornment?: ReactNode;
  children: ReactNode;
  endAddornment?: ReactNode;
  className?: string;
};

export const PlanLimit: FC<PlanLimitProps> = ({
  startAddornment,
  children,
  endAddornment,
  className,
}) => {
  return (
    <li className={className}>
      {startAddornment ? (
        <div className={styles.startAddornment}>{startAddornment}</div>
      ) : null}
      {children}
      {endAddornment ? <div className={styles.endAddornment}>{endAddornment}</div> : null}
    </li>
  );
};

export const CreditsPerMonth: FC<{ credits: number }> = ({ credits }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });
  return <PlanLimit>{t('creditsPerMonth', { credits })}</PlanLimit>;
};

export const UnlimetedCredits: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return (
    <PlanLimit
      startAddornment={<FlashIcon className={styles.iconFlash} />}
      endAddornment={
        <Tooltip title={t('unlimitedTooltip')} placement="top">
          <Box>
            <HelpIcon className={styles.iconHelp} />
          </Box>
        </Tooltip>
      }
      className={styles.unlimitedCredits}
    >
      <Trans
        t={t}
        i18nKey="unlimited"
        components={{ span: <span /> }}
        defaults="<span>Unlimited</span> Credits"
      />
    </PlanLimit>
  );
};

export const LimitedCredits: FC<{
  credits: Record<BillingCycle.Monthly | BillingCycle.Yearly, number | string>;
  variant: 'free' | 'paid';
}> = ({ credits, variant }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });
  const billingCycle = useContext(BillingCycleContext) as
    | BillingCycle.Monthly
    | BillingCycle.Yearly;

  return (
    <PlanLimit
      startAddornment={<FlashIcon className={styles.iconFlash} />}
      endAddornment={
        <Tooltip title={t('unlimitedTooltip')} placement="top">
          <Box>
            <HelpIcon className={styles.iconHelp} />
          </Box>
        </Tooltip>
      }
    >
      {variant === 'free' ? (
        <Trans
          t={t}
          i18nKey="paidCreditsMonthly"
          components={{ span: <span /> }}
          defaults="<span>{{count}}</span> Credits per month"
          values={{ count: credits[BillingCycle.Monthly] ?? 0 }}
        />
      ) : (
        <Trans
          t={t}
          i18nKey="paidCreditsMobileEmail"
          components={{ span: <span /> }}
          defaults="<span>{{count}}</span> Mobile & Email"
          values={{ count: credits[billingCycle] ?? 0 }}
        />
      )}
    </PlanLimit>
  );
};

export const UnlimitedMobileCredits: FC<{ isUnlimitedPlan: boolean }> = ({
  isUnlimitedPlan,
}) => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return (
    <PlanLimit
      startAddornment={<FlashIcon className={styles.iconFlash} />}
      endAddornment={
        <Tooltip
          title={
            isUnlimitedPlan ? (
              <Trans t={t} key={'unlimitedTooltipUnlimitedPlan'}>
                The number of credits you’re given each billing cycle to use for revealing
                prospect information on Linkedin, and Is subject to our{' '}
                <a
                  style={{ color: 'white' }}
                  href={EXTERNAL_ROUTES.HELP_CENTER_FAIR_USAGE_POLICY}
                  target="_blank"
                >
                  Fair Usage policy
                </a>
              </Trans>
            ) : (
              t('unlimitedTooltip')
            )
          }
          placement="top"
        >
          <Box>
            <HelpIcon className={styles.iconHelp} />
          </Box>
        </Tooltip>
      }
      className={styles.unlimitedCredits}
    >
      <Trans
        t={t}
        i18nKey="unlimitedMobile"
        components={{ span: <span /> }}
        defaults="<span>Unlimited</span> Mobile Credits"
      />
    </PlanLimit>
  );
};

export const UnlimitedEmailCredits: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return (
    <PlanLimit className={styles.unlimitedCredits}>
      <Trans
        t={t}
        i18nKey="unlimitedEmail"
        components={{ span: <span /> }}
        defaults="<span>Unlimited</span> Email Credits"
      />
    </PlanLimit>
  );
};

export const UnlimitedExports: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return (
    <PlanLimit className={styles.unlimitedExports}>
      <Trans
        t={t}
        i18nKey="unlimitedExports"
        components={{ span: <span /> }}
        defaults="Unlimited <span>Exports</span>"
      />
    </PlanLimit>
  );
};

export const CreditsOnDemand: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return <Box className={styles.creditsOnDemand}>{t('creditsOnDemand')}</Box>;
};

export const CreditsPerUser: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'limits' });

  return <Typography fontSize="19px">{t('creditsPerUser')}</Typography>;
};
