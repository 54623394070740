import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OutlinedInput, Popover, Typography } from '@mui/material';
import clsx from 'clsx';

import { ITagEntity } from '@/features/common/tag';
import styles from '@/features/settings/features/tagManagement/ui/components/CreateTag/styles.module.scss';

import { FormField, Modal } from '@/components';
import { CheckIcon } from '@/components/Icons';

import EditTagIcon from '@/assets/icons/settings/editTagIcon.svg?react';

import { TAG_COLORS } from './constants';
import { useTagSettingsDialogViewModel } from './TagSettingsDialogViewModel';

interface Props {
  anchorEl: HTMLElement | null;
  tag?: Pick<ITagEntity, 'name' | 'color' | 'uuid'>;
  onClose(): void;
}

export const TagSettingsDialog: React.FC<Props> = ({
  anchorEl,
  onClose: closeDialogCallback,
  tag,
}) => {
  const { t } = useTranslation(['tag', 'validation']);

  const isUpdate = !!tag;

  const {
    onColorChange,
    color,
    onSave,
    isEditMessageModalVisible,
    closeEditMessageModal,
    updateTag,
    form,
    onClose,
  } = useTagSettingsDialogViewModel({
    onClose: closeDialogCallback,
    tag,
  });

  const { isDirty, isValid, isSubmitting } = form.formState;
  const errorText = t(`${form.formState.errors.name?.message}`);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorPosition={{
          top: 0,
          left: 0,
        }}
        classes={{ paper: styles.DialogPaper }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <FormField error={!!form.formState.errors.name} errorText={errorText}>
          <OutlinedInput
            {...form.register('name')}
            placeholder={t('createTag.dialogNamePlaceholder')}
            onChange={(event) => {
              form.setValue(
                'name',
                event.target.value.replace(/[^\p{L}\p{N}\s_-]/gu, '').trim(),
              );
            }}
            type="text"
            autoFocus
          />
        </FormField>
        <Typography className={styles.ColorTitle}>
          {t('createTag.dialogColorTitle')}
        </Typography>
        <div className={styles.ColorPicker}>
          {TAG_COLORS.map((tagColor) => {
            const onClick = (): void => {
              onColorChange(tagColor);
            };

            return (
              <div
                key={tagColor}
                onClick={onClick}
                className={clsx(styles.ColorPickerItemWrapper, {
                  [styles.Checked]: tagColor === color,
                })}
              >
                <div
                  className={styles.ColorPickerItem}
                  style={{ background: tagColor }}
                />
                <CheckIcon />
              </div>
            );
          })}
        </div>
        <div className={styles.Buttons}>
          <Button color="info" onClick={onClose}>
            {t('createTag.dialogCancel')}
          </Button>
          <Button
            color="primary"
            onClick={onSave}
            disabled={isSubmitting || !isDirty || !isValid}
          >
            {t('createTag.dialogSave')}
          </Button>
        </div>
      </Popover>
      {isUpdate && (
        <Modal
          open={isEditMessageModalVisible}
          onClose={closeEditMessageModal}
          containerClass={styles.EditModal}
        >
          <>
            <EditTagIcon />
            <Typography className={styles.EditModalTitle}>
              {t('editTag.dialogTitle')}
            </Typography>
            <Typography className={styles.EditModalDescription}>
              {t('editTag.dialogDescription')}
            </Typography>
            <div className={styles.EditModalButtons}>
              <Button onClick={closeEditMessageModal}>{t('editTag.dialogCancel')}</Button>
              <Button variant="contained" onClick={updateTag}>
                {t('editTag.dialogSave')}
              </Button>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
