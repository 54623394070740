import { useCallback } from 'react';
import { Resolver } from 'react-hook-form';
import * as yup from 'yup';

import { UserRole } from '@/features/common/account';

import { WorkEmailValidationSchema } from '@/utils/validation';

import { useAcceptedTeamMembers } from '../../../hooks';

export const useInviteModalFormResolver = (): Resolver<{
  emails: string[];
  role: UserRole;
}> => {
  const { data: teamMembers } = useAcceptedTeamMembers();
  const emails = teamMembers.map((item) => item.email);

  return useCallback(
    async (data) => {
      try {
        const schema = yup.object({
          emails: yup
            .array()
            .of(
              WorkEmailValidationSchema.test(
                'emailIsExist',
                'emailIsExist',
                function (value) {
                  return !emails.some((email) => email === value);
                },
              ),
            )
            .required(),
          role: yup.string().required(),
        });
        const values = await schema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce((allErrors, currentError) => {
            const valueObj = {
              message: currentError.message,
              type: currentError.type,
              value: currentError.value,
            };
            if (currentError.path.includes('emails')) {
              if (!allErrors['emails']) allErrors['emails'] = valueObj;
            } else {
              allErrors[currentError.path] = valueObj;
            }

            return allErrors;
          }, {}),
        };
      }
    },
    [emails],
  );
};
