import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, map, Observable } from 'rxjs';

import { TEAM_MEMBER_TYPES, WORKSPACE_TYPES } from '@/ioc/types';

import {
  ISubscriptionUseCase,
  type IWorkspaceEntity,
  type IWorkspaceRepository,
  WorkspaceNotFoundError,
  WorkspaceSeatsLimitError,
} from '@/features/common/workspace';
import {
  InvitationStatus,
  ITeamMemberEntity,
  ITeamMemberStateRepository,
  ITeamMemberUseCase,
} from '@/features/settings';

import { TeamMemberInviteFreePlanError } from './errors/TeamMemberInviteFreePlanError';
import { TeamMemberInviteGiftSubscriptionError } from './errors/TeamMemberInviteGiftSubscriptionError';

@injectable()
export default class TeamMemberUseCase implements ITeamMemberUseCase {
  @inject(TEAM_MEMBER_TYPES.TeamMemberRepository)
  private repository: ITeamMemberStateRepository;

  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private workspaceRepository: IWorkspaceRepository;

  @inject(WORKSPACE_TYPES.SubscriptionUseCase)
  private subscriptionUseCase: ISubscriptionUseCase;

  private getCurrentWorkspace(): Observable<IWorkspaceEntity> {
    return this.workspaceRepository
      .getCurrentWorkspace()
      .pipe(filter((workspace): workspace is IWorkspaceEntity => !!workspace));
  }

  public validateInvitesAccessability(
    payload: {
      email: string;
      role: string;
    }[],
  ): Observable<{
    accessable: boolean;
    limit: number;
    warning?: 'nextInvitesChargeable';
  }> {
    return this.getCurrentWorkspace().pipe(
      map((workspace) => {
        const managerIsExistInWorkspace = workspace.members.some(
          (item) => item.role === 'manager',
        );
        const isIncludesManager = payload.some((member) => member.role === 'manager');
        let assumeTotalSeats = workspace.billableMembersCount + payload.length;

        if (!managerIsExistInWorkspace && isIncludesManager) {
          assumeTotalSeats -= 1;
        }

        if (
          workspace.subscription.planPaidMembersLimit &&
          assumeTotalSeats > workspace.subscription.planPaidMembersLimit
        ) {
          throw new WorkspaceSeatsLimitError();
        }

        const result: {
          accessable: boolean;
          limit: number;
          warning?: 'nextInvitesChargeable';
        } = {
          accessable: true,
          limit: workspace.subscription.paidMembersCount,
        };

        if (
          (!workspace.subscription.planPaidMembersLimit ||
            workspace.subscription.planPaidMembersLimit >
              workspace.subscription.paidMembersCount) &&
          assumeTotalSeats > workspace.subscription.paidMembersCount
        ) {
          result.warning = 'nextInvitesChargeable';
        }

        return result;
      }),
    );
  }

  public async iniviteAccessGuard(): Promise<boolean> {
    const subscription = await firstValueFrom(this.subscriptionUseCase.getSubscription());

    if (subscription.planIsFree) {
      throw new TeamMemberInviteFreePlanError();
    }

    if (subscription.isGift) {
      throw new TeamMemberInviteGiftSubscriptionError();
    }

    return true;
  }

  public async inviteTeamMembers(
    payload: {
      email: string;
      role: string;
    }[],
  ): Promise<IWorkspaceEntity> {
    return this.repository.inviteTeamMembers(payload);
  }

  public deleteTeamMembers(
    payload: {
      email: string;
      reassignTo: string;
    }[],
  ): Promise<boolean> {
    return this.repository.deleteTeamMembers(payload);
  }

  public resendInvitation(payload: {
    email: string;
    role: string;
  }): Promise<IWorkspaceEntity> {
    return this.repository.resendInvitation(payload);
  }

  public getTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.workspaceRepository.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace?.members ? workspace.members : [];
      }),
    );
  }

  public getAcceptedOnlyTeamMembers(): Observable<ITeamMemberEntity[]> {
    return this.getTeamMembers().pipe(
      map((members) => {
        return members.filter(
          (member) => member.invitationStatus === InvitationStatus.Accepted,
        );
      }),
    );
  }

  public async updateTeamMemberRole(
    payload: Pick<ITeamMemberEntity, 'email' | 'role'>,
  ): Promise<IWorkspaceEntity> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );
    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    const updatedWorkspace = {
      ...workspace,
      members: workspace.members.map((member) => {
        if (member.email === payload.email) {
          member.role = payload.role;
        }
        return member;
      }),
    };

    return this.workspaceRepository.updateWorkspace(updatedWorkspace);
  }

  public async setMemberAccessAllContacts(value: boolean): Promise<void> {
    const workspace = await firstValueFrom(
      this.workspaceRepository.getCurrentWorkspace(),
    );

    if (!workspace) {
      throw new WorkspaceNotFoundError();
    }

    this.workspaceRepository.updateWorkspace({
      ...workspace,
      memberAccessAllContacts: value,
    });
  }

  public getMemberAccessAllContacts(): Observable<boolean> {
    return this.getCurrentWorkspace().pipe(
      map((workspace) => {
        return workspace.memberAccessAllContacts;
      }),
    );
  }

  public getMemberById(uuid: string): Observable<Nullable<ITeamMemberEntity>> {
    return this.getTeamMembers().pipe(
      map((members) => {
        return members.find((member) => member.uuid === uuid);
      }),
    );
  }
}
