import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { CustomSelectV2 } from '@/components';

import { useSeatsCreditsViewModel } from './useSeatsCreditsViewModel';

import styles from './styles.module.scss';

export const SeatsCreditsSelector: FC<{
  value: number;
  onChange: (value: number) => void;
  error?: boolean;
}> = ({ value, onChange, error }) => {
  const { t } = useTranslation('paymentDetails', { keyPrefix: 'form' });
  const { options } = useSeatsCreditsViewModel({ value });

  return (
    <>
      <CustomSelectV2
        value={value}
        options={options}
        onChange={onChange}
        size="large"
        MenuProps={{
          PopoverProps: {
            classes: { paper: styles.paper },
          },
          renderFooter: () => (
            <Box className={styles.footer}>
              <Typography fontSize={13}>{t('creditUsersSelectorFooterText')}</Typography>
              <Link fontSize={13} href={EXTERNAL_ROUTES.REQUEST_DEMO} target="_blank">
                {t('creditUsersSelectorFooterLink')}
              </Link>
            </Box>
          ),
        }}
        error={!!error}
      />

      {error ? null : (
        <Typography color={'info'} fontSize={12} className={styles.subtitle}>
          {t('creditsUsersSelectorSubtitle')}
        </Typography>
      )}
    </>
  );
};
