import { FC } from 'react';
import { Box, Popover } from '@mui/material';
import clsx from 'clsx';

import { ArrowDownIcon } from '../Icons';

import { PopoverBody } from './components/PopoverBody';
import { useDateSelectViewModel } from './hooks';

import styles from './styles.module.scss';

export type DateSelectProps = {
  defaultValue: string;
  minDateTimestamp?: number;
  onChange: (value: string) => void;
};

export const DateSelect: FC<DateSelectProps> = ({ defaultValue, onChange }) => {
  const {
    options,
    value,
    close,
    anchorElement,
    handleAnchorClick,
    isOpen,
    cutomOption,
    onSelect,
  } = useDateSelectViewModel({
    defaultValue: defaultValue,
    onChange,
  });

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.anchor} onClick={handleAnchorClick}>
        <Box component="span" className={styles.anchorText}>
          {value.label}
        </Box>
        <Box
          className={clsx(styles.anchorIcon, {
            [styles.opened]: isOpen,
          })}
        >
          <ArrowDownIcon />
        </Box>
      </Box>
      <Popover
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        anchorEl={anchorElement}
        open={isOpen}
        onClose={close}
      >
        <PopoverBody
          options={options}
          value={value}
          cutomOption={cutomOption}
          onSelect={onSelect}
        />
      </Popover>
    </Box>
  );
};
