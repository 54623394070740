import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';

import { CreditsLeftCounter } from '@/features/creditsCounter';

import styles from './styles.module.scss';

type PlanCardLayoutProps = {
  title: string;
  hideCreditsData?: boolean;
};

export const PlanCardLayout: FC<PropsWithChildren<PlanCardLayoutProps>> = ({
  children,
  title,
  hideCreditsData = false,
}) => {
  return (
    <Box className={styles.wrapper}>
      <Box>
        <Typography variant="h1" className={styles.title}>
          {title}
        </Typography>
        {hideCreditsData ? null : <CreditsLeftCounter size="large" />}
      </Box>
      {children}
    </Box>
  );
};
