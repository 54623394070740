import { ICreditEntity } from '../../domain';
import { ICreditDC } from '../dataContracts';

export const mapCreditDcToEntity = (dc: ICreditDC): ICreditEntity => {
  return {
    id: dc.id,
    issuer: dc.issuer,
    productId: dc.product_id,
    creditType: dc.credit_type,
    issuedCredits: dc.issued_credits,
    usedCredits: dc.used_credits,
    activatesAt: dc.activates_at,
    expiresAt: dc.expires_at,
    isActive: dc.is_active,
    archived: dc.archived,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    accountId: dc.account_id,
  };
};
