import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { BillingCycle } from '@/features/common/billing';

type UsePlanPriceReturn = {
  title: ReactNode;
  subtitle1: ReactNode;
  subtitle2: ReactNode;
};

export function usePlanPrice(params: {
  priceMetadata: Record<
    BillingCycle.Monthly | BillingCycle.Yearly,
    number | 'contact-sales'
  >;
  billingCycle: BillingCycle;
}): UsePlanPriceReturn {
  const { t } = useTranslation('plans');

  const price = Reflect.get(params.priceMetadata, params.billingCycle);

  if (Number.isNaN(price) || price === 'contact-sales') {
    return {
      title: t('prices.contactTitle'),
      subtitle1: t('prices.contactSubtitle'),
      subtitle2: null,
    };
  }

  // if the price is 0, we display custom subtitle "forever free"
  if (price === 0) {
    return {
      title: t('prices.priceTitle', { price: 0 }),
      subtitle1: t('prices.freeSubtitle'),
      subtitle2: null,
    };
  }

  return {
    title: t('prices.priceTitle', { price }),
    subtitle1:
      params.billingCycle === BillingCycle.Yearly
        ? t(`prices.rate`)
        : t(`prices.rateMonth`), //
    subtitle2: t(`prices.billed${params.billingCycle}`),
  };
}
