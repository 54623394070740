import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import UpgradeDialogImage from '@/assets/icons/settings/upgradeDialogImage.svg?react';

import styles from './styles.module.scss';

export const UpgradeAccount: React.FC = () => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();

  const onClick = (): void => {
    navigate(ROUTES.BILLING.PLANS);
  };

  return (
    <>
      <UpgradeDialogImage />
      <Typography className={styles.text}>
        {t('teamManagement.freePlanError.title')}
      </Typography>
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {t('teamManagement.freePlanError.buttonText')}
      </Button>
    </>
  );
};
