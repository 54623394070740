import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { WORKSPACE_TYPES } from '@/ioc/types';

import { IWorkspaceRepository } from '../data/abstractions/WorksapceRepository';

import { FullAggregatedCreditNotFoundError } from './errors/FullAggregatedCreditNotFoundError';
import { ICreditUseCase } from './abstractions';
import {
  IAggregatedCreditEntity,
  ICreditEntity,
  IWorkspaceSubscriptionEntity,
} from './entities';

@injectable()
export class CreditUseCase implements ICreditUseCase {
  @inject(WORKSPACE_TYPES.WorkspaceRepository)
  private readonly workspaceRepository: IWorkspaceRepository;

  private getSubscription(): Observable<IWorkspaceSubscriptionEntity> {
    return this.workspaceRepository.getCurrentWorkspaceSubscription();
  }

  private getCreditsByType(
    creditType: ICreditEntity['creditType'],
  ): Observable<ICreditEntity[]> {
    return this.workspaceRepository
      .getAllCredits()
      .pipe(
        map((credits) => credits.filter((credit) => credit.creditType === creditType)),
      );
  }

  public getFullCredits(): Observable<ICreditEntity[]> {
    return this.getCreditsByType('full');
  }

  public getFullAggregatedCreditsInfo(): Observable<IAggregatedCreditEntity> {
    return this.getSubscription().pipe(
      map((subscription) => {
        const fullCredit = subscription.credits.find(
          (credit) => credit.creditType === 'full',
        );

        if (!fullCredit) {
          throw new FullAggregatedCreditNotFoundError();
        }

        return fullCredit;
      }),
    );
  }

  public getFullAggregatedCreditsCount(): Observable<number> {
    return this.getFullAggregatedCreditsInfo().pipe(
      map((creditsInfo) => Math.max(creditsInfo.limit - creditsInfo.used, 0)),
    );
  }
}
