import { useTranslation } from 'react-i18next';

import { DATE_SELECT_OPTION_VALUES, isDateSelectOptionValue } from '../enums';
import { Option } from '../types';

export const useDateSelectOptions = () => {
  const { t } = useTranslation('contacts');
  const optionsMapByValue: Record<DATE_SELECT_OPTION_VALUES, Option> = {
    [DATE_SELECT_OPTION_VALUES.allTime]: {
      label: t('subhead.timeFilter.options.allTime'),
      value: DATE_SELECT_OPTION_VALUES.allTime,
    },
    [DATE_SELECT_OPTION_VALUES.today]: {
      label: t('subhead.timeFilter.options.today'),
      value: DATE_SELECT_OPTION_VALUES.today,
    },
    [DATE_SELECT_OPTION_VALUES.yesterday]: {
      label: t('subhead.timeFilter.options.yesterday'),
      value: DATE_SELECT_OPTION_VALUES.yesterday,
    },
    [DATE_SELECT_OPTION_VALUES.lastWeek]: {
      label: t('subhead.timeFilter.options.lastWeek'),
      value: DATE_SELECT_OPTION_VALUES.lastWeek,
    },
    [DATE_SELECT_OPTION_VALUES.lastMonth]: {
      label: t('subhead.timeFilter.options.lastMonth'),
      value: DATE_SELECT_OPTION_VALUES.lastMonth,
    },
    [DATE_SELECT_OPTION_VALUES.currentYear]: {
      label: t('subhead.timeFilter.options.currentYear'),
      value: DATE_SELECT_OPTION_VALUES.currentYear,
    },
  };

  const getCustomOption = (value: string): Option => {
    return {
      label: t('subhead.timeFilter.options.custom'),
      value: isDateSelectOptionValue(value) ? '' : value,
    };
  };

  const options = Object.values(optionsMapByValue);

  return {
    options,
    getCustomOption,
  };
};
