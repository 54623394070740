import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ROUTES } from '@/router/routes';

import { AuthState } from '@/features/common/auth';
import { ReferralTokenInvalidError } from '@/features/referral/domain';
import { ReferrerTokenKey } from '@/features/referral/ui';
import { useReferralUseCase } from '@/features/referral/ui/hooks/useReferralUseCase';
import { useSignUpUseCase } from '@/features/signup';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';
import { NetworkError } from '@/features/system/network';

export const useReferralLandingViewModel = (authState: AuthState) => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [params] = useSearchParams();
  const signUpUseCase = useSignUpUseCase();
  const referralUseCase = useReferralUseCase();
  const logger = useAppLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('referral');

  useAnalytics(ANALYTICS_EVENTS.INVITEE_OPEN_LINK);

  const processError = (error: unknown) => {
    logger.error(error);

    localStorage.removeItem(ReferrerTokenKey);

    switch (true) {
      case error instanceof ReferralTokenInvalidError: {
        enqueueSnackbar(t('error.invalidToken'), { variant: 'error' });
        break;
      }
      case error instanceof NetworkError:
      default: {
        enqueueSnackbar(t('error.networkError'), { variant: 'error' });
        break;
      }
    }

    navigate(ROUTES.SIGN_UP);
  };

  const processToken = async () => {
    try {
      localStorage.setItem(ReferrerTokenKey, token as string);

      const isTokenValid = await referralUseCase.validateToken(token as string);

      if (isTokenValid) {
        const email = params.get('email');
        if (email) {
          await signUpUseCase.signUpWithEmail(email);

          navigate(ROUTES.SIGN_UP_NEW);
        } else {
          navigate(ROUTES.SIGN_UP);
        }
      } else {
        localStorage.removeItem(ReferrerTokenKey);
      }
    } catch (error) {
      processError(error);
    }
  };

  useEffect(() => {
    if (token && authState === AuthState.ShouldProvideCredentials) {
      processToken();
    }
  }, [token, authState]);
};
