import { FC } from 'react';

import PlanCardPreview from '@/assets/icons/planCardPreview.svg?react';

import { PlanCardFreePlan } from './components/PlanCardFreePlan';
import { PlanCardProfessionalPlan } from './components/PlanCardProfessionalPlan';
import { usePlanCardViewModel } from './PlanCardViewModel';

export const PlanCard: FC = () => {
  const { data: isFreePlan, isLoading } = usePlanCardViewModel();

  if (isLoading) {
    return <PlanCardPreview width="402px" preserveAspectRatio="none" />;
  }

  if (isFreePlan) {
    return <PlanCardFreePlan />;
  }

  return <PlanCardProfessionalPlan />;
};
