import { flushSync } from 'react-dom';

type StartViewTransition = (cb: () => unknown) => void;

export const startViewTransition: StartViewTransition = (cb) => {
  if (document.startViewTransition) {
    return flushSync(() => document.startViewTransition(cb));
  }

  return cb();
};
