import { useSnackbar } from 'notistack';
import { firstValueFrom } from 'rxjs';

import { ReferralStatus } from '@/features/referral/domain/entities';
import { useReferralUseCase } from '@/features/referral/ui/hooks/useReferralUseCase';
import { useAppLogger } from '@/features/system/logger';

import { TableCellActionsProps } from './TableCellActions';

type ViewModel = (props: TableCellActionsProps) => {
  onDelete(): void;
  onResendEmail(): Promise<void>;
  isResendButtonVisible: boolean;
};

export const useTableCellActionsViewModel: ViewModel = ({ email, uuid, status }) => {
  const referralUseCase = useReferralUseCase();
  const { enqueueSnackbar } = useSnackbar();
  const logger = useAppLogger();
  const isResendButtonVisible =
    status !== ReferralStatus.Credited && status !== ReferralStatus.NotUsed;

  const onDelete = async (): Promise<void> => {
    await referralUseCase.removeInvitation(uuid);
  };

  const onResendEmail = async (): Promise<void> => {
    try {
      const response = await firstValueFrom(
        referralUseCase.sendInvitationEmail([{ email }]),
      );

      if (response[0].error === 'rate_limited') {
        enqueueSnackbar({ variant: 'error', message: 'Too many attempts. Try in 5 min' });
      } else {
        enqueueSnackbar({
          variant: 'success',
          message: 'Invitation email successfully sent',
        });
      }
    } catch (e) {
      logger.error(e);
    }
  };

  return {
    onDelete,
    onResendEmail,
    isResendButtonVisible,
  };
};
