import { inject, injectable } from 'inversify';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

import { ACCOUNT_TYPES } from '@/ioc/types';

import type { IAccountUseCase } from '@/features/common/account/domain/abstractions/useCases/IAccountUseCase';
import type { AuthIdentityProvider } from '@/features/common/auth';

import type { IAccountRepository } from './abstractions/repositories/IAccountRepository';
import type { IAccountEntity } from './entities';
import type { UserRole } from './types';

@injectable()
export default class AccountUseCase implements IAccountUseCase {
  @inject(ACCOUNT_TYPES.AccountRepository)
  private accountRepository: IAccountRepository;

  getEmailForAccountCreation(): BehaviorSubject<string> {
    return this.accountRepository.getEmailForAccountCreation();
  }

  deleteEmailForAccountCreation(): void {
    return this.accountRepository.deleteEmailForAccountCreation();
  }

  getAccount(): Observable<IAccountEntity | null> {
    return this.accountRepository.getAccount();
  }

  getEmail(): Observable<string> {
    return this.getAccount().pipe(map((account) => account?.email ?? ''));
  }

  getIdentityProviders(): Observable<AuthIdentityProvider[]> {
    return this.getAccount().pipe(map((acc) => acc?.providers ?? []));
  }

  getRole(): Observable<UserRole> {
    return this.getAccount().pipe(
      filter((account) => !!account),
      map((account) => account.role),
    );
  }
}
